import { ErrorBoundary } from "@sentry/react"
import { Link } from "gatsby"
import clsx from "clsx"
import React, { useCallback, useState } from "react"
import { FC } from "react"
import { useSession } from "../../hooks/useSession"
import KmitlLogo from "../../images/logo-go.png"
import { UserMenuDesktop } from "./UserMenuDesktop"
import { UserMenuMobile } from "./UserMenuMobile"
import { useUserEmailMutation } from "../../hooks/useUserEmailMutation"

const Layout: FC = ({ children }) => {
  useUserEmailMutation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { isAuthenticated } = useSession()

  const handleToggleMenu = useCallback(() => setIsMenuOpen((flag) => !flag), [])

  return (
    <div id="content" className="min-h-screen">
      <nav className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <img
                    className="block lg:hidden h-12 w-auto"
                    src={KmitlLogo}
                    alt="KMITL"
                  />
                </Link>
                <Link to="/">
                  <img
                    className="hidden lg:block h-12 w-auto"
                    src={KmitlLogo}
                    alt="KMITL"
                  />
                </Link>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              {isAuthenticated ? <UserMenuDesktop /> : null}
            </div>
            <div className="mr-2 flex items-center sm:hidden">
              <button
                className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleToggleMenu}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={clsx(isMenuOpen ? "hidden" : "block", "h-6 w-6")}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={clsx(isMenuOpen ? "block" : "hidden", "h-6 w-6")}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {isAuthenticated ? (
          <div className={clsx(isMenuOpen ? "block" : "hidden", "sm:hidden")}>
            <UserMenuMobile open={isMenuOpen} />
          </div>
        ) : null}
      </nav>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="md:py-10 py-5">
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
