import { gql } from "@apollo/client"
import { useEffect } from "react"
import { useUpdateUserEmailMutation } from "../generated/graphql"
import { useSession } from "./useSession"

gql`
  mutation UpdateUserEmail($email: String!) {
    returning: insert_user_email_one(
      object: { email: $email }
      on_conflict: { constraint: user_email_pkey, update_columns: [] }
    ) {
      id
      email
    }
  }
`

export function useUserEmailMutation() {
  const { user, type } = useSession()
  const [updateFn] = useUpdateUserEmailMutation({})

  useEffect(() => {
    if (type === "kmitl" && user?.id && user?.account?.email) {
      updateFn({
        variables: {
          email: user.account.email,
        },
      })
    }
  }, [updateFn, type, user?.id, user?.account?.email])
}
