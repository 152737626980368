import { isBrowser } from "."
import { AnonymousType } from "../types"
import { auth } from "./nhost"

export const ANONYMOUS_KEY = "GoFight/anonymous"

/**
 *
 * @param data Anonymous
 */

const loginAsAnonymous = (data: AnonymousType) => {
  if (!isBrowser()) {
    return undefined
  }

  window.localStorage.setItem(ANONYMOUS_KEY, JSON.stringify(data))
}

const getAnonymousData = () => {
  if (!isBrowser()) {
    return undefined
  }

  const string = window.localStorage.getItem(ANONYMOUS_KEY)

  if (!string) {
    return undefined
  }

  try {
    const account = JSON.parse(string) as AnonymousType
    return account
  } catch (ex) {
    console.error(ex)
    return undefined
  }
}

/**
 * Authentication
 */

const logout = () => {
  if (!isBrowser()) {
    return undefined
  }

  try {
    auth.logout()
  } catch (ex) {
  } finally {
    window.localStorage.removeItem(ANONYMOUS_KEY)
  }
}

const anonymousService = {
  logout,
  loginAsAnonymous,
  getAnonymousData,
}

export default anonymousService
