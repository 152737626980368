import { useCallback } from "react"
import { auth } from "../libs/nhost"
import { useAnonymous } from "../store/anonymous"
import { useUser } from "./useUser"

type SessionType = "kmitl" | "anonymous" | undefined

export function useSession() {
  const user = useUser()
  const anonymous = useAnonymous()

  const isAuthenticated = user.signedIn || anonymous.isAuthenticated()

  const logout = useCallback(async () => {
    if (user.signedIn) {
      return auth.logout()
    } else {
      return anonymous.logout()
    }
  }, [user.signedIn, anonymous])

  let type: SessionType

  if (user.signedIn) {
    type = "kmitl"
  }
  if (anonymous.data?.tel) {
    type = "anonymous"
  }

  return {
    anonymous: anonymous.data,
    user: user.data,
    isAuthenticated,
    loading: user.loading,
    logout,
    type,
  }
}
