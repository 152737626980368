import create, { SetState, GetState } from "zustand"
import { devtools } from "zustand/middleware"
import produce from "immer"

import anonymousService from "../libs/anonymous"
import { AnonymousType } from "../types"

export interface AnonymousState {
  data?: AnonymousType
  isAuthenticated: () => boolean
  login: (data: AnonymousType) => void
  logout: () => void
}

export const useAnonymous = create(
  devtools(
    (set: SetState<AnonymousState>, get: GetState<AnonymousState>) => {
      const login = (data: AnonymousType) =>
        set(
          produce((state) => {
            anonymousService.loginAsAnonymous(data)
            state.data = data
          })
        )

      const logout = () =>
        set(
          produce((state) => {
            state.data = undefined
            anonymousService.logout()
          })
        )

      return {
        data: anonymousService.getAnonymousData(),
        isAuthenticated: () => !!get().data,
        login,
        logout,
      }
    },
    { name: "GoFightAnonymous" }
  )
)
