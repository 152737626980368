import { useAuth } from "@nhost/react-auth"
import { useGetUserByIdQuery } from "../generated/graphql"
import { auth } from "../libs/nhost"

export function useUser() {
  const { signedIn } = useAuth()
  const { data, loading: fetching } = useGetUserByIdQuery({
    skip: signedIn !== true,
    ssr: false,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    variables: {
      id: signedIn ? auth.getClaim("x-hasura-user-id") : undefined,
    },
  })

  // signedIn === null means nhost is fetching the data
  const loading = fetching || signedIn === null

  return { data: data?.entity, loading, signedIn: signedIn }
}
