import clsx from "clsx"
import React, { Fragment, useCallback, useState } from "react"
import toast from "react-hot-toast"
import { useSession } from "../../hooks/useSession"

export const UserMenuDesktop = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { user, anonymous, type, logout } = useSession()

  const handleToggleMenu = useCallback(() => setIsMenuOpen((flag) => !flag), [])

  const handleLogout = useCallback(async () => {
    await logout()
    toast.success("ออกจากระบบสำเร็จ")
  }, [logout])

  return (
    <Fragment>
      <div className="ml-3 relative">
        <div className="flex items-center">
          <button
            className="max-w-xs bg-white flex items-center  text-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="user-menu"
            aria-haspopup="true"
            onClick={handleToggleMenu}
          >
            <span className="sr-only">Open user menu</span>
            <svg
              className="text-gray-400 h-8 w-8 rounded-full"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clipRule="evenodd"
              />
            </svg>

            <div className="ml-3 text-left">
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                {type === "kmitl" ? user?.display_name : anonymous?.tel}
              </p>
              <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                {type === "kmitl"
                  ? user?.account?.email
                  : anonymous?.email || anonymous?.contact_name}
              </p>
            </div>
          </button>
        </div>
        <div
          className={clsx(
            isMenuOpen ? "" : "hidden",
            "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
          )}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <div className={clsx("block", "px-4 py-2 text-sm text-gray-700")}>
            {type === "kmitl" ? user?.display_name : anonymous?.tel}
          </div>
          <div
            className={clsx(
              "block",
              "cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            )}
            role="menuitem"
            onClick={handleLogout}
          >
            ออกจากระบบ
          </div>
        </div>
      </div>
    </Fragment>
  )
}
