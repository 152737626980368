import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  citext: any;
  date: any;
  jsonb: any;
  timestamptz: any;
  timetz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/**
 * ยี่ห้อชุดตรวจ ATK
 * 
 * 
 * columns and relationships of "atk_brand"
 */
export type AtkBrand = {
  __typename?: 'atk_brand';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "atk_brand" */
export type AtkBrandAggregate = {
  __typename?: 'atk_brand_aggregate';
  aggregate?: Maybe<AtkBrandAggregateFields>;
  nodes: Array<AtkBrand>;
};

/** aggregate fields of "atk_brand" */
export type AtkBrandAggregateFields = {
  __typename?: 'atk_brand_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AtkBrandMaxFields>;
  min?: Maybe<AtkBrandMinFields>;
};


/** aggregate fields of "atk_brand" */
export type AtkBrandAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AtkBrandSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "atk_brand". All fields are combined with a logical 'AND'. */
export type AtkBrandBoolExp = {
  _and?: Maybe<Array<AtkBrandBoolExp>>;
  _not?: Maybe<AtkBrandBoolExp>;
  _or?: Maybe<Array<AtkBrandBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "atk_brand" */
export enum AtkBrandConstraint {
  /** unique or primary key constraint */
  AtkBrandPkey = 'atk_brand_pkey'
}

/** input type for inserting data into table "atk_brand" */
export type AtkBrandInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AtkBrandMaxFields = {
  __typename?: 'atk_brand_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type AtkBrandMinFields = {
  __typename?: 'atk_brand_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "atk_brand" */
export type AtkBrandMutationResponse = {
  __typename?: 'atk_brand_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AtkBrand>;
};

/** on conflict condition type for table "atk_brand" */
export type AtkBrandOnConflict = {
  constraint: AtkBrandConstraint;
  update_columns: Array<AtkBrandUpdateColumn>;
  where?: Maybe<AtkBrandBoolExp>;
};

/** Ordering options when selecting data from "atk_brand". */
export type AtkBrandOrderBy = {
  active?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: atk_brand */
export type AtkBrandPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "atk_brand" */
export enum AtkBrandSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "atk_brand" */
export type AtkBrandSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "atk_brand" */
export enum AtkBrandUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * ผลตรวจ ATK
 * 
 * 
 * columns and relationships of "atk_result"
 */
export type AtkResult = {
  __typename?: 'atk_result';
  brand_id?: Maybe<Scalars['uuid']>;
  brand_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  date: Scalars['date'];
  id: Scalars['uuid'];
  image: Scalars['String'];
  infected: Scalars['Boolean'];
  time: Scalars['timetz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "atk_result" */
export type AtkResultAggregate = {
  __typename?: 'atk_result_aggregate';
  aggregate?: Maybe<AtkResultAggregateFields>;
  nodes: Array<AtkResult>;
};

/** aggregate fields of "atk_result" */
export type AtkResultAggregateFields = {
  __typename?: 'atk_result_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AtkResultMaxFields>;
  min?: Maybe<AtkResultMinFields>;
};


/** aggregate fields of "atk_result" */
export type AtkResultAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AtkResultSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "atk_result" */
export type AtkResultAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AtkResultMaxOrderBy>;
  min?: Maybe<AtkResultMinOrderBy>;
};

/** input type for inserting array relation for remote table "atk_result" */
export type AtkResultArrRelInsertInput = {
  data: Array<AtkResultInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AtkResultOnConflict>;
};

/** Boolean expression to filter rows from the table "atk_result". All fields are combined with a logical 'AND'. */
export type AtkResultBoolExp = {
  _and?: Maybe<Array<AtkResultBoolExp>>;
  _not?: Maybe<AtkResultBoolExp>;
  _or?: Maybe<Array<AtkResultBoolExp>>;
  brand_id?: Maybe<UuidComparisonExp>;
  brand_name?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  image?: Maybe<StringComparisonExp>;
  infected?: Maybe<BooleanComparisonExp>;
  time?: Maybe<TimetzComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "atk_result" */
export enum AtkResultConstraint {
  /** unique or primary key constraint */
  AtkResultPkey = 'atk_result_pkey'
}

/** input type for inserting data into table "atk_result" */
export type AtkResultInsertInput = {
  brand_id?: Maybe<Scalars['uuid']>;
  brand_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  infected?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['timetz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AtkResultMaxFields = {
  __typename?: 'atk_result_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  brand_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timetz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "atk_result" */
export type AtkResultMaxOrderBy = {
  brand_id?: Maybe<OrderBy>;
  brand_name?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  image?: Maybe<OrderBy>;
  time?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AtkResultMinFields = {
  __typename?: 'atk_result_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  brand_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timetz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "atk_result" */
export type AtkResultMinOrderBy = {
  brand_id?: Maybe<OrderBy>;
  brand_name?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  image?: Maybe<OrderBy>;
  time?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "atk_result" */
export type AtkResultMutationResponse = {
  __typename?: 'atk_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AtkResult>;
};

/** on conflict condition type for table "atk_result" */
export type AtkResultOnConflict = {
  constraint: AtkResultConstraint;
  update_columns: Array<AtkResultUpdateColumn>;
  where?: Maybe<AtkResultBoolExp>;
};

/** Ordering options when selecting data from "atk_result". */
export type AtkResultOrderBy = {
  brand_id?: Maybe<OrderBy>;
  brand_name?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  image?: Maybe<OrderBy>;
  infected?: Maybe<OrderBy>;
  time?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: atk_result */
export type AtkResultPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "atk_result" */
export enum AtkResultSelectColumn {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  BrandName = 'brand_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Infected = 'infected',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "atk_result" */
export type AtkResultSetInput = {
  brand_id?: Maybe<Scalars['uuid']>;
  brand_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  infected?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['timetz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "atk_result" */
export enum AtkResultUpdateColumn {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  BrandName = 'brand_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Infected = 'infected',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "auth.account_providers" */
export type AuthAccountProviders = {
  __typename?: 'auth_account_providers';
  /** An object relationship */
  account?: Maybe<AuthAccounts>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  provider?: Maybe<AuthProviders>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "auth.account_providers" */
export type AuthAccountProvidersAggregate = {
  __typename?: 'auth_account_providers_aggregate';
  aggregate?: Maybe<AuthAccountProvidersAggregateFields>;
  nodes: Array<Maybe<AuthAccountProviders>>;
};

/** aggregate fields of "auth.account_providers" */
export type AuthAccountProvidersAggregateFields = {
  __typename?: 'auth_account_providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<AuthAccountProvidersMaxFields>;
  min?: Maybe<AuthAccountProvidersMinFields>;
};


/** aggregate fields of "auth.account_providers" */
export type AuthAccountProvidersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_providers" */
export type AuthAccountProvidersAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthAccountProvidersMaxOrderBy>;
  min?: Maybe<AuthAccountProvidersMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.account_providers" */
export type AuthAccountProvidersArrRelInsertInput = {
  data: Array<AuthAccountProvidersInsertInput>;
  on_conflict?: Maybe<AuthAccountProvidersOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.account_providers". All fields are combined with a logical 'AND'. */
export type AuthAccountProvidersBoolExp = {
  _and?: Maybe<Array<Maybe<AuthAccountProvidersBoolExp>>>;
  _not?: Maybe<AuthAccountProvidersBoolExp>;
  _or?: Maybe<Array<Maybe<AuthAccountProvidersBoolExp>>>;
  account?: Maybe<AuthAccountsBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  auth_provider?: Maybe<StringComparisonExp>;
  auth_provider_unique_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  provider?: Maybe<AuthProvidersBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "auth.account_providers" */
export enum AuthAccountProvidersConstraint {
  /** unique or primary key constraint */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint */
  AccountProvidersPkey = 'account_providers_pkey'
}

/** input type for inserting data into table "auth.account_providers" */
export type AuthAccountProvidersInsertInput = {
  account?: Maybe<AuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<AuthProvidersObjRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AuthAccountProvidersMaxFields = {
  __typename?: 'auth_account_providers_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.account_providers" */
export type AuthAccountProvidersMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  auth_provider?: Maybe<OrderBy>;
  auth_provider_unique_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthAccountProvidersMinFields = {
  __typename?: 'auth_account_providers_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.account_providers" */
export type AuthAccountProvidersMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  auth_provider?: Maybe<OrderBy>;
  auth_provider_unique_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth.account_providers" */
export type AuthAccountProvidersMutationResponse = {
  __typename?: 'auth_account_providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows?: Maybe<Scalars['Int']>;
  /** data of the affected rows by the mutation */
  returning: Array<Maybe<AuthAccountProviders>>;
};

/** on conflict condition type for table "auth.account_providers" */
export type AuthAccountProvidersOnConflict = {
  constraint: AuthAccountProvidersConstraint;
  update_columns: Array<AuthAccountProvidersUpdateColumn>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};

/** ordering options when selecting data from "auth.account_providers" */
export type AuthAccountProvidersOrderBy = {
  account?: Maybe<AuthAccountsOrderBy>;
  account_id?: Maybe<OrderBy>;
  auth_provider?: Maybe<OrderBy>;
  auth_provider_unique_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  provider?: Maybe<AuthProvidersOrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "auth.account_providers" */
export type AuthAccountProvidersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_providers" */
export enum AuthAccountProvidersSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.account_providers" */
export type AuthAccountProvidersSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.account_providers" */
export enum AuthAccountProvidersUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "auth.account_roles" */
export type AuthAccountRoles = {
  __typename?: 'auth_account_roles';
  /** An object relationship */
  account?: Maybe<AuthAccounts>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  /** An object relationship */
  roleByRole?: Maybe<AuthRoles>;
};

/** aggregated selection of "auth.account_roles" */
export type AuthAccountRolesAggregate = {
  __typename?: 'auth_account_roles_aggregate';
  aggregate?: Maybe<AuthAccountRolesAggregateFields>;
  nodes: Array<Maybe<AuthAccountRoles>>;
};

/** aggregate fields of "auth.account_roles" */
export type AuthAccountRolesAggregateFields = {
  __typename?: 'auth_account_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<AuthAccountRolesMaxFields>;
  min?: Maybe<AuthAccountRolesMinFields>;
};


/** aggregate fields of "auth.account_roles" */
export type AuthAccountRolesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_roles" */
export type AuthAccountRolesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthAccountRolesMaxOrderBy>;
  min?: Maybe<AuthAccountRolesMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.account_roles" */
export type AuthAccountRolesArrRelInsertInput = {
  data: Array<AuthAccountRolesInsertInput>;
  on_conflict?: Maybe<AuthAccountRolesOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.account_roles". All fields are combined with a logical 'AND'. */
export type AuthAccountRolesBoolExp = {
  _and?: Maybe<Array<Maybe<AuthAccountRolesBoolExp>>>;
  _not?: Maybe<AuthAccountRolesBoolExp>;
  _or?: Maybe<Array<Maybe<AuthAccountRolesBoolExp>>>;
  account?: Maybe<AuthAccountsBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  role?: Maybe<StringComparisonExp>;
  roleByRole?: Maybe<AuthRolesBoolExp>;
};

/** unique or primary key constraints on table "auth.account_roles" */
export enum AuthAccountRolesConstraint {
  /** unique or primary key constraint */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key'
}

/** input type for inserting data into table "auth.account_roles" */
export type AuthAccountRolesInsertInput = {
  account?: Maybe<AuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  roleByRole?: Maybe<AuthRolesObjRelInsertInput>;
};

/** aggregate max on columns */
export type AuthAccountRolesMaxFields = {
  __typename?: 'auth_account_roles_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.account_roles" */
export type AuthAccountRolesMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthAccountRolesMinFields = {
  __typename?: 'auth_account_roles_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.account_roles" */
export type AuthAccountRolesMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth.account_roles" */
export type AuthAccountRolesMutationResponse = {
  __typename?: 'auth_account_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows?: Maybe<Scalars['Int']>;
  /** data of the affected rows by the mutation */
  returning: Array<Maybe<AuthAccountRoles>>;
};

/** on conflict condition type for table "auth.account_roles" */
export type AuthAccountRolesOnConflict = {
  constraint: AuthAccountRolesConstraint;
  update_columns: Array<AuthAccountRolesUpdateColumn>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};

/** ordering options when selecting data from "auth.account_roles" */
export type AuthAccountRolesOrderBy = {
  account?: Maybe<AuthAccountsOrderBy>;
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
  roleByRole?: Maybe<AuthRolesOrderBy>;
};

/** primary key columns input for table: "auth.account_roles" */
export type AuthAccountRolesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_roles" */
export enum AuthAccountRolesSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.account_roles" */
export type AuthAccountRolesSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.account_roles" */
export enum AuthAccountRolesUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** columns and relationships of "auth.accounts" */
export type AuthAccounts = {
  __typename?: 'auth_accounts';
  /** An array relationship */
  account_providers: Array<Maybe<AuthAccountProviders>>;
  /** An aggregated array relationship */
  account_providers_aggregate?: Maybe<AuthAccountProvidersAggregate>;
  /** An array relationship */
  account_roles: Array<Maybe<AuthAccountRoles>>;
  /** An aggregated array relationship */
  account_roles_aggregate?: Maybe<AuthAccountRolesAggregate>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  refresh_tokens: Array<Maybe<AuthRefreshTokens>>;
  /** An aggregated array relationship */
  refresh_tokens_aggregate?: Maybe<AuthRefreshTokensAggregate>;
  /** An object relationship */
  role?: Maybe<AuthRoles>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsAccountProvidersArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsAccountProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsAccountRolesArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsAccountRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsCustomRegisterDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsRefreshTokensArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsRefreshTokensAggregateArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};

/** aggregated selection of "auth.accounts" */
export type AuthAccountsAggregate = {
  __typename?: 'auth_accounts_aggregate';
  aggregate?: Maybe<AuthAccountsAggregateFields>;
  nodes: Array<Maybe<AuthAccounts>>;
};

/** aggregate fields of "auth.accounts" */
export type AuthAccountsAggregateFields = {
  __typename?: 'auth_accounts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<AuthAccountsMaxFields>;
  min?: Maybe<AuthAccountsMinFields>;
};


/** aggregate fields of "auth.accounts" */
export type AuthAccountsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthAccountsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.accounts" */
export type AuthAccountsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthAccountsMaxOrderBy>;
  min?: Maybe<AuthAccountsMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthAccountsAppendInput = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.accounts" */
export type AuthAccountsArrRelInsertInput = {
  data: Array<AuthAccountsInsertInput>;
  on_conflict?: Maybe<AuthAccountsOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export type AuthAccountsBoolExp = {
  _and?: Maybe<Array<Maybe<AuthAccountsBoolExp>>>;
  _not?: Maybe<AuthAccountsBoolExp>;
  _or?: Maybe<Array<Maybe<AuthAccountsBoolExp>>>;
  account_providers?: Maybe<AuthAccountProvidersBoolExp>;
  account_roles?: Maybe<AuthAccountRolesBoolExp>;
  active?: Maybe<BooleanComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  custom_register_data?: Maybe<JsonbComparisonExp>;
  default_role?: Maybe<StringComparisonExp>;
  email?: Maybe<CitextComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_anonymous?: Maybe<BooleanComparisonExp>;
  mfa_enabled?: Maybe<BooleanComparisonExp>;
  new_email?: Maybe<CitextComparisonExp>;
  otp_secret?: Maybe<StringComparisonExp>;
  password_hash?: Maybe<StringComparisonExp>;
  refresh_tokens?: Maybe<AuthRefreshTokensBoolExp>;
  role?: Maybe<AuthRolesBoolExp>;
  ticket?: Maybe<UuidComparisonExp>;
  ticket_expires_at?: Maybe<TimestamptzComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.accounts" */
export enum AuthAccountsConstraint {
  /** unique or primary key constraint */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint */
  AccountsUserIdKey = 'accounts_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthAccountsDeleteAtPathInput = {
  custom_register_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthAccountsDeleteElemInput = {
  custom_register_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthAccountsDeleteKeyInput = {
  custom_register_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.accounts" */
export type AuthAccountsInsertInput = {
  account_providers?: Maybe<AuthAccountProvidersArrRelInsertInput>;
  account_roles?: Maybe<AuthAccountRolesArrRelInsertInput>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  refresh_tokens?: Maybe<AuthRefreshTokensArrRelInsertInput>;
  role?: Maybe<AuthRolesObjRelInsertInput>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<UsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthAccountsMaxFields = {
  __typename?: 'auth_accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.accounts" */
export type AuthAccountsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  default_role?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_email?: Maybe<OrderBy>;
  otp_secret?: Maybe<OrderBy>;
  password_hash?: Maybe<OrderBy>;
  ticket?: Maybe<OrderBy>;
  ticket_expires_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthAccountsMinFields = {
  __typename?: 'auth_accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.accounts" */
export type AuthAccountsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  default_role?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_email?: Maybe<OrderBy>;
  otp_secret?: Maybe<OrderBy>;
  password_hash?: Maybe<OrderBy>;
  ticket?: Maybe<OrderBy>;
  ticket_expires_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth.accounts" */
export type AuthAccountsMutationResponse = {
  __typename?: 'auth_accounts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows?: Maybe<Scalars['Int']>;
  /** data of the affected rows by the mutation */
  returning: Array<Maybe<AuthAccounts>>;
};

/** input type for inserting object relation for remote table "auth.accounts" */
export type AuthAccountsObjRelInsertInput = {
  data: AuthAccountsInsertInput;
  on_conflict?: Maybe<AuthAccountsOnConflict>;
};

/** on conflict condition type for table "auth.accounts" */
export type AuthAccountsOnConflict = {
  constraint: AuthAccountsConstraint;
  update_columns: Array<AuthAccountsUpdateColumn>;
  where?: Maybe<AuthAccountsBoolExp>;
};

/** ordering options when selecting data from "auth.accounts" */
export type AuthAccountsOrderBy = {
  account_providers_aggregate?: Maybe<AuthAccountProvidersAggregateOrderBy>;
  account_roles_aggregate?: Maybe<AuthAccountRolesAggregateOrderBy>;
  active?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  custom_register_data?: Maybe<OrderBy>;
  default_role?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_anonymous?: Maybe<OrderBy>;
  mfa_enabled?: Maybe<OrderBy>;
  new_email?: Maybe<OrderBy>;
  otp_secret?: Maybe<OrderBy>;
  password_hash?: Maybe<OrderBy>;
  refresh_tokens_aggregate?: Maybe<AuthRefreshTokensAggregateOrderBy>;
  role?: Maybe<AuthRolesOrderBy>;
  ticket?: Maybe<OrderBy>;
  ticket_expires_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "auth.accounts" */
export type AuthAccountsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthAccountsPrependInput = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "auth.accounts" */
export enum AuthAccountsSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.accounts" */
export type AuthAccountsSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.accounts" */
export enum AuthAccountsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "auth.providers" */
export type AuthProviders = {
  __typename?: 'auth_providers';
  /** An array relationship */
  account_providers: Array<Maybe<AuthAccountProviders>>;
  /** An aggregated array relationship */
  account_providers_aggregate?: Maybe<AuthAccountProvidersAggregate>;
  provider?: Maybe<Scalars['String']>;
};


/** columns and relationships of "auth.providers" */
export type AuthProvidersAccountProvidersArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


/** columns and relationships of "auth.providers" */
export type AuthProvidersAccountProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};

/** aggregated selection of "auth.providers" */
export type AuthProvidersAggregate = {
  __typename?: 'auth_providers_aggregate';
  aggregate?: Maybe<AuthProvidersAggregateFields>;
  nodes: Array<Maybe<AuthProviders>>;
};

/** aggregate fields of "auth.providers" */
export type AuthProvidersAggregateFields = {
  __typename?: 'auth_providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<AuthProvidersMaxFields>;
  min?: Maybe<AuthProvidersMinFields>;
};


/** aggregate fields of "auth.providers" */
export type AuthProvidersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthProvidersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type AuthProvidersBoolExp = {
  _and?: Maybe<Array<Maybe<AuthProvidersBoolExp>>>;
  _not?: Maybe<AuthProvidersBoolExp>;
  _or?: Maybe<Array<Maybe<AuthProvidersBoolExp>>>;
  account_providers?: Maybe<AuthAccountProvidersBoolExp>;
  provider?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum AuthProvidersConstraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type AuthProvidersInsertInput = {
  account_providers?: Maybe<AuthAccountProvidersArrRelInsertInput>;
  provider?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AuthProvidersMaxFields = {
  __typename?: 'auth_providers_max_fields';
  provider?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AuthProvidersMinFields = {
  __typename?: 'auth_providers_min_fields';
  provider?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.providers" */
export type AuthProvidersMutationResponse = {
  __typename?: 'auth_providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows?: Maybe<Scalars['Int']>;
  /** data of the affected rows by the mutation */
  returning: Array<Maybe<AuthProviders>>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type AuthProvidersObjRelInsertInput = {
  data: AuthProvidersInsertInput;
  on_conflict?: Maybe<AuthProvidersOnConflict>;
};

/** on conflict condition type for table "auth.providers" */
export type AuthProvidersOnConflict = {
  constraint: AuthProvidersConstraint;
  update_columns: Array<AuthProvidersUpdateColumn>;
  where?: Maybe<AuthProvidersBoolExp>;
};

/** ordering options when selecting data from "auth.providers" */
export type AuthProvidersOrderBy = {
  account_providers_aggregate?: Maybe<AuthAccountProvidersAggregateOrderBy>;
  provider?: Maybe<OrderBy>;
};

/** primary key columns input for table: "auth.providers" */
export type AuthProvidersPkColumnsInput = {
  provider: Scalars['String'];
};

/** select columns of table "auth.providers" */
export enum AuthProvidersSelectColumn {
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "auth.providers" */
export type AuthProvidersSetInput = {
  provider?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.providers" */
export enum AuthProvidersUpdateColumn {
  /** column name */
  Provider = 'provider'
}

/** columns and relationships of "auth.refresh_tokens" */
export type AuthRefreshTokens = {
  __typename?: 'auth_refresh_tokens';
  /** An object relationship */
  account?: Maybe<AuthAccounts>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregate = {
  __typename?: 'auth_refresh_tokens_aggregate';
  aggregate?: Maybe<AuthRefreshTokensAggregateFields>;
  nodes: Array<Maybe<AuthRefreshTokens>>;
};

/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateFields = {
  __typename?: 'auth_refresh_tokens_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<AuthRefreshTokensMaxFields>;
  min?: Maybe<AuthRefreshTokensMinFields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthRefreshTokensMaxOrderBy>;
  min?: Maybe<AuthRefreshTokensMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type AuthRefreshTokensArrRelInsertInput = {
  data: Array<AuthRefreshTokensInsertInput>;
  on_conflict?: Maybe<AuthRefreshTokensOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokensBoolExp = {
  _and?: Maybe<Array<Maybe<AuthRefreshTokensBoolExp>>>;
  _not?: Maybe<AuthRefreshTokensBoolExp>;
  _or?: Maybe<Array<Maybe<AuthRefreshTokensBoolExp>>>;
  account?: Maybe<AuthAccountsBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  expires_at?: Maybe<TimestamptzComparisonExp>;
  refresh_token?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum AuthRefreshTokensConstraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** input type for inserting data into table "auth.refresh_tokens" */
export type AuthRefreshTokensInsertInput = {
  account?: Maybe<AuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthRefreshTokensMaxFields = {
  __typename?: 'auth_refresh_tokens_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokensMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expires_at?: Maybe<OrderBy>;
  refresh_token?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthRefreshTokensMinFields = {
  __typename?: 'auth_refresh_tokens_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokensMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expires_at?: Maybe<OrderBy>;
  refresh_token?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type AuthRefreshTokensMutationResponse = {
  __typename?: 'auth_refresh_tokens_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows?: Maybe<Scalars['Int']>;
  /** data of the affected rows by the mutation */
  returning: Array<Maybe<AuthRefreshTokens>>;
};

/** on conflict condition type for table "auth.refresh_tokens" */
export type AuthRefreshTokensOnConflict = {
  constraint: AuthRefreshTokensConstraint;
  update_columns: Array<AuthRefreshTokensUpdateColumn>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};

/** ordering options when selecting data from "auth.refresh_tokens" */
export type AuthRefreshTokensOrderBy = {
  account?: Maybe<AuthAccountsOrderBy>;
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expires_at?: Maybe<OrderBy>;
  refresh_token?: Maybe<OrderBy>;
};

/** primary key columns input for table: "auth.refresh_tokens" */
export type AuthRefreshTokensPkColumnsInput = {
  refresh_token: Scalars['uuid'];
};

/** select columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokensSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type AuthRefreshTokensSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokensUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** columns and relationships of "auth.roles" */
export type AuthRoles = {
  __typename?: 'auth_roles';
  /** An array relationship */
  account_roles: Array<Maybe<AuthAccountRoles>>;
  /** An aggregated array relationship */
  account_roles_aggregate?: Maybe<AuthAccountRolesAggregate>;
  /** An array relationship */
  accounts: Array<Maybe<AuthAccounts>>;
  /** An aggregated array relationship */
  accounts_aggregate?: Maybe<AuthAccountsAggregate>;
  role?: Maybe<Scalars['String']>;
};


/** columns and relationships of "auth.roles" */
export type AuthRolesAccountRolesArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


/** columns and relationships of "auth.roles" */
export type AuthRolesAccountRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


/** columns and relationships of "auth.roles" */
export type AuthRolesAccountsArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};


/** columns and relationships of "auth.roles" */
export type AuthRolesAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};

/** aggregated selection of "auth.roles" */
export type AuthRolesAggregate = {
  __typename?: 'auth_roles_aggregate';
  aggregate?: Maybe<AuthRolesAggregateFields>;
  nodes: Array<Maybe<AuthRoles>>;
};

/** aggregate fields of "auth.roles" */
export type AuthRolesAggregateFields = {
  __typename?: 'auth_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<AuthRolesMaxFields>;
  min?: Maybe<AuthRolesMinFields>;
};


/** aggregate fields of "auth.roles" */
export type AuthRolesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthRolesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type AuthRolesBoolExp = {
  _and?: Maybe<Array<Maybe<AuthRolesBoolExp>>>;
  _not?: Maybe<AuthRolesBoolExp>;
  _or?: Maybe<Array<Maybe<AuthRolesBoolExp>>>;
  account_roles?: Maybe<AuthAccountRolesBoolExp>;
  accounts?: Maybe<AuthAccountsBoolExp>;
  role?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum AuthRolesConstraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export type AuthRolesInsertInput = {
  account_roles?: Maybe<AuthAccountRolesArrRelInsertInput>;
  accounts?: Maybe<AuthAccountsArrRelInsertInput>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AuthRolesMaxFields = {
  __typename?: 'auth_roles_max_fields';
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AuthRolesMinFields = {
  __typename?: 'auth_roles_min_fields';
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.roles" */
export type AuthRolesMutationResponse = {
  __typename?: 'auth_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows?: Maybe<Scalars['Int']>;
  /** data of the affected rows by the mutation */
  returning: Array<Maybe<AuthRoles>>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type AuthRolesObjRelInsertInput = {
  data: AuthRolesInsertInput;
  on_conflict?: Maybe<AuthRolesOnConflict>;
};

/** on conflict condition type for table "auth.roles" */
export type AuthRolesOnConflict = {
  constraint: AuthRolesConstraint;
  update_columns: Array<AuthRolesUpdateColumn>;
  where?: Maybe<AuthRolesBoolExp>;
};

/** ordering options when selecting data from "auth.roles" */
export type AuthRolesOrderBy = {
  account_roles_aggregate?: Maybe<AuthAccountRolesAggregateOrderBy>;
  accounts_aggregate?: Maybe<AuthAccountsAggregateOrderBy>;
  role?: Maybe<OrderBy>;
};

/** primary key columns input for table: "auth.roles" */
export type AuthRolesPkColumnsInput = {
  role: Scalars['String'];
};

/** select columns of table "auth.roles" */
export enum AuthRolesSelectColumn {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export type AuthRolesSetInput = {
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.roles" */
export enum AuthRolesUpdateColumn {
  /** column name */
  Role = 'role'
}


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "building" */
export type Building = {
  __typename?: 'building';
  check_in_count: Scalars['bigint'];
  check_out_count: Scalars['bigint'];
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['String'];
  manager_email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization_name?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  scan_count: Scalars['bigint'];
  /** An array relationship */
  traces: Array<Trace>;
  /** An aggregate relationship */
  traces_aggregate: TraceAggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "building" */
export type BuildingTracesArgs = {
  distinct_on?: Maybe<Array<TraceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceOrderBy>>;
  where?: Maybe<TraceBoolExp>;
};


/** columns and relationships of "building" */
export type BuildingTracesAggregateArgs = {
  distinct_on?: Maybe<Array<TraceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceOrderBy>>;
  where?: Maybe<TraceBoolExp>;
};

/** aggregated selection of "building" */
export type BuildingAggregate = {
  __typename?: 'building_aggregate';
  aggregate?: Maybe<BuildingAggregateFields>;
  nodes: Array<Building>;
};

/** aggregate fields of "building" */
export type BuildingAggregateFields = {
  __typename?: 'building_aggregate_fields';
  avg?: Maybe<BuildingAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BuildingMaxFields>;
  min?: Maybe<BuildingMinFields>;
  stddev?: Maybe<BuildingStddevFields>;
  stddev_pop?: Maybe<BuildingStddevPopFields>;
  stddev_samp?: Maybe<BuildingStddevSampFields>;
  sum?: Maybe<BuildingSumFields>;
  var_pop?: Maybe<BuildingVarPopFields>;
  var_samp?: Maybe<BuildingVarSampFields>;
  variance?: Maybe<BuildingVarianceFields>;
};


/** aggregate fields of "building" */
export type BuildingAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BuildingSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BuildingAvgFields = {
  __typename?: 'building_avg_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
  scan_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "building". All fields are combined with a logical 'AND'. */
export type BuildingBoolExp = {
  _and?: Maybe<Array<BuildingBoolExp>>;
  _not?: Maybe<BuildingBoolExp>;
  _or?: Maybe<Array<BuildingBoolExp>>;
  check_in_count?: Maybe<BigintComparisonExp>;
  check_out_count?: Maybe<BigintComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  manager_email?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  organization_name?: Maybe<StringComparisonExp>;
  qrcode?: Maybe<StringComparisonExp>;
  scan_count?: Maybe<BigintComparisonExp>;
  traces?: Maybe<TraceBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "building" */
export enum BuildingConstraint {
  /** unique or primary key constraint */
  BuildingPkey = 'building_pkey'
}

/** input type for incrementing numeric columns in table "building" */
export type BuildingIncInput = {
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  scan_count?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "building" */
export type BuildingInsertInput = {
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  manager_email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  scan_count?: Maybe<Scalars['bigint']>;
  traces?: Maybe<TraceArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BuildingMaxFields = {
  __typename?: 'building_max_fields';
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  manager_email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  scan_count?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type BuildingMinFields = {
  __typename?: 'building_min_fields';
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  manager_email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  scan_count?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "building" */
export type BuildingMutationResponse = {
  __typename?: 'building_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Building>;
};

/** input type for inserting object relation for remote table "building" */
export type BuildingObjRelInsertInput = {
  data: BuildingInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<BuildingOnConflict>;
};

/** on conflict condition type for table "building" */
export type BuildingOnConflict = {
  constraint: BuildingConstraint;
  update_columns: Array<BuildingUpdateColumn>;
  where?: Maybe<BuildingBoolExp>;
};

/** Ordering options when selecting data from "building". */
export type BuildingOrderBy = {
  check_in_count?: Maybe<OrderBy>;
  check_out_count?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  manager_email?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  organization_name?: Maybe<OrderBy>;
  qrcode?: Maybe<OrderBy>;
  scan_count?: Maybe<OrderBy>;
  traces_aggregate?: Maybe<TraceAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: building */
export type BuildingPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "building" */
export enum BuildingSelectColumn {
  /** column name */
  CheckInCount = 'check_in_count',
  /** column name */
  CheckOutCount = 'check_out_count',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerEmail = 'manager_email',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  Qrcode = 'qrcode',
  /** column name */
  ScanCount = 'scan_count',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "building" */
export type BuildingSetInput = {
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  manager_email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['String']>;
  scan_count?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type BuildingStddevFields = {
  __typename?: 'building_stddev_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
  scan_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BuildingStddevPopFields = {
  __typename?: 'building_stddev_pop_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
  scan_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BuildingStddevSampFields = {
  __typename?: 'building_stddev_samp_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
  scan_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BuildingSumFields = {
  __typename?: 'building_sum_fields';
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  scan_count?: Maybe<Scalars['bigint']>;
};

/** update columns of table "building" */
export enum BuildingUpdateColumn {
  /** column name */
  CheckInCount = 'check_in_count',
  /** column name */
  CheckOutCount = 'check_out_count',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerEmail = 'manager_email',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  Qrcode = 'qrcode',
  /** column name */
  ScanCount = 'scan_count',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type BuildingVarPopFields = {
  __typename?: 'building_var_pop_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
  scan_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BuildingVarSampFields = {
  __typename?: 'building_var_samp_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
  scan_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BuildingVarianceFields = {
  __typename?: 'building_variance_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
  scan_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "check_in_by_date" */
export type CheckInByDate = {
  __typename?: 'check_in_by_date';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "check_in_by_date" */
export type CheckInByDateAggregate = {
  __typename?: 'check_in_by_date_aggregate';
  aggregate?: Maybe<CheckInByDateAggregateFields>;
  nodes: Array<CheckInByDate>;
};

/** aggregate fields of "check_in_by_date" */
export type CheckInByDateAggregateFields = {
  __typename?: 'check_in_by_date_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CheckInByDateMaxFields>;
  min?: Maybe<CheckInByDateMinFields>;
};


/** aggregate fields of "check_in_by_date" */
export type CheckInByDateAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CheckInByDateSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "check_in_by_date". All fields are combined with a logical 'AND'. */
export type CheckInByDateBoolExp = {
  _and?: Maybe<Array<CheckInByDateBoolExp>>;
  _not?: Maybe<CheckInByDateBoolExp>;
  _or?: Maybe<Array<CheckInByDateBoolExp>>;
  building_id?: Maybe<StringComparisonExp>;
  contact_name?: Maybe<StringComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  timestamp?: Maybe<TimestamptzComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type CheckInByDateMaxFields = {
  __typename?: 'check_in_by_date_max_fields';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CheckInByDateMinFields = {
  __typename?: 'check_in_by_date_min_fields';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "check_in_by_date". */
export type CheckInByDateOrderBy = {
  building_id?: Maybe<OrderBy>;
  contact_name?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "check_in_by_date" */
export enum CheckInByDateSelectColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Tel = 'tel',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "check_in_out" */
export type CheckInOut = {
  __typename?: 'check_in_out';
  /** An object relationship */
  building?: Maybe<Building>;
  building_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "check_in_out" */
export type CheckInOutAggregate = {
  __typename?: 'check_in_out_aggregate';
  aggregate?: Maybe<CheckInOutAggregateFields>;
  nodes: Array<CheckInOut>;
};

/** aggregate fields of "check_in_out" */
export type CheckInOutAggregateFields = {
  __typename?: 'check_in_out_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CheckInOutMaxFields>;
  min?: Maybe<CheckInOutMinFields>;
};


/** aggregate fields of "check_in_out" */
export type CheckInOutAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CheckInOutSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "check_in_out". All fields are combined with a logical 'AND'. */
export type CheckInOutBoolExp = {
  _and?: Maybe<Array<CheckInOutBoolExp>>;
  _not?: Maybe<CheckInOutBoolExp>;
  _or?: Maybe<Array<CheckInOutBoolExp>>;
  building?: Maybe<BuildingBoolExp>;
  building_id?: Maybe<StringComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  contact_name?: Maybe<StringComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  timestamp?: Maybe<TimestamptzComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** columns and relationships of "check_in_out_by_date" */
export type CheckInOutByDate = {
  __typename?: 'check_in_out_by_date';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "check_in_out_by_date" */
export type CheckInOutByDateAggregate = {
  __typename?: 'check_in_out_by_date_aggregate';
  aggregate?: Maybe<CheckInOutByDateAggregateFields>;
  nodes: Array<CheckInOutByDate>;
};

/** aggregate fields of "check_in_out_by_date" */
export type CheckInOutByDateAggregateFields = {
  __typename?: 'check_in_out_by_date_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CheckInOutByDateMaxFields>;
  min?: Maybe<CheckInOutByDateMinFields>;
};


/** aggregate fields of "check_in_out_by_date" */
export type CheckInOutByDateAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CheckInOutByDateSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "check_in_out_by_date". All fields are combined with a logical 'AND'. */
export type CheckInOutByDateBoolExp = {
  _and?: Maybe<Array<CheckInOutByDateBoolExp>>;
  _not?: Maybe<CheckInOutByDateBoolExp>;
  _or?: Maybe<Array<CheckInOutByDateBoolExp>>;
  building_id?: Maybe<StringComparisonExp>;
  contact_name?: Maybe<StringComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  timestamp?: Maybe<TimestamptzComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type CheckInOutByDateMaxFields = {
  __typename?: 'check_in_out_by_date_max_fields';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CheckInOutByDateMinFields = {
  __typename?: 'check_in_out_by_date_min_fields';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "check_in_out_by_date". */
export type CheckInOutByDateOrderBy = {
  building_id?: Maybe<OrderBy>;
  contact_name?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "check_in_out_by_date" */
export enum CheckInOutByDateSelectColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Tel = 'tel',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "check_in_out_daily" */
export type CheckInOutDaily = {
  __typename?: 'check_in_out_daily';
  building_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<UserEmail>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "check_in_out_daily" */
export type CheckInOutDailyAggregate = {
  __typename?: 'check_in_out_daily_aggregate';
  aggregate?: Maybe<CheckInOutDailyAggregateFields>;
  nodes: Array<CheckInOutDaily>;
};

/** aggregate fields of "check_in_out_daily" */
export type CheckInOutDailyAggregateFields = {
  __typename?: 'check_in_out_daily_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CheckInOutDailyMaxFields>;
  min?: Maybe<CheckInOutDailyMinFields>;
};


/** aggregate fields of "check_in_out_daily" */
export type CheckInOutDailyAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CheckInOutDailySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "check_in_out_daily". All fields are combined with a logical 'AND'. */
export type CheckInOutDailyBoolExp = {
  _and?: Maybe<Array<CheckInOutDailyBoolExp>>;
  _not?: Maybe<CheckInOutDailyBoolExp>;
  _or?: Maybe<Array<CheckInOutDailyBoolExp>>;
  building_id?: Maybe<StringComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  contact_name?: Maybe<StringComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  timestamp?: Maybe<TimestamptzComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  user?: Maybe<UserEmailBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type CheckInOutDailyMaxFields = {
  __typename?: 'check_in_out_daily_max_fields';
  building_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CheckInOutDailyMinFields = {
  __typename?: 'check_in_out_daily_min_fields';
  building_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "check_in_out_daily". */
export type CheckInOutDailyOrderBy = {
  building_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  contact_name?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  user?: Maybe<UserEmailOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "check_in_out_daily" */
export enum CheckInOutDailySelectColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  Code = 'code',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  Date = 'date',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Tel = 'tel',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** aggregate max on columns */
export type CheckInOutMaxFields = {
  __typename?: 'check_in_out_max_fields';
  building_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CheckInOutMinFields = {
  __typename?: 'check_in_out_min_fields';
  building_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "check_in_out". */
export type CheckInOutOrderBy = {
  building?: Maybe<BuildingOrderBy>;
  building_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  contact_name?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "check_in_out" */
export enum CheckInOutSelectColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  Code = 'code',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  Date = 'date',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Tel = 'tel',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "check_in_out_summary_by_building" */
export type CheckInOutSummaryByBuilding = {
  __typename?: 'check_in_out_summary_by_building';
  /** An object relationship */
  building?: Maybe<Building>;
  building_id?: Maybe<Scalars['String']>;
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** aggregated selection of "check_in_out_summary_by_building" */
export type CheckInOutSummaryByBuildingAggregate = {
  __typename?: 'check_in_out_summary_by_building_aggregate';
  aggregate?: Maybe<CheckInOutSummaryByBuildingAggregateFields>;
  nodes: Array<CheckInOutSummaryByBuilding>;
};

/** aggregate fields of "check_in_out_summary_by_building" */
export type CheckInOutSummaryByBuildingAggregateFields = {
  __typename?: 'check_in_out_summary_by_building_aggregate_fields';
  avg?: Maybe<CheckInOutSummaryByBuildingAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CheckInOutSummaryByBuildingMaxFields>;
  min?: Maybe<CheckInOutSummaryByBuildingMinFields>;
  stddev?: Maybe<CheckInOutSummaryByBuildingStddevFields>;
  stddev_pop?: Maybe<CheckInOutSummaryByBuildingStddevPopFields>;
  stddev_samp?: Maybe<CheckInOutSummaryByBuildingStddevSampFields>;
  sum?: Maybe<CheckInOutSummaryByBuildingSumFields>;
  var_pop?: Maybe<CheckInOutSummaryByBuildingVarPopFields>;
  var_samp?: Maybe<CheckInOutSummaryByBuildingVarSampFields>;
  variance?: Maybe<CheckInOutSummaryByBuildingVarianceFields>;
};


/** aggregate fields of "check_in_out_summary_by_building" */
export type CheckInOutSummaryByBuildingAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CheckInOutSummaryByBuildingSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CheckInOutSummaryByBuildingAvgFields = {
  __typename?: 'check_in_out_summary_by_building_avg_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "check_in_out_summary_by_building". All fields are combined with a logical 'AND'. */
export type CheckInOutSummaryByBuildingBoolExp = {
  _and?: Maybe<Array<CheckInOutSummaryByBuildingBoolExp>>;
  _not?: Maybe<CheckInOutSummaryByBuildingBoolExp>;
  _or?: Maybe<Array<CheckInOutSummaryByBuildingBoolExp>>;
  building?: Maybe<BuildingBoolExp>;
  building_id?: Maybe<StringComparisonExp>;
  check_in_count?: Maybe<BigintComparisonExp>;
  check_out_count?: Maybe<BigintComparisonExp>;
  date?: Maybe<DateComparisonExp>;
};

/** columns and relationships of "check_in_out_summary_by_building_daily" */
export type CheckInOutSummaryByBuildingDaily = {
  __typename?: 'check_in_out_summary_by_building_daily';
  /** An object relationship */
  building?: Maybe<Building>;
  building_id?: Maybe<Scalars['String']>;
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** aggregated selection of "check_in_out_summary_by_building_daily" */
export type CheckInOutSummaryByBuildingDailyAggregate = {
  __typename?: 'check_in_out_summary_by_building_daily_aggregate';
  aggregate?: Maybe<CheckInOutSummaryByBuildingDailyAggregateFields>;
  nodes: Array<CheckInOutSummaryByBuildingDaily>;
};

/** aggregate fields of "check_in_out_summary_by_building_daily" */
export type CheckInOutSummaryByBuildingDailyAggregateFields = {
  __typename?: 'check_in_out_summary_by_building_daily_aggregate_fields';
  avg?: Maybe<CheckInOutSummaryByBuildingDailyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CheckInOutSummaryByBuildingDailyMaxFields>;
  min?: Maybe<CheckInOutSummaryByBuildingDailyMinFields>;
  stddev?: Maybe<CheckInOutSummaryByBuildingDailyStddevFields>;
  stddev_pop?: Maybe<CheckInOutSummaryByBuildingDailyStddevPopFields>;
  stddev_samp?: Maybe<CheckInOutSummaryByBuildingDailyStddevSampFields>;
  sum?: Maybe<CheckInOutSummaryByBuildingDailySumFields>;
  var_pop?: Maybe<CheckInOutSummaryByBuildingDailyVarPopFields>;
  var_samp?: Maybe<CheckInOutSummaryByBuildingDailyVarSampFields>;
  variance?: Maybe<CheckInOutSummaryByBuildingDailyVarianceFields>;
};


/** aggregate fields of "check_in_out_summary_by_building_daily" */
export type CheckInOutSummaryByBuildingDailyAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CheckInOutSummaryByBuildingDailySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CheckInOutSummaryByBuildingDailyAvgFields = {
  __typename?: 'check_in_out_summary_by_building_daily_avg_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "check_in_out_summary_by_building_daily". All fields are combined with a logical 'AND'. */
export type CheckInOutSummaryByBuildingDailyBoolExp = {
  _and?: Maybe<Array<CheckInOutSummaryByBuildingDailyBoolExp>>;
  _not?: Maybe<CheckInOutSummaryByBuildingDailyBoolExp>;
  _or?: Maybe<Array<CheckInOutSummaryByBuildingDailyBoolExp>>;
  building?: Maybe<BuildingBoolExp>;
  building_id?: Maybe<StringComparisonExp>;
  check_in_count?: Maybe<BigintComparisonExp>;
  check_out_count?: Maybe<BigintComparisonExp>;
  date?: Maybe<DateComparisonExp>;
};

/** aggregate max on columns */
export type CheckInOutSummaryByBuildingDailyMaxFields = {
  __typename?: 'check_in_out_summary_by_building_daily_max_fields';
  building_id?: Maybe<Scalars['String']>;
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type CheckInOutSummaryByBuildingDailyMinFields = {
  __typename?: 'check_in_out_summary_by_building_daily_min_fields';
  building_id?: Maybe<Scalars['String']>;
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** Ordering options when selecting data from "check_in_out_summary_by_building_daily". */
export type CheckInOutSummaryByBuildingDailyOrderBy = {
  building?: Maybe<BuildingOrderBy>;
  building_id?: Maybe<OrderBy>;
  check_in_count?: Maybe<OrderBy>;
  check_out_count?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
};

/** select columns of table "check_in_out_summary_by_building_daily" */
export enum CheckInOutSummaryByBuildingDailySelectColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CheckInCount = 'check_in_count',
  /** column name */
  CheckOutCount = 'check_out_count',
  /** column name */
  Date = 'date'
}

/** aggregate stddev on columns */
export type CheckInOutSummaryByBuildingDailyStddevFields = {
  __typename?: 'check_in_out_summary_by_building_daily_stddev_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CheckInOutSummaryByBuildingDailyStddevPopFields = {
  __typename?: 'check_in_out_summary_by_building_daily_stddev_pop_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CheckInOutSummaryByBuildingDailyStddevSampFields = {
  __typename?: 'check_in_out_summary_by_building_daily_stddev_samp_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CheckInOutSummaryByBuildingDailySumFields = {
  __typename?: 'check_in_out_summary_by_building_daily_sum_fields';
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type CheckInOutSummaryByBuildingDailyVarPopFields = {
  __typename?: 'check_in_out_summary_by_building_daily_var_pop_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CheckInOutSummaryByBuildingDailyVarSampFields = {
  __typename?: 'check_in_out_summary_by_building_daily_var_samp_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CheckInOutSummaryByBuildingDailyVarianceFields = {
  __typename?: 'check_in_out_summary_by_building_daily_variance_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type CheckInOutSummaryByBuildingMaxFields = {
  __typename?: 'check_in_out_summary_by_building_max_fields';
  building_id?: Maybe<Scalars['String']>;
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type CheckInOutSummaryByBuildingMinFields = {
  __typename?: 'check_in_out_summary_by_building_min_fields';
  building_id?: Maybe<Scalars['String']>;
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** Ordering options when selecting data from "check_in_out_summary_by_building". */
export type CheckInOutSummaryByBuildingOrderBy = {
  building?: Maybe<BuildingOrderBy>;
  building_id?: Maybe<OrderBy>;
  check_in_count?: Maybe<OrderBy>;
  check_out_count?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
};

/** select columns of table "check_in_out_summary_by_building" */
export enum CheckInOutSummaryByBuildingSelectColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CheckInCount = 'check_in_count',
  /** column name */
  CheckOutCount = 'check_out_count',
  /** column name */
  Date = 'date'
}

/** aggregate stddev on columns */
export type CheckInOutSummaryByBuildingStddevFields = {
  __typename?: 'check_in_out_summary_by_building_stddev_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CheckInOutSummaryByBuildingStddevPopFields = {
  __typename?: 'check_in_out_summary_by_building_stddev_pop_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CheckInOutSummaryByBuildingStddevSampFields = {
  __typename?: 'check_in_out_summary_by_building_stddev_samp_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CheckInOutSummaryByBuildingSumFields = {
  __typename?: 'check_in_out_summary_by_building_sum_fields';
  check_in_count?: Maybe<Scalars['bigint']>;
  check_out_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type CheckInOutSummaryByBuildingVarPopFields = {
  __typename?: 'check_in_out_summary_by_building_var_pop_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CheckInOutSummaryByBuildingVarSampFields = {
  __typename?: 'check_in_out_summary_by_building_var_samp_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CheckInOutSummaryByBuildingVarianceFields = {
  __typename?: 'check_in_out_summary_by_building_variance_fields';
  check_in_count?: Maybe<Scalars['Float']>;
  check_out_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "check_out_by_date" */
export type CheckOutByDate = {
  __typename?: 'check_out_by_date';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "check_out_by_date" */
export type CheckOutByDateAggregate = {
  __typename?: 'check_out_by_date_aggregate';
  aggregate?: Maybe<CheckOutByDateAggregateFields>;
  nodes: Array<CheckOutByDate>;
};

/** aggregate fields of "check_out_by_date" */
export type CheckOutByDateAggregateFields = {
  __typename?: 'check_out_by_date_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CheckOutByDateMaxFields>;
  min?: Maybe<CheckOutByDateMinFields>;
};


/** aggregate fields of "check_out_by_date" */
export type CheckOutByDateAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CheckOutByDateSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "check_out_by_date". All fields are combined with a logical 'AND'. */
export type CheckOutByDateBoolExp = {
  _and?: Maybe<Array<CheckOutByDateBoolExp>>;
  _not?: Maybe<CheckOutByDateBoolExp>;
  _or?: Maybe<Array<CheckOutByDateBoolExp>>;
  building_id?: Maybe<StringComparisonExp>;
  contact_name?: Maybe<StringComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  timestamp?: Maybe<TimestamptzComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type CheckOutByDateMaxFields = {
  __typename?: 'check_out_by_date_max_fields';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CheckOutByDateMinFields = {
  __typename?: 'check_out_by_date_min_fields';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "check_out_by_date". */
export type CheckOutByDateOrderBy = {
  building_id?: Maybe<OrderBy>;
  contact_name?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "check_out_by_date" */
export enum CheckOutByDateSelectColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Tel = 'tel',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}


/** expression to compare columns of type citext. All fields are combined with logical 'AND'. */
export type CitextComparisonExp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "constants" */
export type Constants = {
  __typename?: 'constants';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "constants" */
export type ConstantsAggregate = {
  __typename?: 'constants_aggregate';
  aggregate?: Maybe<ConstantsAggregateFields>;
  nodes: Array<Constants>;
};

/** aggregate fields of "constants" */
export type ConstantsAggregateFields = {
  __typename?: 'constants_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ConstantsMaxFields>;
  min?: Maybe<ConstantsMinFields>;
};


/** aggregate fields of "constants" */
export type ConstantsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ConstantsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "constants". All fields are combined with a logical 'AND'. */
export type ConstantsBoolExp = {
  _and?: Maybe<Array<ConstantsBoolExp>>;
  _not?: Maybe<ConstantsBoolExp>;
  _or?: Maybe<Array<ConstantsBoolExp>>;
  key?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "constants" */
export enum ConstantsConstraint {
  /** unique or primary key constraint */
  ConstantsPkey = 'constants_pkey'
}

/** input type for inserting data into table "constants" */
export type ConstantsInsertInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ConstantsMaxFields = {
  __typename?: 'constants_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ConstantsMinFields = {
  __typename?: 'constants_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "constants" */
export type ConstantsMutationResponse = {
  __typename?: 'constants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Constants>;
};

/** on conflict condition type for table "constants" */
export type ConstantsOnConflict = {
  constraint: ConstantsConstraint;
  update_columns: Array<ConstantsUpdateColumn>;
  where?: Maybe<ConstantsBoolExp>;
};

/** Ordering options when selecting data from "constants". */
export type ConstantsOrderBy = {
  key?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: constants */
export type ConstantsPkColumnsInput = {
  key: Scalars['String'];
};

/** select columns of table "constants" */
export enum ConstantsSelectColumn {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "constants" */
export type ConstantsSetInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "constants" */
export enum ConstantsUpdateColumn {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "course_syllabus" */
export type CourseSyllabus = {
  __typename?: 'course_syllabus';
  id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "course_syllabus" */
export type CourseSyllabusAggregate = {
  __typename?: 'course_syllabus_aggregate';
  aggregate?: Maybe<CourseSyllabusAggregateFields>;
  nodes: Array<Maybe<CourseSyllabus>>;
};

/** aggregate fields of "course_syllabus" */
export type CourseSyllabusAggregateFields = {
  __typename?: 'course_syllabus_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CourseSyllabusMaxFields>;
  min?: Maybe<CourseSyllabusMinFields>;
};


/** aggregate fields of "course_syllabus" */
export type CourseSyllabusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CourseSyllabusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_syllabus". All fields are combined with a logical 'AND'. */
export type CourseSyllabusBoolExp = {
  _and?: Maybe<Array<Maybe<CourseSyllabusBoolExp>>>;
  _not?: Maybe<CourseSyllabusBoolExp>;
  _or?: Maybe<Array<Maybe<CourseSyllabusBoolExp>>>;
  id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "course_syllabus" */
export enum CourseSyllabusConstraint {
  /** unique or primary key constraint */
  CourseSyllabusPkey = 'course_syllabus_pkey'
}

/** input type for inserting data into table "course_syllabus" */
export type CourseSyllabusInsertInput = {
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CourseSyllabusMaxFields = {
  __typename?: 'course_syllabus_max_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CourseSyllabusMinFields = {
  __typename?: 'course_syllabus_min_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "course_syllabus" */
export type CourseSyllabusMutationResponse = {
  __typename?: 'course_syllabus_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows?: Maybe<Scalars['Int']>;
  /** data of the affected rows by the mutation */
  returning: Array<Maybe<CourseSyllabus>>;
};

/** on conflict condition type for table "course_syllabus" */
export type CourseSyllabusOnConflict = {
  constraint: CourseSyllabusConstraint;
  update_columns: Array<CourseSyllabusUpdateColumn>;
  where?: Maybe<CourseSyllabusBoolExp>;
};

/** ordering options when selecting data from "course_syllabus" */
export type CourseSyllabusOrderBy = {
  id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "course_syllabus" */
export type CourseSyllabusPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "course_syllabus" */
export enum CourseSyllabusSelectColumn {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "course_syllabus" */
export type CourseSyllabusSetInput = {
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "course_syllabus" */
export enum CourseSyllabusUpdateColumn {
  /** column name */
  Id = 'id'
}

/** columns and relationships of "daily_questionnaire" */
export type DailyQuestionnaire = {
  __typename?: 'daily_questionnaire';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_3_1?: Maybe<Scalars['Boolean']>;
  question_3_2?: Maybe<Scalars['Boolean']>;
  question_3_3?: Maybe<Scalars['Boolean']>;
  question_3_4?: Maybe<Scalars['Boolean']>;
  question_3_5?: Maybe<Scalars['Boolean']>;
  question_3_6?: Maybe<Scalars['Boolean']>;
  question_3_json?: Maybe<Scalars['jsonb']>;
  question_4?: Maybe<Scalars['String']>;
  risk?: Maybe<Scalars['Boolean']>;
  tel?: Maybe<Scalars['String']>;
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "daily_questionnaire" */
export type DailyQuestionnaireQuestion_3JsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "daily_questionnaire" */
export type DailyQuestionnaireAggregate = {
  __typename?: 'daily_questionnaire_aggregate';
  aggregate?: Maybe<DailyQuestionnaireAggregateFields>;
  nodes: Array<DailyQuestionnaire>;
};

/** aggregate fields of "daily_questionnaire" */
export type DailyQuestionnaireAggregateFields = {
  __typename?: 'daily_questionnaire_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DailyQuestionnaireMaxFields>;
  min?: Maybe<DailyQuestionnaireMinFields>;
};


/** aggregate fields of "daily_questionnaire" */
export type DailyQuestionnaireAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DailyQuestionnaireSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DailyQuestionnaireAppendInput = {
  question_3_json?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "daily_questionnaire". All fields are combined with a logical 'AND'. */
export type DailyQuestionnaireBoolExp = {
  _and?: Maybe<Array<DailyQuestionnaireBoolExp>>;
  _not?: Maybe<DailyQuestionnaireBoolExp>;
  _or?: Maybe<Array<DailyQuestionnaireBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  question_1?: Maybe<StringComparisonExp>;
  question_2?: Maybe<StringComparisonExp>;
  question_3?: Maybe<StringComparisonExp>;
  question_3_1?: Maybe<BooleanComparisonExp>;
  question_3_2?: Maybe<BooleanComparisonExp>;
  question_3_3?: Maybe<BooleanComparisonExp>;
  question_3_4?: Maybe<BooleanComparisonExp>;
  question_3_5?: Maybe<BooleanComparisonExp>;
  question_3_6?: Maybe<BooleanComparisonExp>;
  question_3_json?: Maybe<JsonbComparisonExp>;
  question_4?: Maybe<StringComparisonExp>;
  risk?: Maybe<BooleanComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DailyQuestionnaireDeleteAtPathInput = {
  question_3_json?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DailyQuestionnaireDeleteElemInput = {
  question_3_json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DailyQuestionnaireDeleteKeyInput = {
  question_3_json?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "daily_questionnaire" */
export type DailyQuestionnaireInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_3_1?: Maybe<Scalars['Boolean']>;
  question_3_2?: Maybe<Scalars['Boolean']>;
  question_3_3?: Maybe<Scalars['Boolean']>;
  question_3_4?: Maybe<Scalars['Boolean']>;
  question_3_5?: Maybe<Scalars['Boolean']>;
  question_3_6?: Maybe<Scalars['Boolean']>;
  question_3_json?: Maybe<Scalars['jsonb']>;
  question_4?: Maybe<Scalars['String']>;
  risk?: Maybe<Scalars['Boolean']>;
  tel?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type DailyQuestionnaireMaxFields = {
  __typename?: 'daily_questionnaire_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_4?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type DailyQuestionnaireMinFields = {
  __typename?: 'daily_questionnaire_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_4?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "daily_questionnaire" */
export type DailyQuestionnaireMutationResponse = {
  __typename?: 'daily_questionnaire_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DailyQuestionnaire>;
};

/** Ordering options when selecting data from "daily_questionnaire". */
export type DailyQuestionnaireOrderBy = {
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  question_1?: Maybe<OrderBy>;
  question_2?: Maybe<OrderBy>;
  question_3?: Maybe<OrderBy>;
  question_3_1?: Maybe<OrderBy>;
  question_3_2?: Maybe<OrderBy>;
  question_3_3?: Maybe<OrderBy>;
  question_3_4?: Maybe<OrderBy>;
  question_3_5?: Maybe<OrderBy>;
  question_3_6?: Maybe<OrderBy>;
  question_3_json?: Maybe<OrderBy>;
  question_4?: Maybe<OrderBy>;
  risk?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DailyQuestionnairePrependInput = {
  question_3_json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "daily_questionnaire" */
export enum DailyQuestionnaireSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Question_1 = 'question_1',
  /** column name */
  Question_2 = 'question_2',
  /** column name */
  Question_3 = 'question_3',
  /** column name */
  Question_3_1 = 'question_3_1',
  /** column name */
  Question_3_2 = 'question_3_2',
  /** column name */
  Question_3_3 = 'question_3_3',
  /** column name */
  Question_3_4 = 'question_3_4',
  /** column name */
  Question_3_5 = 'question_3_5',
  /** column name */
  Question_3_6 = 'question_3_6',
  /** column name */
  Question_3Json = 'question_3_json',
  /** column name */
  Question_4 = 'question_4',
  /** column name */
  Risk = 'risk',
  /** column name */
  Tel = 'tel',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "daily_questionnaire" */
export type DailyQuestionnaireSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_3_1?: Maybe<Scalars['Boolean']>;
  question_3_2?: Maybe<Scalars['Boolean']>;
  question_3_3?: Maybe<Scalars['Boolean']>;
  question_3_4?: Maybe<Scalars['Boolean']>;
  question_3_5?: Maybe<Scalars['Boolean']>;
  question_3_6?: Maybe<Scalars['Boolean']>;
  question_3_json?: Maybe<Scalars['jsonb']>;
  question_4?: Maybe<Scalars['String']>;
  risk?: Maybe<Scalars['Boolean']>;
  tel?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "danger_zone" */
export type DangerZone = {
  __typename?: 'danger_zone';
  id: Scalars['uuid'];
  name: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

/** aggregated selection of "danger_zone" */
export type DangerZoneAggregate = {
  __typename?: 'danger_zone_aggregate';
  aggregate?: Maybe<DangerZoneAggregateFields>;
  nodes: Array<DangerZone>;
};

/** aggregate fields of "danger_zone" */
export type DangerZoneAggregateFields = {
  __typename?: 'danger_zone_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DangerZoneMaxFields>;
  min?: Maybe<DangerZoneMinFields>;
};


/** aggregate fields of "danger_zone" */
export type DangerZoneAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DangerZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "danger_zone". All fields are combined with a logical 'AND'. */
export type DangerZoneBoolExp = {
  _and?: Maybe<Array<DangerZoneBoolExp>>;
  _not?: Maybe<DangerZoneBoolExp>;
  _or?: Maybe<Array<DangerZoneBoolExp>>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "danger_zone" */
export enum DangerZoneConstraint {
  /** unique or primary key constraint */
  DangerZonePkey = 'danger_zone_pkey'
}

/** input type for inserting data into table "danger_zone" */
export type DangerZoneInsertInput = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DangerZoneMaxFields = {
  __typename?: 'danger_zone_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DangerZoneMinFields = {
  __typename?: 'danger_zone_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "danger_zone" */
export type DangerZoneMutationResponse = {
  __typename?: 'danger_zone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DangerZone>;
};

/** on conflict condition type for table "danger_zone" */
export type DangerZoneOnConflict = {
  constraint: DangerZoneConstraint;
  update_columns: Array<DangerZoneUpdateColumn>;
  where?: Maybe<DangerZoneBoolExp>;
};

/** Ordering options when selecting data from "danger_zone". */
export type DangerZoneOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
};

/** primary key columns input for table: danger_zone */
export type DangerZonePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "danger_zone" */
export enum DangerZoneSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Reference = 'reference'
}

/** input type for updating data in table "danger_zone" */
export type DangerZoneSetInput = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

/** update columns of table "danger_zone" */
export enum DangerZoneUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Reference = 'reference'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "foreign_user" */
export type ForeignUser = {
  __typename?: 'foreign_user';
  avatar_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  display_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "foreign_user" */
export type ForeignUserAggregate = {
  __typename?: 'foreign_user_aggregate';
  aggregate?: Maybe<ForeignUserAggregateFields>;
  nodes: Array<ForeignUser>;
};

/** aggregate fields of "foreign_user" */
export type ForeignUserAggregateFields = {
  __typename?: 'foreign_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ForeignUserMaxFields>;
  min?: Maybe<ForeignUserMinFields>;
};


/** aggregate fields of "foreign_user" */
export type ForeignUserAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ForeignUserSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "foreign_user". All fields are combined with a logical 'AND'. */
export type ForeignUserBoolExp = {
  _and?: Maybe<Array<ForeignUserBoolExp>>;
  _not?: Maybe<ForeignUserBoolExp>;
  _or?: Maybe<Array<ForeignUserBoolExp>>;
  avatar_url?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** input type for inserting data into table "foreign_user" */
export type ForeignUserInsertInput = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ForeignUserMaxFields = {
  __typename?: 'foreign_user_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ForeignUserMinFields = {
  __typename?: 'foreign_user_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "foreign_user" */
export type ForeignUserMutationResponse = {
  __typename?: 'foreign_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ForeignUser>;
};

/** input type for inserting object relation for remote table "foreign_user" */
export type ForeignUserObjRelInsertInput = {
  data: ForeignUserInsertInput;
};

/** Ordering options when selecting data from "foreign_user". */
export type ForeignUserOrderBy = {
  avatar_url?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** select columns of table "foreign_user" */
export enum ForeignUserSelectColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "foreign_user" */
export type ForeignUserSetInput = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete data from the table: "atk_brand" */
  delete_atk_brand?: Maybe<AtkBrandMutationResponse>;
  /** delete single row from the table: "atk_brand" */
  delete_atk_brand_by_pk?: Maybe<AtkBrand>;
  /** delete data from the table: "atk_result" */
  delete_atk_result?: Maybe<AtkResultMutationResponse>;
  /** delete single row from the table: "atk_result" */
  delete_atk_result_by_pk?: Maybe<AtkResult>;
  /** delete data from the table: "auth.account_providers" */
  delete_auth_account_providers?: Maybe<AuthAccountProvidersMutationResponse>;
  /** delete single row from the table: "auth.account_providers" */
  delete_auth_account_providers_by_pk?: Maybe<AuthAccountProviders>;
  /** delete data from the table: "auth.account_roles" */
  delete_auth_account_roles?: Maybe<AuthAccountRolesMutationResponse>;
  /** delete single row from the table: "auth.account_roles" */
  delete_auth_account_roles_by_pk?: Maybe<AuthAccountRoles>;
  /** delete data from the table: "auth.accounts" */
  delete_auth_accounts?: Maybe<AuthAccountsMutationResponse>;
  /** delete single row from the table: "auth.accounts" */
  delete_auth_accounts_by_pk?: Maybe<AuthAccounts>;
  /** delete data from the table: "auth.providers" */
  delete_auth_providers?: Maybe<AuthProvidersMutationResponse>;
  /** delete single row from the table: "auth.providers" */
  delete_auth_providers_by_pk?: Maybe<AuthProviders>;
  /** delete data from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** delete single row from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens_by_pk?: Maybe<AuthRefreshTokens>;
  /** delete data from the table: "auth.roles" */
  delete_auth_roles?: Maybe<AuthRolesMutationResponse>;
  /** delete single row from the table: "auth.roles" */
  delete_auth_roles_by_pk?: Maybe<AuthRoles>;
  /** delete data from the table: "building" */
  delete_building?: Maybe<BuildingMutationResponse>;
  /** delete single row from the table: "building" */
  delete_building_by_pk?: Maybe<Building>;
  /** delete data from the table: "constants" */
  delete_constants?: Maybe<ConstantsMutationResponse>;
  /** delete single row from the table: "constants" */
  delete_constants_by_pk?: Maybe<Constants>;
  /** delete data from the table: "course_syllabus" */
  delete_course_syllabus?: Maybe<CourseSyllabusMutationResponse>;
  /** delete single row from the table: "course_syllabus" */
  delete_course_syllabus_by_pk?: Maybe<CourseSyllabus>;
  /** delete data from the table: "daily_questionnaire" */
  delete_daily_questionnaire?: Maybe<DailyQuestionnaireMutationResponse>;
  /** delete data from the table: "danger_zone" */
  delete_danger_zone?: Maybe<DangerZoneMutationResponse>;
  /** delete single row from the table: "danger_zone" */
  delete_danger_zone_by_pk?: Maybe<DangerZone>;
  /** delete data from the table: "foreign_user" */
  delete_foreign_user?: Maybe<ForeignUserMutationResponse>;
  /** delete data from the table: "permissions" */
  delete_permissions?: Maybe<PermissionsMutationResponse>;
  /** delete single row from the table: "permissions" */
  delete_permissions_by_pk?: Maybe<Permissions>;
  /** delete data from the table: "questionnaire_answer" */
  delete_questionnaire_answer?: Maybe<QuestionnaireAnswerMutationResponse>;
  /** delete single row from the table: "questionnaire_answer" */
  delete_questionnaire_answer_by_pk?: Maybe<QuestionnaireAnswer>;
  /** delete data from the table: "trace" */
  delete_trace?: Maybe<TraceMutationResponse>;
  /** delete single row from the table: "trace" */
  delete_trace_by_pk?: Maybe<Trace>;
  /** delete data from the table: "trace_type" */
  delete_trace_type?: Maybe<TraceTypeMutationResponse>;
  /** delete single row from the table: "trace_type" */
  delete_trace_type_by_pk?: Maybe<TraceType>;
  /** delete data from the table: "user_email" */
  delete_user_email?: Maybe<UserEmailMutationResponse>;
  /** delete single row from the table: "user_email" */
  delete_user_email_by_pk?: Maybe<UserEmail>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vaccined_detail" */
  delete_vaccined_detail?: Maybe<VaccinedDetailMutationResponse>;
  /** delete single row from the table: "vaccined_detail" */
  delete_vaccined_detail_by_pk?: Maybe<VaccinedDetail>;
  /** delete data from the table: "vaccined_infomation" */
  delete_vaccined_infomation?: Maybe<VaccinedInfomationMutationResponse>;
  /** delete single row from the table: "vaccined_infomation" */
  delete_vaccined_infomation_by_pk?: Maybe<VaccinedInfomation>;
  /** insert data into the table: "atk_brand" */
  insert_atk_brand?: Maybe<AtkBrandMutationResponse>;
  /** insert a single row into the table: "atk_brand" */
  insert_atk_brand_one?: Maybe<AtkBrand>;
  /** insert data into the table: "atk_result" */
  insert_atk_result?: Maybe<AtkResultMutationResponse>;
  /** insert a single row into the table: "atk_result" */
  insert_atk_result_one?: Maybe<AtkResult>;
  /** insert data into the table: "auth.account_providers" */
  insert_auth_account_providers?: Maybe<AuthAccountProvidersMutationResponse>;
  /** insert a single row into the table: "auth.account_providers" */
  insert_auth_account_providers_one?: Maybe<AuthAccountProviders>;
  /** insert data into the table: "auth.account_roles" */
  insert_auth_account_roles?: Maybe<AuthAccountRolesMutationResponse>;
  /** insert a single row into the table: "auth.account_roles" */
  insert_auth_account_roles_one?: Maybe<AuthAccountRoles>;
  /** insert data into the table: "auth.accounts" */
  insert_auth_accounts?: Maybe<AuthAccountsMutationResponse>;
  /** insert a single row into the table: "auth.accounts" */
  insert_auth_accounts_one?: Maybe<AuthAccounts>;
  /** insert data into the table: "auth.providers" */
  insert_auth_providers?: Maybe<AuthProvidersMutationResponse>;
  /** insert a single row into the table: "auth.providers" */
  insert_auth_providers_one?: Maybe<AuthProviders>;
  /** insert data into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens_one?: Maybe<AuthRefreshTokens>;
  /** insert data into the table: "auth.roles" */
  insert_auth_roles?: Maybe<AuthRolesMutationResponse>;
  /** insert a single row into the table: "auth.roles" */
  insert_auth_roles_one?: Maybe<AuthRoles>;
  /** insert data into the table: "building" */
  insert_building?: Maybe<BuildingMutationResponse>;
  /** insert a single row into the table: "building" */
  insert_building_one?: Maybe<Building>;
  /** insert data into the table: "constants" */
  insert_constants?: Maybe<ConstantsMutationResponse>;
  /** insert a single row into the table: "constants" */
  insert_constants_one?: Maybe<Constants>;
  /** insert data into the table: "course_syllabus" */
  insert_course_syllabus?: Maybe<CourseSyllabusMutationResponse>;
  /** insert a single row into the table: "course_syllabus" */
  insert_course_syllabus_one?: Maybe<CourseSyllabus>;
  /** insert data into the table: "daily_questionnaire" */
  insert_daily_questionnaire?: Maybe<DailyQuestionnaireMutationResponse>;
  /** insert a single row into the table: "daily_questionnaire" */
  insert_daily_questionnaire_one?: Maybe<DailyQuestionnaire>;
  /** insert data into the table: "danger_zone" */
  insert_danger_zone?: Maybe<DangerZoneMutationResponse>;
  /** insert a single row into the table: "danger_zone" */
  insert_danger_zone_one?: Maybe<DangerZone>;
  /** insert data into the table: "foreign_user" */
  insert_foreign_user?: Maybe<ForeignUserMutationResponse>;
  /** insert a single row into the table: "foreign_user" */
  insert_foreign_user_one?: Maybe<ForeignUser>;
  /** insert data into the table: "permissions" */
  insert_permissions?: Maybe<PermissionsMutationResponse>;
  /** insert a single row into the table: "permissions" */
  insert_permissions_one?: Maybe<Permissions>;
  /** insert data into the table: "questionnaire_answer" */
  insert_questionnaire_answer?: Maybe<QuestionnaireAnswerMutationResponse>;
  /** insert a single row into the table: "questionnaire_answer" */
  insert_questionnaire_answer_one?: Maybe<QuestionnaireAnswer>;
  /** insert data into the table: "trace" */
  insert_trace?: Maybe<TraceMutationResponse>;
  /** insert a single row into the table: "trace" */
  insert_trace_one?: Maybe<Trace>;
  /** insert data into the table: "trace_type" */
  insert_trace_type?: Maybe<TraceTypeMutationResponse>;
  /** insert a single row into the table: "trace_type" */
  insert_trace_type_one?: Maybe<TraceType>;
  /** insert data into the table: "user_email" */
  insert_user_email?: Maybe<UserEmailMutationResponse>;
  /** insert a single row into the table: "user_email" */
  insert_user_email_one?: Maybe<UserEmail>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vaccined_detail" */
  insert_vaccined_detail?: Maybe<VaccinedDetailMutationResponse>;
  /** insert a single row into the table: "vaccined_detail" */
  insert_vaccined_detail_one?: Maybe<VaccinedDetail>;
  /** insert data into the table: "vaccined_infomation" */
  insert_vaccined_infomation?: Maybe<VaccinedInfomationMutationResponse>;
  /** insert a single row into the table: "vaccined_infomation" */
  insert_vaccined_infomation_one?: Maybe<VaccinedInfomation>;
  /** update data of the table: "atk_brand" */
  update_atk_brand?: Maybe<AtkBrandMutationResponse>;
  /** update single row of the table: "atk_brand" */
  update_atk_brand_by_pk?: Maybe<AtkBrand>;
  /** update data of the table: "atk_result" */
  update_atk_result?: Maybe<AtkResultMutationResponse>;
  /** update single row of the table: "atk_result" */
  update_atk_result_by_pk?: Maybe<AtkResult>;
  /** update data of the table: "auth.account_providers" */
  update_auth_account_providers?: Maybe<AuthAccountProvidersMutationResponse>;
  /** update single row of the table: "auth.account_providers" */
  update_auth_account_providers_by_pk?: Maybe<AuthAccountProviders>;
  /** update data of the table: "auth.account_roles" */
  update_auth_account_roles?: Maybe<AuthAccountRolesMutationResponse>;
  /** update single row of the table: "auth.account_roles" */
  update_auth_account_roles_by_pk?: Maybe<AuthAccountRoles>;
  /** update data of the table: "auth.accounts" */
  update_auth_accounts?: Maybe<AuthAccountsMutationResponse>;
  /** update single row of the table: "auth.accounts" */
  update_auth_accounts_by_pk?: Maybe<AuthAccounts>;
  /** update data of the table: "auth.providers" */
  update_auth_providers?: Maybe<AuthProvidersMutationResponse>;
  /** update single row of the table: "auth.providers" */
  update_auth_providers_by_pk?: Maybe<AuthProviders>;
  /** update data of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** update single row of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_by_pk?: Maybe<AuthRefreshTokens>;
  /** update data of the table: "auth.roles" */
  update_auth_roles?: Maybe<AuthRolesMutationResponse>;
  /** update single row of the table: "auth.roles" */
  update_auth_roles_by_pk?: Maybe<AuthRoles>;
  /** update data of the table: "building" */
  update_building?: Maybe<BuildingMutationResponse>;
  /** update single row of the table: "building" */
  update_building_by_pk?: Maybe<Building>;
  /** update data of the table: "constants" */
  update_constants?: Maybe<ConstantsMutationResponse>;
  /** update single row of the table: "constants" */
  update_constants_by_pk?: Maybe<Constants>;
  /** update data of the table: "course_syllabus" */
  update_course_syllabus?: Maybe<CourseSyllabusMutationResponse>;
  /** update single row of the table: "course_syllabus" */
  update_course_syllabus_by_pk?: Maybe<CourseSyllabus>;
  /** update data of the table: "daily_questionnaire" */
  update_daily_questionnaire?: Maybe<DailyQuestionnaireMutationResponse>;
  /** update data of the table: "danger_zone" */
  update_danger_zone?: Maybe<DangerZoneMutationResponse>;
  /** update single row of the table: "danger_zone" */
  update_danger_zone_by_pk?: Maybe<DangerZone>;
  /** update data of the table: "foreign_user" */
  update_foreign_user?: Maybe<ForeignUserMutationResponse>;
  /** update data of the table: "permissions" */
  update_permissions?: Maybe<PermissionsMutationResponse>;
  /** update single row of the table: "permissions" */
  update_permissions_by_pk?: Maybe<Permissions>;
  /** update data of the table: "questionnaire_answer" */
  update_questionnaire_answer?: Maybe<QuestionnaireAnswerMutationResponse>;
  /** update single row of the table: "questionnaire_answer" */
  update_questionnaire_answer_by_pk?: Maybe<QuestionnaireAnswer>;
  /** update data of the table: "trace" */
  update_trace?: Maybe<TraceMutationResponse>;
  /** update single row of the table: "trace" */
  update_trace_by_pk?: Maybe<Trace>;
  /** update data of the table: "trace_type" */
  update_trace_type?: Maybe<TraceTypeMutationResponse>;
  /** update single row of the table: "trace_type" */
  update_trace_type_by_pk?: Maybe<TraceType>;
  /** update data of the table: "user_email" */
  update_user_email?: Maybe<UserEmailMutationResponse>;
  /** update single row of the table: "user_email" */
  update_user_email_by_pk?: Maybe<UserEmail>;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "vaccined_detail" */
  update_vaccined_detail?: Maybe<VaccinedDetailMutationResponse>;
  /** update single row of the table: "vaccined_detail" */
  update_vaccined_detail_by_pk?: Maybe<VaccinedDetail>;
  /** update data of the table: "vaccined_infomation" */
  update_vaccined_infomation?: Maybe<VaccinedInfomationMutationResponse>;
  /** update single row of the table: "vaccined_infomation" */
  update_vaccined_infomation_by_pk?: Maybe<VaccinedInfomation>;
};


/** mutation root */
export type MutationRootDeleteAtkBrandArgs = {
  where: AtkBrandBoolExp;
};


/** mutation root */
export type MutationRootDeleteAtkBrandByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAtkResultArgs = {
  where: AtkResultBoolExp;
};


/** mutation root */
export type MutationRootDeleteAtkResultByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthAccountProvidersArgs = {
  where: AuthAccountProvidersBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthAccountProvidersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthAccountRolesArgs = {
  where: AuthAccountRolesBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthAccountRolesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthAccountsArgs = {
  where: AuthAccountsBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthAccountsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthProvidersArgs = {
  where: AuthProvidersBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthProvidersByPkArgs = {
  provider: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAuthRefreshTokensArgs = {
  where: AuthRefreshTokensBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthRefreshTokensByPkArgs = {
  refresh_token: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthRolesArgs = {
  where: AuthRolesBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthRolesByPkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteBuildingArgs = {
  where: BuildingBoolExp;
};


/** mutation root */
export type MutationRootDeleteBuildingByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteConstantsArgs = {
  where: ConstantsBoolExp;
};


/** mutation root */
export type MutationRootDeleteConstantsByPkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteCourseSyllabusArgs = {
  where: CourseSyllabusBoolExp;
};


/** mutation root */
export type MutationRootDeleteCourseSyllabusByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteDailyQuestionnaireArgs = {
  where: DailyQuestionnaireBoolExp;
};


/** mutation root */
export type MutationRootDeleteDangerZoneArgs = {
  where: DangerZoneBoolExp;
};


/** mutation root */
export type MutationRootDeleteDangerZoneByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteForeignUserArgs = {
  where: ForeignUserBoolExp;
};


/** mutation root */
export type MutationRootDeletePermissionsArgs = {
  where: PermissionsBoolExp;
};


/** mutation root */
export type MutationRootDeletePermissionsByPkArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteQuestionnaireAnswerArgs = {
  where: QuestionnaireAnswerBoolExp;
};


/** mutation root */
export type MutationRootDeleteQuestionnaireAnswerByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteTraceArgs = {
  where: TraceBoolExp;
};


/** mutation root */
export type MutationRootDeleteTraceByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteTraceTypeArgs = {
  where: TraceTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteTraceTypeByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteUserEmailArgs = {
  where: UserEmailBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserEmailByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUsersArgs = {
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteVaccinedDetailArgs = {
  where: VaccinedDetailBoolExp;
};


/** mutation root */
export type MutationRootDeleteVaccinedDetailByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteVaccinedInfomationArgs = {
  where: VaccinedInfomationBoolExp;
};


/** mutation root */
export type MutationRootDeleteVaccinedInfomationByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootInsertAtkBrandArgs = {
  objects: Array<AtkBrandInsertInput>;
  on_conflict?: Maybe<AtkBrandOnConflict>;
};


/** mutation root */
export type MutationRootInsertAtkBrandOneArgs = {
  object: AtkBrandInsertInput;
  on_conflict?: Maybe<AtkBrandOnConflict>;
};


/** mutation root */
export type MutationRootInsertAtkResultArgs = {
  objects: Array<AtkResultInsertInput>;
  on_conflict?: Maybe<AtkResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertAtkResultOneArgs = {
  object: AtkResultInsertInput;
  on_conflict?: Maybe<AtkResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountProvidersArgs = {
  objects: Array<AuthAccountProvidersInsertInput>;
  on_conflict?: Maybe<AuthAccountProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountProvidersOneArgs = {
  object: AuthAccountProvidersInsertInput;
  on_conflict?: Maybe<AuthAccountProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountRolesArgs = {
  objects: Array<AuthAccountRolesInsertInput>;
  on_conflict?: Maybe<AuthAccountRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountRolesOneArgs = {
  object: AuthAccountRolesInsertInput;
  on_conflict?: Maybe<AuthAccountRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountsArgs = {
  objects: Array<AuthAccountsInsertInput>;
  on_conflict?: Maybe<AuthAccountsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountsOneArgs = {
  object: AuthAccountsInsertInput;
  on_conflict?: Maybe<AuthAccountsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthProvidersArgs = {
  objects: Array<AuthProvidersInsertInput>;
  on_conflict?: Maybe<AuthProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthProvidersOneArgs = {
  object: AuthProvidersInsertInput;
  on_conflict?: Maybe<AuthProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRefreshTokensArgs = {
  objects: Array<AuthRefreshTokensInsertInput>;
  on_conflict?: Maybe<AuthRefreshTokensOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRefreshTokensOneArgs = {
  object: AuthRefreshTokensInsertInput;
  on_conflict?: Maybe<AuthRefreshTokensOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRolesArgs = {
  objects: Array<AuthRolesInsertInput>;
  on_conflict?: Maybe<AuthRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRolesOneArgs = {
  object: AuthRolesInsertInput;
  on_conflict?: Maybe<AuthRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertBuildingArgs = {
  objects: Array<BuildingInsertInput>;
  on_conflict?: Maybe<BuildingOnConflict>;
};


/** mutation root */
export type MutationRootInsertBuildingOneArgs = {
  object: BuildingInsertInput;
  on_conflict?: Maybe<BuildingOnConflict>;
};


/** mutation root */
export type MutationRootInsertConstantsArgs = {
  objects: Array<ConstantsInsertInput>;
  on_conflict?: Maybe<ConstantsOnConflict>;
};


/** mutation root */
export type MutationRootInsertConstantsOneArgs = {
  object: ConstantsInsertInput;
  on_conflict?: Maybe<ConstantsOnConflict>;
};


/** mutation root */
export type MutationRootInsertCourseSyllabusArgs = {
  objects: Array<CourseSyllabusInsertInput>;
  on_conflict?: Maybe<CourseSyllabusOnConflict>;
};


/** mutation root */
export type MutationRootInsertCourseSyllabusOneArgs = {
  object: CourseSyllabusInsertInput;
  on_conflict?: Maybe<CourseSyllabusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDailyQuestionnaireArgs = {
  objects: Array<DailyQuestionnaireInsertInput>;
};


/** mutation root */
export type MutationRootInsertDailyQuestionnaireOneArgs = {
  object: DailyQuestionnaireInsertInput;
};


/** mutation root */
export type MutationRootInsertDangerZoneArgs = {
  objects: Array<DangerZoneInsertInput>;
  on_conflict?: Maybe<DangerZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertDangerZoneOneArgs = {
  object: DangerZoneInsertInput;
  on_conflict?: Maybe<DangerZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertForeignUserArgs = {
  objects: Array<ForeignUserInsertInput>;
};


/** mutation root */
export type MutationRootInsertForeignUserOneArgs = {
  object: ForeignUserInsertInput;
};


/** mutation root */
export type MutationRootInsertPermissionsArgs = {
  objects: Array<PermissionsInsertInput>;
  on_conflict?: Maybe<PermissionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertPermissionsOneArgs = {
  object: PermissionsInsertInput;
  on_conflict?: Maybe<PermissionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireAnswerArgs = {
  objects: Array<QuestionnaireAnswerInsertInput>;
  on_conflict?: Maybe<QuestionnaireAnswerOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireAnswerOneArgs = {
  object: QuestionnaireAnswerInsertInput;
  on_conflict?: Maybe<QuestionnaireAnswerOnConflict>;
};


/** mutation root */
export type MutationRootInsertTraceArgs = {
  objects: Array<TraceInsertInput>;
  on_conflict?: Maybe<TraceOnConflict>;
};


/** mutation root */
export type MutationRootInsertTraceOneArgs = {
  object: TraceInsertInput;
  on_conflict?: Maybe<TraceOnConflict>;
};


/** mutation root */
export type MutationRootInsertTraceTypeArgs = {
  objects: Array<TraceTypeInsertInput>;
  on_conflict?: Maybe<TraceTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertTraceTypeOneArgs = {
  object: TraceTypeInsertInput;
  on_conflict?: Maybe<TraceTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserEmailArgs = {
  objects: Array<UserEmailInsertInput>;
  on_conflict?: Maybe<UserEmailOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserEmailOneArgs = {
  object: UserEmailInsertInput;
  on_conflict?: Maybe<UserEmailOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: Maybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersOneArgs = {
  object: UsersInsertInput;
  on_conflict?: Maybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertVaccinedDetailArgs = {
  objects: Array<VaccinedDetailInsertInput>;
  on_conflict?: Maybe<VaccinedDetailOnConflict>;
};


/** mutation root */
export type MutationRootInsertVaccinedDetailOneArgs = {
  object: VaccinedDetailInsertInput;
  on_conflict?: Maybe<VaccinedDetailOnConflict>;
};


/** mutation root */
export type MutationRootInsertVaccinedInfomationArgs = {
  objects: Array<VaccinedInfomationInsertInput>;
  on_conflict?: Maybe<VaccinedInfomationOnConflict>;
};


/** mutation root */
export type MutationRootInsertVaccinedInfomationOneArgs = {
  object: VaccinedInfomationInsertInput;
  on_conflict?: Maybe<VaccinedInfomationOnConflict>;
};


/** mutation root */
export type MutationRootUpdateAtkBrandArgs = {
  _set?: Maybe<AtkBrandSetInput>;
  where: AtkBrandBoolExp;
};


/** mutation root */
export type MutationRootUpdateAtkBrandByPkArgs = {
  _set?: Maybe<AtkBrandSetInput>;
  pk_columns: AtkBrandPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAtkResultArgs = {
  _set?: Maybe<AtkResultSetInput>;
  where: AtkResultBoolExp;
};


/** mutation root */
export type MutationRootUpdateAtkResultByPkArgs = {
  _set?: Maybe<AtkResultSetInput>;
  pk_columns: AtkResultPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthAccountProvidersArgs = {
  _set?: Maybe<AuthAccountProvidersSetInput>;
  where: AuthAccountProvidersBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthAccountProvidersByPkArgs = {
  _set?: Maybe<AuthAccountProvidersSetInput>;
  pk_columns: AuthAccountProvidersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthAccountRolesArgs = {
  _set?: Maybe<AuthAccountRolesSetInput>;
  where: AuthAccountRolesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthAccountRolesByPkArgs = {
  _set?: Maybe<AuthAccountRolesSetInput>;
  pk_columns: AuthAccountRolesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthAccountsArgs = {
  _append?: Maybe<AuthAccountsAppendInput>;
  _delete_at_path?: Maybe<AuthAccountsDeleteAtPathInput>;
  _delete_elem?: Maybe<AuthAccountsDeleteElemInput>;
  _delete_key?: Maybe<AuthAccountsDeleteKeyInput>;
  _prepend?: Maybe<AuthAccountsPrependInput>;
  _set?: Maybe<AuthAccountsSetInput>;
  where: AuthAccountsBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthAccountsByPkArgs = {
  _append?: Maybe<AuthAccountsAppendInput>;
  _delete_at_path?: Maybe<AuthAccountsDeleteAtPathInput>;
  _delete_elem?: Maybe<AuthAccountsDeleteElemInput>;
  _delete_key?: Maybe<AuthAccountsDeleteKeyInput>;
  _prepend?: Maybe<AuthAccountsPrependInput>;
  _set?: Maybe<AuthAccountsSetInput>;
  pk_columns: AuthAccountsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthProvidersArgs = {
  _set?: Maybe<AuthProvidersSetInput>;
  where: AuthProvidersBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthProvidersByPkArgs = {
  _set?: Maybe<AuthProvidersSetInput>;
  pk_columns: AuthProvidersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokensArgs = {
  _set?: Maybe<AuthRefreshTokensSetInput>;
  where: AuthRefreshTokensBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokensByPkArgs = {
  _set?: Maybe<AuthRefreshTokensSetInput>;
  pk_columns: AuthRefreshTokensPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthRolesArgs = {
  _set?: Maybe<AuthRolesSetInput>;
  where: AuthRolesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthRolesByPkArgs = {
  _set?: Maybe<AuthRolesSetInput>;
  pk_columns: AuthRolesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateBuildingArgs = {
  _inc?: Maybe<BuildingIncInput>;
  _set?: Maybe<BuildingSetInput>;
  where: BuildingBoolExp;
};


/** mutation root */
export type MutationRootUpdateBuildingByPkArgs = {
  _inc?: Maybe<BuildingIncInput>;
  _set?: Maybe<BuildingSetInput>;
  pk_columns: BuildingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConstantsArgs = {
  _set?: Maybe<ConstantsSetInput>;
  where: ConstantsBoolExp;
};


/** mutation root */
export type MutationRootUpdateConstantsByPkArgs = {
  _set?: Maybe<ConstantsSetInput>;
  pk_columns: ConstantsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCourseSyllabusArgs = {
  _set?: Maybe<CourseSyllabusSetInput>;
  where: CourseSyllabusBoolExp;
};


/** mutation root */
export type MutationRootUpdateCourseSyllabusByPkArgs = {
  _set?: Maybe<CourseSyllabusSetInput>;
  pk_columns: CourseSyllabusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDailyQuestionnaireArgs = {
  _append?: Maybe<DailyQuestionnaireAppendInput>;
  _delete_at_path?: Maybe<DailyQuestionnaireDeleteAtPathInput>;
  _delete_elem?: Maybe<DailyQuestionnaireDeleteElemInput>;
  _delete_key?: Maybe<DailyQuestionnaireDeleteKeyInput>;
  _prepend?: Maybe<DailyQuestionnairePrependInput>;
  _set?: Maybe<DailyQuestionnaireSetInput>;
  where: DailyQuestionnaireBoolExp;
};


/** mutation root */
export type MutationRootUpdateDangerZoneArgs = {
  _set?: Maybe<DangerZoneSetInput>;
  where: DangerZoneBoolExp;
};


/** mutation root */
export type MutationRootUpdateDangerZoneByPkArgs = {
  _set?: Maybe<DangerZoneSetInput>;
  pk_columns: DangerZonePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateForeignUserArgs = {
  _set?: Maybe<ForeignUserSetInput>;
  where: ForeignUserBoolExp;
};


/** mutation root */
export type MutationRootUpdatePermissionsArgs = {
  _set?: Maybe<PermissionsSetInput>;
  where: PermissionsBoolExp;
};


/** mutation root */
export type MutationRootUpdatePermissionsByPkArgs = {
  _set?: Maybe<PermissionsSetInput>;
  pk_columns: PermissionsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireAnswerArgs = {
  _append?: Maybe<QuestionnaireAnswerAppendInput>;
  _delete_at_path?: Maybe<QuestionnaireAnswerDeleteAtPathInput>;
  _delete_elem?: Maybe<QuestionnaireAnswerDeleteElemInput>;
  _delete_key?: Maybe<QuestionnaireAnswerDeleteKeyInput>;
  _prepend?: Maybe<QuestionnaireAnswerPrependInput>;
  _set?: Maybe<QuestionnaireAnswerSetInput>;
  where: QuestionnaireAnswerBoolExp;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireAnswerByPkArgs = {
  _append?: Maybe<QuestionnaireAnswerAppendInput>;
  _delete_at_path?: Maybe<QuestionnaireAnswerDeleteAtPathInput>;
  _delete_elem?: Maybe<QuestionnaireAnswerDeleteElemInput>;
  _delete_key?: Maybe<QuestionnaireAnswerDeleteKeyInput>;
  _prepend?: Maybe<QuestionnaireAnswerPrependInput>;
  _set?: Maybe<QuestionnaireAnswerSetInput>;
  pk_columns: QuestionnaireAnswerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTraceArgs = {
  _set?: Maybe<TraceSetInput>;
  where: TraceBoolExp;
};


/** mutation root */
export type MutationRootUpdateTraceByPkArgs = {
  _set?: Maybe<TraceSetInput>;
  pk_columns: TracePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTraceTypeArgs = {
  _set?: Maybe<TraceTypeSetInput>;
  where: TraceTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateTraceTypeByPkArgs = {
  _set?: Maybe<TraceTypeSetInput>;
  pk_columns: TraceTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserEmailArgs = {
  _set?: Maybe<UserEmailSetInput>;
  where: UserEmailBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserEmailByPkArgs = {
  _set?: Maybe<UserEmailSetInput>;
  pk_columns: UserEmailPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _append?: Maybe<UsersAppendInput>;
  _delete_at_path?: Maybe<UsersDeleteAtPathInput>;
  _delete_elem?: Maybe<UsersDeleteElemInput>;
  _delete_key?: Maybe<UsersDeleteKeyInput>;
  _prepend?: Maybe<UsersPrependInput>;
  _set?: Maybe<UsersSetInput>;
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateUsersByPkArgs = {
  _append?: Maybe<UsersAppendInput>;
  _delete_at_path?: Maybe<UsersDeleteAtPathInput>;
  _delete_elem?: Maybe<UsersDeleteElemInput>;
  _delete_key?: Maybe<UsersDeleteKeyInput>;
  _prepend?: Maybe<UsersPrependInput>;
  _set?: Maybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateVaccinedDetailArgs = {
  _set?: Maybe<VaccinedDetailSetInput>;
  where: VaccinedDetailBoolExp;
};


/** mutation root */
export type MutationRootUpdateVaccinedDetailByPkArgs = {
  _set?: Maybe<VaccinedDetailSetInput>;
  pk_columns: VaccinedDetailPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateVaccinedInfomationArgs = {
  _set?: Maybe<VaccinedInfomationSetInput>;
  where: VaccinedInfomationBoolExp;
};


/** mutation root */
export type MutationRootUpdateVaccinedInfomationByPkArgs = {
  _set?: Maybe<VaccinedInfomationSetInput>;
  pk_columns: VaccinedInfomationPkColumnsInput;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "permissions" */
export type Permissions = {
  __typename?: 'permissions';
  account_id?: Maybe<Scalars['uuid']>;
  building_code?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** aggregated selection of "permissions" */
export type PermissionsAggregate = {
  __typename?: 'permissions_aggregate';
  aggregate?: Maybe<PermissionsAggregateFields>;
  nodes: Array<Permissions>;
};

/** aggregate fields of "permissions" */
export type PermissionsAggregateFields = {
  __typename?: 'permissions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PermissionsMaxFields>;
  min?: Maybe<PermissionsMinFields>;
};


/** aggregate fields of "permissions" */
export type PermissionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PermissionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "permissions". All fields are combined with a logical 'AND'. */
export type PermissionsBoolExp = {
  _and?: Maybe<Array<PermissionsBoolExp>>;
  _not?: Maybe<PermissionsBoolExp>;
  _or?: Maybe<Array<PermissionsBoolExp>>;
  account_id?: Maybe<UuidComparisonExp>;
  building_code?: Maybe<StringComparisonExp>;
  email?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "permissions" */
export enum PermissionsConstraint {
  /** unique or primary key constraint */
  PermissionsEmailBuildingCodeKey = 'permissions_email_building_code_key',
  /** unique or primary key constraint */
  PermissionsPkey = 'permissions_pkey'
}

/** input type for inserting data into table "permissions" */
export type PermissionsInsertInput = {
  account_id?: Maybe<Scalars['uuid']>;
  building_code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PermissionsMaxFields = {
  __typename?: 'permissions_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  building_code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PermissionsMinFields = {
  __typename?: 'permissions_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  building_code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "permissions" */
export type PermissionsMutationResponse = {
  __typename?: 'permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Permissions>;
};

/** on conflict condition type for table "permissions" */
export type PermissionsOnConflict = {
  constraint: PermissionsConstraint;
  update_columns: Array<PermissionsUpdateColumn>;
  where?: Maybe<PermissionsBoolExp>;
};

/** Ordering options when selecting data from "permissions". */
export type PermissionsOrderBy = {
  account_id?: Maybe<OrderBy>;
  building_code?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
};

/** primary key columns input for table: permissions */
export type PermissionsPkColumnsInput = {
  email: Scalars['String'];
};

/** select columns of table "permissions" */
export enum PermissionsSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BuildingCode = 'building_code',
  /** column name */
  Email = 'email'
}

/** input type for updating data in table "permissions" */
export type PermissionsSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  building_code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** update columns of table "permissions" */
export enum PermissionsUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BuildingCode = 'building_code',
  /** column name */
  Email = 'email'
}

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "atk_brand" */
  atk_brand: Array<AtkBrand>;
  /** fetch aggregated fields from the table: "atk_brand" */
  atk_brand_aggregate: AtkBrandAggregate;
  /** fetch data from the table: "atk_brand" using primary key columns */
  atk_brand_by_pk?: Maybe<AtkBrand>;
  /** fetch data from the table: "atk_result" */
  atk_result: Array<AtkResult>;
  /** fetch aggregated fields from the table: "atk_result" */
  atk_result_aggregate: AtkResultAggregate;
  /** fetch data from the table: "atk_result" using primary key columns */
  atk_result_by_pk?: Maybe<AtkResult>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Maybe<AuthAccountProviders>>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate?: Maybe<AuthAccountProvidersAggregate>;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<AuthAccountProviders>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Maybe<AuthAccountRoles>>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate?: Maybe<AuthAccountRolesAggregate>;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<AuthAccountRoles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Maybe<AuthAccounts>>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate?: Maybe<AuthAccountsAggregate>;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<AuthAccounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Maybe<AuthProviders>>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate?: Maybe<AuthProvidersAggregate>;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Maybe<AuthRefreshTokens>>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate?: Maybe<AuthRefreshTokensAggregate>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Maybe<AuthRoles>>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate?: Maybe<AuthRolesAggregate>;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<AuthRoles>;
  /** fetch data from the table: "building" */
  building: Array<Building>;
  /** fetch aggregated fields from the table: "building" */
  building_aggregate: BuildingAggregate;
  /** fetch data from the table: "building" using primary key columns */
  building_by_pk?: Maybe<Building>;
  /** fetch data from the table: "check_in_by_date" */
  check_in_by_date: Array<CheckInByDate>;
  /** fetch aggregated fields from the table: "check_in_by_date" */
  check_in_by_date_aggregate: CheckInByDateAggregate;
  /** fetch data from the table: "check_in_out" */
  check_in_out: Array<CheckInOut>;
  /** fetch aggregated fields from the table: "check_in_out" */
  check_in_out_aggregate: CheckInOutAggregate;
  /** fetch data from the table: "check_in_out_by_date" */
  check_in_out_by_date: Array<CheckInOutByDate>;
  /** fetch aggregated fields from the table: "check_in_out_by_date" */
  check_in_out_by_date_aggregate: CheckInOutByDateAggregate;
  /** fetch data from the table: "check_in_out_daily" */
  check_in_out_daily: Array<CheckInOutDaily>;
  /** fetch aggregated fields from the table: "check_in_out_daily" */
  check_in_out_daily_aggregate: CheckInOutDailyAggregate;
  /** fetch data from the table: "check_in_out_summary_by_building" */
  check_in_out_summary_by_building: Array<CheckInOutSummaryByBuilding>;
  /** fetch aggregated fields from the table: "check_in_out_summary_by_building" */
  check_in_out_summary_by_building_aggregate: CheckInOutSummaryByBuildingAggregate;
  /** fetch data from the table: "check_in_out_summary_by_building_daily" */
  check_in_out_summary_by_building_daily: Array<CheckInOutSummaryByBuildingDaily>;
  /** fetch aggregated fields from the table: "check_in_out_summary_by_building_daily" */
  check_in_out_summary_by_building_daily_aggregate: CheckInOutSummaryByBuildingDailyAggregate;
  /** fetch data from the table: "check_out_by_date" */
  check_out_by_date: Array<CheckOutByDate>;
  /** fetch aggregated fields from the table: "check_out_by_date" */
  check_out_by_date_aggregate: CheckOutByDateAggregate;
  /** fetch data from the table: "constants" */
  constants: Array<Constants>;
  /** fetch aggregated fields from the table: "constants" */
  constants_aggregate: ConstantsAggregate;
  /** fetch data from the table: "constants" using primary key columns */
  constants_by_pk?: Maybe<Constants>;
  /** fetch data from the table: "course_syllabus" */
  course_syllabus: Array<Maybe<CourseSyllabus>>;
  /** fetch aggregated fields from the table: "course_syllabus" */
  course_syllabus_aggregate?: Maybe<CourseSyllabusAggregate>;
  /** fetch data from the table: "course_syllabus" using primary key columns */
  course_syllabus_by_pk?: Maybe<CourseSyllabus>;
  /** fetch data from the table: "daily_questionnaire" */
  daily_questionnaire: Array<DailyQuestionnaire>;
  /** fetch aggregated fields from the table: "daily_questionnaire" */
  daily_questionnaire_aggregate: DailyQuestionnaireAggregate;
  /** fetch data from the table: "danger_zone" */
  danger_zone: Array<DangerZone>;
  /** fetch aggregated fields from the table: "danger_zone" */
  danger_zone_aggregate: DangerZoneAggregate;
  /** fetch data from the table: "danger_zone" using primary key columns */
  danger_zone_by_pk?: Maybe<DangerZone>;
  /** fetch data from the table: "foreign_user" */
  foreign_user: Array<ForeignUser>;
  /** fetch aggregated fields from the table: "foreign_user" */
  foreign_user_aggregate: ForeignUserAggregate;
  /** execute function "my_atk_result" which returns "atk_result" */
  my_atk_result: Array<AtkResult>;
  /** execute function "my_atk_result" and query aggregates on result of table type "atk_result" */
  my_atk_result_aggregate: AtkResultAggregate;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: PermissionsAggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "questionnaire_answer" */
  questionnaire_answer: Array<QuestionnaireAnswer>;
  /** fetch aggregated fields from the table: "questionnaire_answer" */
  questionnaire_answer_aggregate: QuestionnaireAnswerAggregate;
  /** fetch data from the table: "questionnaire_answer" using primary key columns */
  questionnaire_answer_by_pk?: Maybe<QuestionnaireAnswer>;
  /** fetch data from the table: "trace" */
  trace: Array<Trace>;
  /** fetch aggregated fields from the table: "trace" */
  trace_aggregate: TraceAggregate;
  /** fetch data from the table: "trace" using primary key columns */
  trace_by_pk?: Maybe<Trace>;
  /** fetch data from the table: "trace_type" */
  trace_type: Array<TraceType>;
  /** fetch aggregated fields from the table: "trace_type" */
  trace_type_aggregate: TraceTypeAggregate;
  /** fetch data from the table: "trace_type" using primary key columns */
  trace_type_by_pk?: Maybe<TraceType>;
  /** fetch data from the table: "user_email" */
  user_email: Array<UserEmail>;
  /** fetch aggregated fields from the table: "user_email" */
  user_email_aggregate: UserEmailAggregate;
  /** fetch data from the table: "user_email" using primary key columns */
  user_email_by_pk?: Maybe<UserEmail>;
  /** fetch data from the table: "users" */
  users: Array<Maybe<Users>>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate?: Maybe<UsersAggregate>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vaccined_detail" */
  vaccined_detail: Array<VaccinedDetail>;
  /** fetch aggregated fields from the table: "vaccined_detail" */
  vaccined_detail_aggregate: VaccinedDetailAggregate;
  /** fetch data from the table: "vaccined_detail" using primary key columns */
  vaccined_detail_by_pk?: Maybe<VaccinedDetail>;
  /** fetch data from the table: "vaccined_infomation" */
  vaccined_infomation: Array<VaccinedInfomation>;
  /** fetch aggregated fields from the table: "vaccined_infomation" */
  vaccined_infomation_aggregate: VaccinedInfomationAggregate;
  /** fetch data from the table: "vaccined_infomation" using primary key columns */
  vaccined_infomation_by_pk?: Maybe<VaccinedInfomation>;
};


export type QueryRootAtkBrandArgs = {
  distinct_on?: Maybe<Array<AtkBrandSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkBrandOrderBy>>;
  where?: Maybe<AtkBrandBoolExp>;
};


export type QueryRootAtkBrandAggregateArgs = {
  distinct_on?: Maybe<Array<AtkBrandSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkBrandOrderBy>>;
  where?: Maybe<AtkBrandBoolExp>;
};


export type QueryRootAtkBrandByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAtkResultArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


export type QueryRootAtkResultAggregateArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


export type QueryRootAtkResultByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAuthAccountProvidersArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


export type QueryRootAuthAccountProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


export type QueryRootAuthAccountProvidersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAuthAccountRolesArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


export type QueryRootAuthAccountRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


export type QueryRootAuthAccountRolesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAuthAccountsArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};


export type QueryRootAuthAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};


export type QueryRootAuthAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAuthProvidersArgs = {
  distinct_on?: Maybe<Array<AuthProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthProvidersOrderBy>>;
  where?: Maybe<AuthProvidersBoolExp>;
};


export type QueryRootAuthProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthProvidersOrderBy>>;
  where?: Maybe<AuthProvidersBoolExp>;
};


export type QueryRootAuthProvidersByPkArgs = {
  provider: Scalars['String'];
};


export type QueryRootAuthRefreshTokensArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};


export type QueryRootAuthRefreshTokensAggregateArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};


export type QueryRootAuthRefreshTokensByPkArgs = {
  refresh_token: Scalars['uuid'];
};


export type QueryRootAuthRolesArgs = {
  distinct_on?: Maybe<Array<AuthRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRolesOrderBy>>;
  where?: Maybe<AuthRolesBoolExp>;
};


export type QueryRootAuthRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRolesOrderBy>>;
  where?: Maybe<AuthRolesBoolExp>;
};


export type QueryRootAuthRolesByPkArgs = {
  role: Scalars['String'];
};


export type QueryRootBuildingArgs = {
  distinct_on?: Maybe<Array<BuildingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BuildingOrderBy>>;
  where?: Maybe<BuildingBoolExp>;
};


export type QueryRootBuildingAggregateArgs = {
  distinct_on?: Maybe<Array<BuildingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BuildingOrderBy>>;
  where?: Maybe<BuildingBoolExp>;
};


export type QueryRootBuildingByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootCheckInByDateArgs = {
  distinct_on?: Maybe<Array<CheckInByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInByDateOrderBy>>;
  where?: Maybe<CheckInByDateBoolExp>;
};


export type QueryRootCheckInByDateAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInByDateOrderBy>>;
  where?: Maybe<CheckInByDateBoolExp>;
};


export type QueryRootCheckInOutArgs = {
  distinct_on?: Maybe<Array<CheckInOutSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutOrderBy>>;
  where?: Maybe<CheckInOutBoolExp>;
};


export type QueryRootCheckInOutAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutOrderBy>>;
  where?: Maybe<CheckInOutBoolExp>;
};


export type QueryRootCheckInOutByDateArgs = {
  distinct_on?: Maybe<Array<CheckInOutByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutByDateOrderBy>>;
  where?: Maybe<CheckInOutByDateBoolExp>;
};


export type QueryRootCheckInOutByDateAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutByDateOrderBy>>;
  where?: Maybe<CheckInOutByDateBoolExp>;
};


export type QueryRootCheckInOutDailyArgs = {
  distinct_on?: Maybe<Array<CheckInOutDailySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutDailyOrderBy>>;
  where?: Maybe<CheckInOutDailyBoolExp>;
};


export type QueryRootCheckInOutDailyAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutDailySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutDailyOrderBy>>;
  where?: Maybe<CheckInOutDailyBoolExp>;
};


export type QueryRootCheckInOutSummaryByBuildingArgs = {
  distinct_on?: Maybe<Array<CheckInOutSummaryByBuildingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutSummaryByBuildingOrderBy>>;
  where?: Maybe<CheckInOutSummaryByBuildingBoolExp>;
};


export type QueryRootCheckInOutSummaryByBuildingAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutSummaryByBuildingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutSummaryByBuildingOrderBy>>;
  where?: Maybe<CheckInOutSummaryByBuildingBoolExp>;
};


export type QueryRootCheckInOutSummaryByBuildingDailyArgs = {
  distinct_on?: Maybe<Array<CheckInOutSummaryByBuildingDailySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutSummaryByBuildingDailyOrderBy>>;
  where?: Maybe<CheckInOutSummaryByBuildingDailyBoolExp>;
};


export type QueryRootCheckInOutSummaryByBuildingDailyAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutSummaryByBuildingDailySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutSummaryByBuildingDailyOrderBy>>;
  where?: Maybe<CheckInOutSummaryByBuildingDailyBoolExp>;
};


export type QueryRootCheckOutByDateArgs = {
  distinct_on?: Maybe<Array<CheckOutByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckOutByDateOrderBy>>;
  where?: Maybe<CheckOutByDateBoolExp>;
};


export type QueryRootCheckOutByDateAggregateArgs = {
  distinct_on?: Maybe<Array<CheckOutByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckOutByDateOrderBy>>;
  where?: Maybe<CheckOutByDateBoolExp>;
};


export type QueryRootConstantsArgs = {
  distinct_on?: Maybe<Array<ConstantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ConstantsOrderBy>>;
  where?: Maybe<ConstantsBoolExp>;
};


export type QueryRootConstantsAggregateArgs = {
  distinct_on?: Maybe<Array<ConstantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ConstantsOrderBy>>;
  where?: Maybe<ConstantsBoolExp>;
};


export type QueryRootConstantsByPkArgs = {
  key: Scalars['String'];
};


export type QueryRootCourseSyllabusArgs = {
  distinct_on?: Maybe<Array<CourseSyllabusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseSyllabusOrderBy>>;
  where?: Maybe<CourseSyllabusBoolExp>;
};


export type QueryRootCourseSyllabusAggregateArgs = {
  distinct_on?: Maybe<Array<CourseSyllabusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CourseSyllabusOrderBy>>;
  where?: Maybe<CourseSyllabusBoolExp>;
};


export type QueryRootCourseSyllabusByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDailyQuestionnaireArgs = {
  distinct_on?: Maybe<Array<DailyQuestionnaireSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DailyQuestionnaireOrderBy>>;
  where?: Maybe<DailyQuestionnaireBoolExp>;
};


export type QueryRootDailyQuestionnaireAggregateArgs = {
  distinct_on?: Maybe<Array<DailyQuestionnaireSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DailyQuestionnaireOrderBy>>;
  where?: Maybe<DailyQuestionnaireBoolExp>;
};


export type QueryRootDangerZoneArgs = {
  distinct_on?: Maybe<Array<DangerZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DangerZoneOrderBy>>;
  where?: Maybe<DangerZoneBoolExp>;
};


export type QueryRootDangerZoneAggregateArgs = {
  distinct_on?: Maybe<Array<DangerZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DangerZoneOrderBy>>;
  where?: Maybe<DangerZoneBoolExp>;
};


export type QueryRootDangerZoneByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootForeignUserArgs = {
  distinct_on?: Maybe<Array<ForeignUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ForeignUserOrderBy>>;
  where?: Maybe<ForeignUserBoolExp>;
};


export type QueryRootForeignUserAggregateArgs = {
  distinct_on?: Maybe<Array<ForeignUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ForeignUserOrderBy>>;
  where?: Maybe<ForeignUserBoolExp>;
};


export type QueryRootMyAtkResultArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


export type QueryRootMyAtkResultAggregateArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


export type QueryRootPermissionsArgs = {
  distinct_on?: Maybe<Array<PermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PermissionsOrderBy>>;
  where?: Maybe<PermissionsBoolExp>;
};


export type QueryRootPermissionsAggregateArgs = {
  distinct_on?: Maybe<Array<PermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PermissionsOrderBy>>;
  where?: Maybe<PermissionsBoolExp>;
};


export type QueryRootPermissionsByPkArgs = {
  email: Scalars['String'];
};


export type QueryRootQuestionnaireAnswerArgs = {
  distinct_on?: Maybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: Maybe<QuestionnaireAnswerBoolExp>;
};


export type QueryRootQuestionnaireAnswerAggregateArgs = {
  distinct_on?: Maybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: Maybe<QuestionnaireAnswerBoolExp>;
};


export type QueryRootQuestionnaireAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTraceArgs = {
  distinct_on?: Maybe<Array<TraceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceOrderBy>>;
  where?: Maybe<TraceBoolExp>;
};


export type QueryRootTraceAggregateArgs = {
  distinct_on?: Maybe<Array<TraceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceOrderBy>>;
  where?: Maybe<TraceBoolExp>;
};


export type QueryRootTraceByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTraceTypeArgs = {
  distinct_on?: Maybe<Array<TraceTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceTypeOrderBy>>;
  where?: Maybe<TraceTypeBoolExp>;
};


export type QueryRootTraceTypeAggregateArgs = {
  distinct_on?: Maybe<Array<TraceTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceTypeOrderBy>>;
  where?: Maybe<TraceTypeBoolExp>;
};


export type QueryRootTraceTypeByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootUserEmailArgs = {
  distinct_on?: Maybe<Array<UserEmailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEmailOrderBy>>;
  where?: Maybe<UserEmailBoolExp>;
};


export type QueryRootUserEmailAggregateArgs = {
  distinct_on?: Maybe<Array<UserEmailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEmailOrderBy>>;
  where?: Maybe<UserEmailBoolExp>;
};


export type QueryRootUserEmailByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


export type QueryRootUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


export type QueryRootUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootVaccinedDetailArgs = {
  distinct_on?: Maybe<Array<VaccinedDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedDetailOrderBy>>;
  where?: Maybe<VaccinedDetailBoolExp>;
};


export type QueryRootVaccinedDetailAggregateArgs = {
  distinct_on?: Maybe<Array<VaccinedDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedDetailOrderBy>>;
  where?: Maybe<VaccinedDetailBoolExp>;
};


export type QueryRootVaccinedDetailByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootVaccinedInfomationArgs = {
  distinct_on?: Maybe<Array<VaccinedInfomationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedInfomationOrderBy>>;
  where?: Maybe<VaccinedInfomationBoolExp>;
};


export type QueryRootVaccinedInfomationAggregateArgs = {
  distinct_on?: Maybe<Array<VaccinedInfomationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedInfomationOrderBy>>;
  where?: Maybe<VaccinedInfomationBoolExp>;
};


export type QueryRootVaccinedInfomationByPkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "questionnaire_answer" */
export type QuestionnaireAnswer = {
  __typename?: 'questionnaire_answer';
  contact_name?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  date: Scalars['date'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  question_1: Scalars['String'];
  question_2: Scalars['String'];
  question_3: Scalars['String'];
  question_3_1: Scalars['Boolean'];
  question_3_2: Scalars['Boolean'];
  question_3_3: Scalars['Boolean'];
  question_3_4: Scalars['Boolean'];
  question_3_5: Scalars['Boolean'];
  question_3_6: Scalars['Boolean'];
  question_3_json: Scalars['jsonb'];
  question_4: Scalars['String'];
  risk: Scalars['Boolean'];
  tel?: Maybe<Scalars['String']>;
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "questionnaire_answer" */
export type QuestionnaireAnswerQuestion_3JsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "questionnaire_answer" */
export type QuestionnaireAnswerAggregate = {
  __typename?: 'questionnaire_answer_aggregate';
  aggregate?: Maybe<QuestionnaireAnswerAggregateFields>;
  nodes: Array<QuestionnaireAnswer>;
};

/** aggregate fields of "questionnaire_answer" */
export type QuestionnaireAnswerAggregateFields = {
  __typename?: 'questionnaire_answer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<QuestionnaireAnswerMaxFields>;
  min?: Maybe<QuestionnaireAnswerMinFields>;
};


/** aggregate fields of "questionnaire_answer" */
export type QuestionnaireAnswerAggregateFieldsCountArgs = {
  columns?: Maybe<Array<QuestionnaireAnswerSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type QuestionnaireAnswerAppendInput = {
  question_3_json?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "questionnaire_answer". All fields are combined with a logical 'AND'. */
export type QuestionnaireAnswerBoolExp = {
  _and?: Maybe<Array<QuestionnaireAnswerBoolExp>>;
  _not?: Maybe<QuestionnaireAnswerBoolExp>;
  _or?: Maybe<Array<QuestionnaireAnswerBoolExp>>;
  contact_name?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  question_1?: Maybe<StringComparisonExp>;
  question_2?: Maybe<StringComparisonExp>;
  question_3?: Maybe<StringComparisonExp>;
  question_3_1?: Maybe<BooleanComparisonExp>;
  question_3_2?: Maybe<BooleanComparisonExp>;
  question_3_3?: Maybe<BooleanComparisonExp>;
  question_3_4?: Maybe<BooleanComparisonExp>;
  question_3_5?: Maybe<BooleanComparisonExp>;
  question_3_6?: Maybe<BooleanComparisonExp>;
  question_3_json?: Maybe<JsonbComparisonExp>;
  question_4?: Maybe<StringComparisonExp>;
  risk?: Maybe<BooleanComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "questionnaire_answer" */
export enum QuestionnaireAnswerConstraint {
  /** unique or primary key constraint */
  QuestionnaireAnswerPkey = 'questionnaire_answer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type QuestionnaireAnswerDeleteAtPathInput = {
  question_3_json?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type QuestionnaireAnswerDeleteElemInput = {
  question_3_json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type QuestionnaireAnswerDeleteKeyInput = {
  question_3_json?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "questionnaire_answer" */
export type QuestionnaireAnswerInsertInput = {
  contact_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_3_1?: Maybe<Scalars['Boolean']>;
  question_3_2?: Maybe<Scalars['Boolean']>;
  question_3_3?: Maybe<Scalars['Boolean']>;
  question_3_4?: Maybe<Scalars['Boolean']>;
  question_3_5?: Maybe<Scalars['Boolean']>;
  question_3_6?: Maybe<Scalars['Boolean']>;
  question_3_json?: Maybe<Scalars['jsonb']>;
  question_4?: Maybe<Scalars['String']>;
  risk?: Maybe<Scalars['Boolean']>;
  tel?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type QuestionnaireAnswerMaxFields = {
  __typename?: 'questionnaire_answer_max_fields';
  contact_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_4?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type QuestionnaireAnswerMinFields = {
  __typename?: 'questionnaire_answer_min_fields';
  contact_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_4?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "questionnaire_answer" */
export type QuestionnaireAnswerMutationResponse = {
  __typename?: 'questionnaire_answer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireAnswer>;
};

/** on conflict condition type for table "questionnaire_answer" */
export type QuestionnaireAnswerOnConflict = {
  constraint: QuestionnaireAnswerConstraint;
  update_columns: Array<QuestionnaireAnswerUpdateColumn>;
  where?: Maybe<QuestionnaireAnswerBoolExp>;
};

/** Ordering options when selecting data from "questionnaire_answer". */
export type QuestionnaireAnswerOrderBy = {
  contact_name?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  question_1?: Maybe<OrderBy>;
  question_2?: Maybe<OrderBy>;
  question_3?: Maybe<OrderBy>;
  question_3_1?: Maybe<OrderBy>;
  question_3_2?: Maybe<OrderBy>;
  question_3_3?: Maybe<OrderBy>;
  question_3_4?: Maybe<OrderBy>;
  question_3_5?: Maybe<OrderBy>;
  question_3_6?: Maybe<OrderBy>;
  question_3_json?: Maybe<OrderBy>;
  question_4?: Maybe<OrderBy>;
  risk?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: questionnaire_answer */
export type QuestionnaireAnswerPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type QuestionnaireAnswerPrependInput = {
  question_3_json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "questionnaire_answer" */
export enum QuestionnaireAnswerSelectColumn {
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Question_1 = 'question_1',
  /** column name */
  Question_2 = 'question_2',
  /** column name */
  Question_3 = 'question_3',
  /** column name */
  Question_3_1 = 'question_3_1',
  /** column name */
  Question_3_2 = 'question_3_2',
  /** column name */
  Question_3_3 = 'question_3_3',
  /** column name */
  Question_3_4 = 'question_3_4',
  /** column name */
  Question_3_5 = 'question_3_5',
  /** column name */
  Question_3_6 = 'question_3_6',
  /** column name */
  Question_3Json = 'question_3_json',
  /** column name */
  Question_4 = 'question_4',
  /** column name */
  Risk = 'risk',
  /** column name */
  Tel = 'tel',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "questionnaire_answer" */
export type QuestionnaireAnswerSetInput = {
  contact_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_1?: Maybe<Scalars['String']>;
  question_2?: Maybe<Scalars['String']>;
  question_3?: Maybe<Scalars['String']>;
  question_3_1?: Maybe<Scalars['Boolean']>;
  question_3_2?: Maybe<Scalars['Boolean']>;
  question_3_3?: Maybe<Scalars['Boolean']>;
  question_3_4?: Maybe<Scalars['Boolean']>;
  question_3_5?: Maybe<Scalars['Boolean']>;
  question_3_6?: Maybe<Scalars['Boolean']>;
  question_3_json?: Maybe<Scalars['jsonb']>;
  question_4?: Maybe<Scalars['String']>;
  risk?: Maybe<Scalars['Boolean']>;
  tel?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "questionnaire_answer" */
export enum QuestionnaireAnswerUpdateColumn {
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Question_1 = 'question_1',
  /** column name */
  Question_2 = 'question_2',
  /** column name */
  Question_3 = 'question_3',
  /** column name */
  Question_3_1 = 'question_3_1',
  /** column name */
  Question_3_2 = 'question_3_2',
  /** column name */
  Question_3_3 = 'question_3_3',
  /** column name */
  Question_3_4 = 'question_3_4',
  /** column name */
  Question_3_5 = 'question_3_5',
  /** column name */
  Question_3_6 = 'question_3_6',
  /** column name */
  Question_3Json = 'question_3_json',
  /** column name */
  Question_4 = 'question_4',
  /** column name */
  Risk = 'risk',
  /** column name */
  Tel = 'tel',
  /** column name */
  UserId = 'user_id'
}

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "atk_brand" */
  atk_brand: Array<AtkBrand>;
  /** fetch aggregated fields from the table: "atk_brand" */
  atk_brand_aggregate: AtkBrandAggregate;
  /** fetch data from the table: "atk_brand" using primary key columns */
  atk_brand_by_pk?: Maybe<AtkBrand>;
  /** fetch data from the table: "atk_result" */
  atk_result: Array<AtkResult>;
  /** fetch aggregated fields from the table: "atk_result" */
  atk_result_aggregate: AtkResultAggregate;
  /** fetch data from the table: "atk_result" using primary key columns */
  atk_result_by_pk?: Maybe<AtkResult>;
  /** fetch data from the table: "building" */
  building: Array<Building>;
  /** fetch aggregated fields from the table: "building" */
  building_aggregate: BuildingAggregate;
  /** fetch data from the table: "building" using primary key columns */
  building_by_pk?: Maybe<Building>;
  /** fetch data from the table: "check_in_by_date" */
  check_in_by_date: Array<CheckInByDate>;
  /** fetch aggregated fields from the table: "check_in_by_date" */
  check_in_by_date_aggregate: CheckInByDateAggregate;
  /** fetch data from the table: "check_in_out" */
  check_in_out: Array<CheckInOut>;
  /** fetch aggregated fields from the table: "check_in_out" */
  check_in_out_aggregate: CheckInOutAggregate;
  /** fetch data from the table: "check_in_out_by_date" */
  check_in_out_by_date: Array<CheckInOutByDate>;
  /** fetch aggregated fields from the table: "check_in_out_by_date" */
  check_in_out_by_date_aggregate: CheckInOutByDateAggregate;
  /** fetch data from the table: "check_in_out_daily" */
  check_in_out_daily: Array<CheckInOutDaily>;
  /** fetch aggregated fields from the table: "check_in_out_daily" */
  check_in_out_daily_aggregate: CheckInOutDailyAggregate;
  /** fetch data from the table: "check_in_out_summary_by_building" */
  check_in_out_summary_by_building: Array<CheckInOutSummaryByBuilding>;
  /** fetch aggregated fields from the table: "check_in_out_summary_by_building" */
  check_in_out_summary_by_building_aggregate: CheckInOutSummaryByBuildingAggregate;
  /** fetch data from the table: "check_in_out_summary_by_building_daily" */
  check_in_out_summary_by_building_daily: Array<CheckInOutSummaryByBuildingDaily>;
  /** fetch aggregated fields from the table: "check_in_out_summary_by_building_daily" */
  check_in_out_summary_by_building_daily_aggregate: CheckInOutSummaryByBuildingDailyAggregate;
  /** fetch data from the table: "check_out_by_date" */
  check_out_by_date: Array<CheckOutByDate>;
  /** fetch aggregated fields from the table: "check_out_by_date" */
  check_out_by_date_aggregate: CheckOutByDateAggregate;
  /** fetch data from the table: "constants" */
  constants: Array<Constants>;
  /** fetch aggregated fields from the table: "constants" */
  constants_aggregate: ConstantsAggregate;
  /** fetch data from the table: "constants" using primary key columns */
  constants_by_pk?: Maybe<Constants>;
  /** fetch data from the table: "daily_questionnaire" */
  daily_questionnaire: Array<DailyQuestionnaire>;
  /** fetch aggregated fields from the table: "daily_questionnaire" */
  daily_questionnaire_aggregate: DailyQuestionnaireAggregate;
  /** fetch data from the table: "danger_zone" */
  danger_zone: Array<DangerZone>;
  /** fetch aggregated fields from the table: "danger_zone" */
  danger_zone_aggregate: DangerZoneAggregate;
  /** fetch data from the table: "danger_zone" using primary key columns */
  danger_zone_by_pk?: Maybe<DangerZone>;
  /** fetch data from the table: "foreign_user" */
  foreign_user: Array<ForeignUser>;
  /** fetch aggregated fields from the table: "foreign_user" */
  foreign_user_aggregate: ForeignUserAggregate;
  /** execute function "my_atk_result" which returns "atk_result" */
  my_atk_result: Array<AtkResult>;
  /** execute function "my_atk_result" and query aggregates on result of table type "atk_result" */
  my_atk_result_aggregate: AtkResultAggregate;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: PermissionsAggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "questionnaire_answer" */
  questionnaire_answer: Array<QuestionnaireAnswer>;
  /** fetch aggregated fields from the table: "questionnaire_answer" */
  questionnaire_answer_aggregate: QuestionnaireAnswerAggregate;
  /** fetch data from the table: "questionnaire_answer" using primary key columns */
  questionnaire_answer_by_pk?: Maybe<QuestionnaireAnswer>;
  /** fetch data from the table: "trace" */
  trace: Array<Trace>;
  /** fetch aggregated fields from the table: "trace" */
  trace_aggregate: TraceAggregate;
  /** fetch data from the table: "trace" using primary key columns */
  trace_by_pk?: Maybe<Trace>;
  /** fetch data from the table: "trace_type" */
  trace_type: Array<TraceType>;
  /** fetch aggregated fields from the table: "trace_type" */
  trace_type_aggregate: TraceTypeAggregate;
  /** fetch data from the table: "trace_type" using primary key columns */
  trace_type_by_pk?: Maybe<TraceType>;
  /** fetch data from the table: "user_email" */
  user_email: Array<UserEmail>;
  /** fetch aggregated fields from the table: "user_email" */
  user_email_aggregate: UserEmailAggregate;
  /** fetch data from the table: "user_email" using primary key columns */
  user_email_by_pk?: Maybe<UserEmail>;
  /** fetch data from the table: "vaccined_detail" */
  vaccined_detail: Array<VaccinedDetail>;
  /** fetch aggregated fields from the table: "vaccined_detail" */
  vaccined_detail_aggregate: VaccinedDetailAggregate;
  /** fetch data from the table: "vaccined_detail" using primary key columns */
  vaccined_detail_by_pk?: Maybe<VaccinedDetail>;
  /** fetch data from the table: "vaccined_infomation" */
  vaccined_infomation: Array<VaccinedInfomation>;
  /** fetch aggregated fields from the table: "vaccined_infomation" */
  vaccined_infomation_aggregate: VaccinedInfomationAggregate;
  /** fetch data from the table: "vaccined_infomation" using primary key columns */
  vaccined_infomation_by_pk?: Maybe<VaccinedInfomation>;
};


export type SubscriptionRootAtkBrandArgs = {
  distinct_on?: Maybe<Array<AtkBrandSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkBrandOrderBy>>;
  where?: Maybe<AtkBrandBoolExp>;
};


export type SubscriptionRootAtkBrandAggregateArgs = {
  distinct_on?: Maybe<Array<AtkBrandSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkBrandOrderBy>>;
  where?: Maybe<AtkBrandBoolExp>;
};


export type SubscriptionRootAtkBrandByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAtkResultArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


export type SubscriptionRootAtkResultAggregateArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


export type SubscriptionRootAtkResultByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootBuildingArgs = {
  distinct_on?: Maybe<Array<BuildingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BuildingOrderBy>>;
  where?: Maybe<BuildingBoolExp>;
};


export type SubscriptionRootBuildingAggregateArgs = {
  distinct_on?: Maybe<Array<BuildingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BuildingOrderBy>>;
  where?: Maybe<BuildingBoolExp>;
};


export type SubscriptionRootBuildingByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootCheckInByDateArgs = {
  distinct_on?: Maybe<Array<CheckInByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInByDateOrderBy>>;
  where?: Maybe<CheckInByDateBoolExp>;
};


export type SubscriptionRootCheckInByDateAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInByDateOrderBy>>;
  where?: Maybe<CheckInByDateBoolExp>;
};


export type SubscriptionRootCheckInOutArgs = {
  distinct_on?: Maybe<Array<CheckInOutSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutOrderBy>>;
  where?: Maybe<CheckInOutBoolExp>;
};


export type SubscriptionRootCheckInOutAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutOrderBy>>;
  where?: Maybe<CheckInOutBoolExp>;
};


export type SubscriptionRootCheckInOutByDateArgs = {
  distinct_on?: Maybe<Array<CheckInOutByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutByDateOrderBy>>;
  where?: Maybe<CheckInOutByDateBoolExp>;
};


export type SubscriptionRootCheckInOutByDateAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutByDateOrderBy>>;
  where?: Maybe<CheckInOutByDateBoolExp>;
};


export type SubscriptionRootCheckInOutDailyArgs = {
  distinct_on?: Maybe<Array<CheckInOutDailySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutDailyOrderBy>>;
  where?: Maybe<CheckInOutDailyBoolExp>;
};


export type SubscriptionRootCheckInOutDailyAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutDailySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutDailyOrderBy>>;
  where?: Maybe<CheckInOutDailyBoolExp>;
};


export type SubscriptionRootCheckInOutSummaryByBuildingArgs = {
  distinct_on?: Maybe<Array<CheckInOutSummaryByBuildingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutSummaryByBuildingOrderBy>>;
  where?: Maybe<CheckInOutSummaryByBuildingBoolExp>;
};


export type SubscriptionRootCheckInOutSummaryByBuildingAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutSummaryByBuildingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutSummaryByBuildingOrderBy>>;
  where?: Maybe<CheckInOutSummaryByBuildingBoolExp>;
};


export type SubscriptionRootCheckInOutSummaryByBuildingDailyArgs = {
  distinct_on?: Maybe<Array<CheckInOutSummaryByBuildingDailySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutSummaryByBuildingDailyOrderBy>>;
  where?: Maybe<CheckInOutSummaryByBuildingDailyBoolExp>;
};


export type SubscriptionRootCheckInOutSummaryByBuildingDailyAggregateArgs = {
  distinct_on?: Maybe<Array<CheckInOutSummaryByBuildingDailySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckInOutSummaryByBuildingDailyOrderBy>>;
  where?: Maybe<CheckInOutSummaryByBuildingDailyBoolExp>;
};


export type SubscriptionRootCheckOutByDateArgs = {
  distinct_on?: Maybe<Array<CheckOutByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckOutByDateOrderBy>>;
  where?: Maybe<CheckOutByDateBoolExp>;
};


export type SubscriptionRootCheckOutByDateAggregateArgs = {
  distinct_on?: Maybe<Array<CheckOutByDateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CheckOutByDateOrderBy>>;
  where?: Maybe<CheckOutByDateBoolExp>;
};


export type SubscriptionRootConstantsArgs = {
  distinct_on?: Maybe<Array<ConstantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ConstantsOrderBy>>;
  where?: Maybe<ConstantsBoolExp>;
};


export type SubscriptionRootConstantsAggregateArgs = {
  distinct_on?: Maybe<Array<ConstantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ConstantsOrderBy>>;
  where?: Maybe<ConstantsBoolExp>;
};


export type SubscriptionRootConstantsByPkArgs = {
  key: Scalars['String'];
};


export type SubscriptionRootDailyQuestionnaireArgs = {
  distinct_on?: Maybe<Array<DailyQuestionnaireSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DailyQuestionnaireOrderBy>>;
  where?: Maybe<DailyQuestionnaireBoolExp>;
};


export type SubscriptionRootDailyQuestionnaireAggregateArgs = {
  distinct_on?: Maybe<Array<DailyQuestionnaireSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DailyQuestionnaireOrderBy>>;
  where?: Maybe<DailyQuestionnaireBoolExp>;
};


export type SubscriptionRootDangerZoneArgs = {
  distinct_on?: Maybe<Array<DangerZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DangerZoneOrderBy>>;
  where?: Maybe<DangerZoneBoolExp>;
};


export type SubscriptionRootDangerZoneAggregateArgs = {
  distinct_on?: Maybe<Array<DangerZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DangerZoneOrderBy>>;
  where?: Maybe<DangerZoneBoolExp>;
};


export type SubscriptionRootDangerZoneByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootForeignUserArgs = {
  distinct_on?: Maybe<Array<ForeignUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ForeignUserOrderBy>>;
  where?: Maybe<ForeignUserBoolExp>;
};


export type SubscriptionRootForeignUserAggregateArgs = {
  distinct_on?: Maybe<Array<ForeignUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ForeignUserOrderBy>>;
  where?: Maybe<ForeignUserBoolExp>;
};


export type SubscriptionRootMyAtkResultArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


export type SubscriptionRootMyAtkResultAggregateArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


export type SubscriptionRootPermissionsArgs = {
  distinct_on?: Maybe<Array<PermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PermissionsOrderBy>>;
  where?: Maybe<PermissionsBoolExp>;
};


export type SubscriptionRootPermissionsAggregateArgs = {
  distinct_on?: Maybe<Array<PermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PermissionsOrderBy>>;
  where?: Maybe<PermissionsBoolExp>;
};


export type SubscriptionRootPermissionsByPkArgs = {
  email: Scalars['String'];
};


export type SubscriptionRootQuestionnaireAnswerArgs = {
  distinct_on?: Maybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: Maybe<QuestionnaireAnswerBoolExp>;
};


export type SubscriptionRootQuestionnaireAnswerAggregateArgs = {
  distinct_on?: Maybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: Maybe<QuestionnaireAnswerBoolExp>;
};


export type SubscriptionRootQuestionnaireAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTraceArgs = {
  distinct_on?: Maybe<Array<TraceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceOrderBy>>;
  where?: Maybe<TraceBoolExp>;
};


export type SubscriptionRootTraceAggregateArgs = {
  distinct_on?: Maybe<Array<TraceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceOrderBy>>;
  where?: Maybe<TraceBoolExp>;
};


export type SubscriptionRootTraceByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTraceTypeArgs = {
  distinct_on?: Maybe<Array<TraceTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceTypeOrderBy>>;
  where?: Maybe<TraceTypeBoolExp>;
};


export type SubscriptionRootTraceTypeAggregateArgs = {
  distinct_on?: Maybe<Array<TraceTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceTypeOrderBy>>;
  where?: Maybe<TraceTypeBoolExp>;
};


export type SubscriptionRootTraceTypeByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootUserEmailArgs = {
  distinct_on?: Maybe<Array<UserEmailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEmailOrderBy>>;
  where?: Maybe<UserEmailBoolExp>;
};


export type SubscriptionRootUserEmailAggregateArgs = {
  distinct_on?: Maybe<Array<UserEmailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserEmailOrderBy>>;
  where?: Maybe<UserEmailBoolExp>;
};


export type SubscriptionRootUserEmailByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootVaccinedDetailArgs = {
  distinct_on?: Maybe<Array<VaccinedDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedDetailOrderBy>>;
  where?: Maybe<VaccinedDetailBoolExp>;
};


export type SubscriptionRootVaccinedDetailAggregateArgs = {
  distinct_on?: Maybe<Array<VaccinedDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedDetailOrderBy>>;
  where?: Maybe<VaccinedDetailBoolExp>;
};


export type SubscriptionRootVaccinedDetailByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootVaccinedInfomationArgs = {
  distinct_on?: Maybe<Array<VaccinedInfomationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedInfomationOrderBy>>;
  where?: Maybe<VaccinedInfomationBoolExp>;
};


export type SubscriptionRootVaccinedInfomationAggregateArgs = {
  distinct_on?: Maybe<Array<VaccinedInfomationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedInfomationOrderBy>>;
  where?: Maybe<VaccinedInfomationBoolExp>;
};


export type SubscriptionRootVaccinedInfomationByPkArgs = {
  id: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type TimetzComparisonExp = {
  _eq?: Maybe<Scalars['timetz']>;
  _gt?: Maybe<Scalars['timetz']>;
  _gte?: Maybe<Scalars['timetz']>;
  _in?: Maybe<Array<Scalars['timetz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timetz']>;
  _lte?: Maybe<Scalars['timetz']>;
  _neq?: Maybe<Scalars['timetz']>;
  _nin?: Maybe<Array<Scalars['timetz']>>;
};

/** columns and relationships of "trace" */
export type Trace = {
  __typename?: 'trace';
  /** An object relationship */
  building: Building;
  building_id: Scalars['String'];
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  tel?: Maybe<Scalars['String']>;
  timestamp: Scalars['timestamptz'];
  /** An object relationship */
  trace_type: TraceType;
  type: TraceTypeEnum;
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "trace" */
export type TraceAggregate = {
  __typename?: 'trace_aggregate';
  aggregate?: Maybe<TraceAggregateFields>;
  nodes: Array<Trace>;
};

/** aggregate fields of "trace" */
export type TraceAggregateFields = {
  __typename?: 'trace_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TraceMaxFields>;
  min?: Maybe<TraceMinFields>;
};


/** aggregate fields of "trace" */
export type TraceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TraceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trace" */
export type TraceAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<TraceMaxOrderBy>;
  min?: Maybe<TraceMinOrderBy>;
};

/** input type for inserting array relation for remote table "trace" */
export type TraceArrRelInsertInput = {
  data: Array<TraceInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<TraceOnConflict>;
};

/** Boolean expression to filter rows from the table "trace". All fields are combined with a logical 'AND'. */
export type TraceBoolExp = {
  _and?: Maybe<Array<TraceBoolExp>>;
  _not?: Maybe<TraceBoolExp>;
  _or?: Maybe<Array<TraceBoolExp>>;
  building?: Maybe<BuildingBoolExp>;
  building_id?: Maybe<StringComparisonExp>;
  contact_name?: Maybe<StringComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  timestamp?: Maybe<TimestamptzComparisonExp>;
  trace_type?: Maybe<TraceTypeBoolExp>;
  type?: Maybe<TraceTypeEnumComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "trace" */
export enum TraceConstraint {
  /** unique or primary key constraint */
  TracePkey = 'trace_pkey'
}

/** input type for inserting data into table "trace" */
export type TraceInsertInput = {
  building?: Maybe<BuildingObjRelInsertInput>;
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  trace_type?: Maybe<TraceTypeObjRelInsertInput>;
  type?: Maybe<TraceTypeEnum>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TraceMaxFields = {
  __typename?: 'trace_max_fields';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "trace" */
export type TraceMaxOrderBy = {
  building_id?: Maybe<OrderBy>;
  contact_name?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type TraceMinFields = {
  __typename?: 'trace_min_fields';
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "trace" */
export type TraceMinOrderBy = {
  building_id?: Maybe<OrderBy>;
  contact_name?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "trace" */
export type TraceMutationResponse = {
  __typename?: 'trace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trace>;
};

/** on conflict condition type for table "trace" */
export type TraceOnConflict = {
  constraint: TraceConstraint;
  update_columns: Array<TraceUpdateColumn>;
  where?: Maybe<TraceBoolExp>;
};

/** Ordering options when selecting data from "trace". */
export type TraceOrderBy = {
  building?: Maybe<BuildingOrderBy>;
  building_id?: Maybe<OrderBy>;
  contact_name?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  trace_type?: Maybe<TraceTypeOrderBy>;
  type?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: trace */
export type TracePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "trace" */
export enum TraceSelectColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Tel = 'tel',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "trace" */
export type TraceSetInput = {
  building_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tel?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<TraceTypeEnum>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "trace_type" */
export type TraceType = {
  __typename?: 'trace_type';
  description: Scalars['String'];
  /** An array relationship */
  traces: Array<Trace>;
  /** An aggregate relationship */
  traces_aggregate: TraceAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "trace_type" */
export type TraceTypeTracesArgs = {
  distinct_on?: Maybe<Array<TraceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceOrderBy>>;
  where?: Maybe<TraceBoolExp>;
};


/** columns and relationships of "trace_type" */
export type TraceTypeTracesAggregateArgs = {
  distinct_on?: Maybe<Array<TraceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TraceOrderBy>>;
  where?: Maybe<TraceBoolExp>;
};

/** aggregated selection of "trace_type" */
export type TraceTypeAggregate = {
  __typename?: 'trace_type_aggregate';
  aggregate?: Maybe<TraceTypeAggregateFields>;
  nodes: Array<TraceType>;
};

/** aggregate fields of "trace_type" */
export type TraceTypeAggregateFields = {
  __typename?: 'trace_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TraceTypeMaxFields>;
  min?: Maybe<TraceTypeMinFields>;
};


/** aggregate fields of "trace_type" */
export type TraceTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TraceTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "trace_type". All fields are combined with a logical 'AND'. */
export type TraceTypeBoolExp = {
  _and?: Maybe<Array<TraceTypeBoolExp>>;
  _not?: Maybe<TraceTypeBoolExp>;
  _or?: Maybe<Array<TraceTypeBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  traces?: Maybe<TraceBoolExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "trace_type" */
export enum TraceTypeConstraint {
  /** unique or primary key constraint */
  TraceTypePkey = 'trace_type_pkey'
}

export enum TraceTypeEnum {
  /** Check In */
  In = 'in',
  /** Check Out */
  Out = 'out'
}

/** Boolean expression to compare columns of type "trace_type_enum". All fields are combined with logical 'AND'. */
export type TraceTypeEnumComparisonExp = {
  _eq?: Maybe<TraceTypeEnum>;
  _in?: Maybe<Array<TraceTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<TraceTypeEnum>;
  _nin?: Maybe<Array<TraceTypeEnum>>;
};

/** input type for inserting data into table "trace_type" */
export type TraceTypeInsertInput = {
  description?: Maybe<Scalars['String']>;
  traces?: Maybe<TraceArrRelInsertInput>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TraceTypeMaxFields = {
  __typename?: 'trace_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TraceTypeMinFields = {
  __typename?: 'trace_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "trace_type" */
export type TraceTypeMutationResponse = {
  __typename?: 'trace_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TraceType>;
};

/** input type for inserting object relation for remote table "trace_type" */
export type TraceTypeObjRelInsertInput = {
  data: TraceTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<TraceTypeOnConflict>;
};

/** on conflict condition type for table "trace_type" */
export type TraceTypeOnConflict = {
  constraint: TraceTypeConstraint;
  update_columns: Array<TraceTypeUpdateColumn>;
  where?: Maybe<TraceTypeBoolExp>;
};

/** Ordering options when selecting data from "trace_type". */
export type TraceTypeOrderBy = {
  description?: Maybe<OrderBy>;
  traces_aggregate?: Maybe<TraceAggregateOrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: trace_type */
export type TraceTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "trace_type" */
export enum TraceTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "trace_type" */
export type TraceTypeSetInput = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "trace_type" */
export enum TraceTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "trace" */
export enum TraceUpdateColumn {
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Tel = 'tel',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_email" */
export type UserEmail = {
  __typename?: 'user_email';
  /** An array relationship */
  atk_results: Array<AtkResult>;
  /** An aggregate relationship */
  atk_results_aggregate: AtkResultAggregate;
  email: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  user?: Maybe<ForeignUser>;
  /** An object relationship */
  vaccined_information?: Maybe<VaccinedInfomation>;
};


/** columns and relationships of "user_email" */
export type UserEmailAtkResultsArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};


/** columns and relationships of "user_email" */
export type UserEmailAtkResultsAggregateArgs = {
  distinct_on?: Maybe<Array<AtkResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy>>;
  where?: Maybe<AtkResultBoolExp>;
};

/** aggregated selection of "user_email" */
export type UserEmailAggregate = {
  __typename?: 'user_email_aggregate';
  aggregate?: Maybe<UserEmailAggregateFields>;
  nodes: Array<UserEmail>;
};

/** aggregate fields of "user_email" */
export type UserEmailAggregateFields = {
  __typename?: 'user_email_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserEmailMaxFields>;
  min?: Maybe<UserEmailMinFields>;
};


/** aggregate fields of "user_email" */
export type UserEmailAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UserEmailSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_email". All fields are combined with a logical 'AND'. */
export type UserEmailBoolExp = {
  _and?: Maybe<Array<UserEmailBoolExp>>;
  _not?: Maybe<UserEmailBoolExp>;
  _or?: Maybe<Array<UserEmailBoolExp>>;
  atk_results?: Maybe<AtkResultBoolExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  user?: Maybe<ForeignUserBoolExp>;
  vaccined_information?: Maybe<VaccinedInfomationBoolExp>;
};

/** unique or primary key constraints on table "user_email" */
export enum UserEmailConstraint {
  /** unique or primary key constraint */
  UserEmailPkey = 'user_email_pkey'
}

/** input type for inserting data into table "user_email" */
export type UserEmailInsertInput = {
  atk_results?: Maybe<AtkResultArrRelInsertInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user?: Maybe<ForeignUserObjRelInsertInput>;
  vaccined_information?: Maybe<VaccinedInfomationObjRelInsertInput>;
};

/** aggregate max on columns */
export type UserEmailMaxFields = {
  __typename?: 'user_email_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserEmailMinFields = {
  __typename?: 'user_email_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_email" */
export type UserEmailMutationResponse = {
  __typename?: 'user_email_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserEmail>;
};

/** on conflict condition type for table "user_email" */
export type UserEmailOnConflict = {
  constraint: UserEmailConstraint;
  update_columns: Array<UserEmailUpdateColumn>;
  where?: Maybe<UserEmailBoolExp>;
};

/** Ordering options when selecting data from "user_email". */
export type UserEmailOrderBy = {
  atk_results_aggregate?: Maybe<AtkResultAggregateOrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user?: Maybe<ForeignUserOrderBy>;
  vaccined_information?: Maybe<VaccinedInfomationOrderBy>;
};

/** primary key columns input for table: user_email */
export type UserEmailPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "user_email" */
export enum UserEmailSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "user_email" */
export type UserEmailSetInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "user_email" */
export enum UserEmailUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id'
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  account?: Maybe<AuthAccounts>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  dn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<Scalars['String']>;
  organization_unit?: Maybe<Scalars['String']>;
  ou_list?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "users" */
export type UsersOuListArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Maybe<Users>>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
};


/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UsersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UsersAppendInput = {
  ou_list?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: Maybe<Array<Maybe<UsersBoolExp>>>;
  _not?: Maybe<UsersBoolExp>;
  _or?: Maybe<Array<Maybe<UsersBoolExp>>>;
  account?: Maybe<AuthAccountsBoolExp>;
  avatar_url?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  dn?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<StringComparisonExp>;
  organization_unit?: Maybe<StringComparisonExp>;
  ou_list?: Maybe<JsonbComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UsersDeleteAtPathInput = {
  ou_list?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UsersDeleteElemInput = {
  ou_list?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UsersDeleteKeyInput = {
  ou_list?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  account?: Maybe<AuthAccountsObjRelInsertInput>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  dn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<Scalars['String']>;
  organization_unit?: Maybe<Scalars['String']>;
  ou_list?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'users_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  dn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<Scalars['String']>;
  organization_unit?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'users_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  dn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<Scalars['String']>;
  organization_unit?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows?: Maybe<Scalars['Int']>;
  /** data of the affected rows by the mutation */
  returning: Array<Maybe<Users>>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  on_conflict?: Maybe<UsersOnConflict>;
};

/** on conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns: Array<UsersUpdateColumn>;
  where?: Maybe<UsersBoolExp>;
};

/** ordering options when selecting data from "users" */
export type UsersOrderBy = {
  account?: Maybe<AuthAccountsOrderBy>;
  avatar_url?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  dn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization?: Maybe<OrderBy>;
  organization_unit?: Maybe<OrderBy>;
  ou_list?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "users" */
export type UsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UsersPrependInput = {
  ou_list?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Dn = 'dn',
  /** column name */
  Id = 'id',
  /** column name */
  Organization = 'organization',
  /** column name */
  OrganizationUnit = 'organization_unit',
  /** column name */
  OuList = 'ou_list',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  dn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<Scalars['String']>;
  organization_unit?: Maybe<Scalars['String']>;
  ou_list?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Dn = 'dn',
  /** column name */
  Id = 'id',
  /** column name */
  Organization = 'organization',
  /** column name */
  OrganizationUnit = 'organization_unit',
  /** column name */
  OuList = 'ou_list',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/**
 * ตารางข้อมูลการรับ vaccine
 * 
 * 
 * columns and relationships of "vaccined_detail"
 */
export type VaccinedDetail = {
  __typename?: 'vaccined_detail';
  citizen_id: Scalars['String'];
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  vaccined_1_band?: Maybe<Scalars['String']>;
  vaccined_1_date?: Maybe<Scalars['date']>;
  vaccined_2_band?: Maybe<Scalars['String']>;
  vaccined_2_date?: Maybe<Scalars['date']>;
  vaccined_3_band?: Maybe<Scalars['String']>;
  vaccined_3_date?: Maybe<Scalars['date']>;
  vaccined_4_band?: Maybe<Scalars['String']>;
  vaccined_4_date?: Maybe<Scalars['date']>;
  vaccined_5_band?: Maybe<Scalars['String']>;
  vaccined_5_date?: Maybe<Scalars['date']>;
  vaccined_6_band?: Maybe<Scalars['String']>;
  vaccined_6_date?: Maybe<Scalars['date']>;
};

/** aggregated selection of "vaccined_detail" */
export type VaccinedDetailAggregate = {
  __typename?: 'vaccined_detail_aggregate';
  aggregate?: Maybe<VaccinedDetailAggregateFields>;
  nodes: Array<VaccinedDetail>;
};

/** aggregate fields of "vaccined_detail" */
export type VaccinedDetailAggregateFields = {
  __typename?: 'vaccined_detail_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<VaccinedDetailMaxFields>;
  min?: Maybe<VaccinedDetailMinFields>;
};


/** aggregate fields of "vaccined_detail" */
export type VaccinedDetailAggregateFieldsCountArgs = {
  columns?: Maybe<Array<VaccinedDetailSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vaccined_detail" */
export type VaccinedDetailAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<VaccinedDetailMaxOrderBy>;
  min?: Maybe<VaccinedDetailMinOrderBy>;
};

/** input type for inserting array relation for remote table "vaccined_detail" */
export type VaccinedDetailArrRelInsertInput = {
  data: Array<VaccinedDetailInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<VaccinedDetailOnConflict>;
};

/** Boolean expression to filter rows from the table "vaccined_detail". All fields are combined with a logical 'AND'. */
export type VaccinedDetailBoolExp = {
  _and?: Maybe<Array<VaccinedDetailBoolExp>>;
  _not?: Maybe<VaccinedDetailBoolExp>;
  _or?: Maybe<Array<VaccinedDetailBoolExp>>;
  citizen_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  vaccined_1_band?: Maybe<StringComparisonExp>;
  vaccined_1_date?: Maybe<DateComparisonExp>;
  vaccined_2_band?: Maybe<StringComparisonExp>;
  vaccined_2_date?: Maybe<DateComparisonExp>;
  vaccined_3_band?: Maybe<StringComparisonExp>;
  vaccined_3_date?: Maybe<DateComparisonExp>;
  vaccined_4_band?: Maybe<StringComparisonExp>;
  vaccined_4_date?: Maybe<DateComparisonExp>;
  vaccined_5_band?: Maybe<StringComparisonExp>;
  vaccined_5_date?: Maybe<DateComparisonExp>;
  vaccined_6_band?: Maybe<StringComparisonExp>;
  vaccined_6_date?: Maybe<DateComparisonExp>;
};

/** unique or primary key constraints on table "vaccined_detail" */
export enum VaccinedDetailConstraint {
  /** unique or primary key constraint */
  VaccinedDetailCitizenIdKey = 'vaccined_detail_citizen_id_key',
  /** unique or primary key constraint */
  VaccinedDetailPkey = 'vaccined_detail_pkey'
}

/** input type for inserting data into table "vaccined_detail" */
export type VaccinedDetailInsertInput = {
  citizen_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vaccined_1_band?: Maybe<Scalars['String']>;
  vaccined_1_date?: Maybe<Scalars['date']>;
  vaccined_2_band?: Maybe<Scalars['String']>;
  vaccined_2_date?: Maybe<Scalars['date']>;
  vaccined_3_band?: Maybe<Scalars['String']>;
  vaccined_3_date?: Maybe<Scalars['date']>;
  vaccined_4_band?: Maybe<Scalars['String']>;
  vaccined_4_date?: Maybe<Scalars['date']>;
  vaccined_5_band?: Maybe<Scalars['String']>;
  vaccined_5_date?: Maybe<Scalars['date']>;
  vaccined_6_band?: Maybe<Scalars['String']>;
  vaccined_6_date?: Maybe<Scalars['date']>;
};

/** aggregate max on columns */
export type VaccinedDetailMaxFields = {
  __typename?: 'vaccined_detail_max_fields';
  citizen_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vaccined_1_band?: Maybe<Scalars['String']>;
  vaccined_1_date?: Maybe<Scalars['date']>;
  vaccined_2_band?: Maybe<Scalars['String']>;
  vaccined_2_date?: Maybe<Scalars['date']>;
  vaccined_3_band?: Maybe<Scalars['String']>;
  vaccined_3_date?: Maybe<Scalars['date']>;
  vaccined_4_band?: Maybe<Scalars['String']>;
  vaccined_4_date?: Maybe<Scalars['date']>;
  vaccined_5_band?: Maybe<Scalars['String']>;
  vaccined_5_date?: Maybe<Scalars['date']>;
  vaccined_6_band?: Maybe<Scalars['String']>;
  vaccined_6_date?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "vaccined_detail" */
export type VaccinedDetailMaxOrderBy = {
  citizen_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vaccined_1_band?: Maybe<OrderBy>;
  vaccined_1_date?: Maybe<OrderBy>;
  vaccined_2_band?: Maybe<OrderBy>;
  vaccined_2_date?: Maybe<OrderBy>;
  vaccined_3_band?: Maybe<OrderBy>;
  vaccined_3_date?: Maybe<OrderBy>;
  vaccined_4_band?: Maybe<OrderBy>;
  vaccined_4_date?: Maybe<OrderBy>;
  vaccined_5_band?: Maybe<OrderBy>;
  vaccined_5_date?: Maybe<OrderBy>;
  vaccined_6_band?: Maybe<OrderBy>;
  vaccined_6_date?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type VaccinedDetailMinFields = {
  __typename?: 'vaccined_detail_min_fields';
  citizen_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vaccined_1_band?: Maybe<Scalars['String']>;
  vaccined_1_date?: Maybe<Scalars['date']>;
  vaccined_2_band?: Maybe<Scalars['String']>;
  vaccined_2_date?: Maybe<Scalars['date']>;
  vaccined_3_band?: Maybe<Scalars['String']>;
  vaccined_3_date?: Maybe<Scalars['date']>;
  vaccined_4_band?: Maybe<Scalars['String']>;
  vaccined_4_date?: Maybe<Scalars['date']>;
  vaccined_5_band?: Maybe<Scalars['String']>;
  vaccined_5_date?: Maybe<Scalars['date']>;
  vaccined_6_band?: Maybe<Scalars['String']>;
  vaccined_6_date?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "vaccined_detail" */
export type VaccinedDetailMinOrderBy = {
  citizen_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vaccined_1_band?: Maybe<OrderBy>;
  vaccined_1_date?: Maybe<OrderBy>;
  vaccined_2_band?: Maybe<OrderBy>;
  vaccined_2_date?: Maybe<OrderBy>;
  vaccined_3_band?: Maybe<OrderBy>;
  vaccined_3_date?: Maybe<OrderBy>;
  vaccined_4_band?: Maybe<OrderBy>;
  vaccined_4_date?: Maybe<OrderBy>;
  vaccined_5_band?: Maybe<OrderBy>;
  vaccined_5_date?: Maybe<OrderBy>;
  vaccined_6_band?: Maybe<OrderBy>;
  vaccined_6_date?: Maybe<OrderBy>;
};

/** response of any mutation on the table "vaccined_detail" */
export type VaccinedDetailMutationResponse = {
  __typename?: 'vaccined_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VaccinedDetail>;
};

/** on conflict condition type for table "vaccined_detail" */
export type VaccinedDetailOnConflict = {
  constraint: VaccinedDetailConstraint;
  update_columns: Array<VaccinedDetailUpdateColumn>;
  where?: Maybe<VaccinedDetailBoolExp>;
};

/** Ordering options when selecting data from "vaccined_detail". */
export type VaccinedDetailOrderBy = {
  citizen_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vaccined_1_band?: Maybe<OrderBy>;
  vaccined_1_date?: Maybe<OrderBy>;
  vaccined_2_band?: Maybe<OrderBy>;
  vaccined_2_date?: Maybe<OrderBy>;
  vaccined_3_band?: Maybe<OrderBy>;
  vaccined_3_date?: Maybe<OrderBy>;
  vaccined_4_band?: Maybe<OrderBy>;
  vaccined_4_date?: Maybe<OrderBy>;
  vaccined_5_band?: Maybe<OrderBy>;
  vaccined_5_date?: Maybe<OrderBy>;
  vaccined_6_band?: Maybe<OrderBy>;
  vaccined_6_date?: Maybe<OrderBy>;
};

/** primary key columns input for table: vaccined_detail */
export type VaccinedDetailPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "vaccined_detail" */
export enum VaccinedDetailSelectColumn {
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vaccined_1Band = 'vaccined_1_band',
  /** column name */
  Vaccined_1Date = 'vaccined_1_date',
  /** column name */
  Vaccined_2Band = 'vaccined_2_band',
  /** column name */
  Vaccined_2Date = 'vaccined_2_date',
  /** column name */
  Vaccined_3Band = 'vaccined_3_band',
  /** column name */
  Vaccined_3Date = 'vaccined_3_date',
  /** column name */
  Vaccined_4Band = 'vaccined_4_band',
  /** column name */
  Vaccined_4Date = 'vaccined_4_date',
  /** column name */
  Vaccined_5Band = 'vaccined_5_band',
  /** column name */
  Vaccined_5Date = 'vaccined_5_date',
  /** column name */
  Vaccined_6Band = 'vaccined_6_band',
  /** column name */
  Vaccined_6Date = 'vaccined_6_date'
}

/** input type for updating data in table "vaccined_detail" */
export type VaccinedDetailSetInput = {
  citizen_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vaccined_1_band?: Maybe<Scalars['String']>;
  vaccined_1_date?: Maybe<Scalars['date']>;
  vaccined_2_band?: Maybe<Scalars['String']>;
  vaccined_2_date?: Maybe<Scalars['date']>;
  vaccined_3_band?: Maybe<Scalars['String']>;
  vaccined_3_date?: Maybe<Scalars['date']>;
  vaccined_4_band?: Maybe<Scalars['String']>;
  vaccined_4_date?: Maybe<Scalars['date']>;
  vaccined_5_band?: Maybe<Scalars['String']>;
  vaccined_5_date?: Maybe<Scalars['date']>;
  vaccined_6_band?: Maybe<Scalars['String']>;
  vaccined_6_date?: Maybe<Scalars['date']>;
};

/** update columns of table "vaccined_detail" */
export enum VaccinedDetailUpdateColumn {
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vaccined_1Band = 'vaccined_1_band',
  /** column name */
  Vaccined_1Date = 'vaccined_1_date',
  /** column name */
  Vaccined_2Band = 'vaccined_2_band',
  /** column name */
  Vaccined_2Date = 'vaccined_2_date',
  /** column name */
  Vaccined_3Band = 'vaccined_3_band',
  /** column name */
  Vaccined_3Date = 'vaccined_3_date',
  /** column name */
  Vaccined_4Band = 'vaccined_4_band',
  /** column name */
  Vaccined_4Date = 'vaccined_4_date',
  /** column name */
  Vaccined_5Band = 'vaccined_5_band',
  /** column name */
  Vaccined_5Date = 'vaccined_5_date',
  /** column name */
  Vaccined_6Band = 'vaccined_6_band',
  /** column name */
  Vaccined_6Date = 'vaccined_6_date'
}

/**
 * รายชื่อข้อมูลของผู้ที่มีประวัติฉีด vaccine
 * 
 * 
 * columns and relationships of "vaccined_infomation"
 */
export type VaccinedInfomation = {
  __typename?: 'vaccined_infomation';
  citizen_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  vaccined: Array<VaccinedDetail>;
  /** An aggregate relationship */
  vaccined_aggregate: VaccinedDetailAggregate;
};


/**
 * รายชื่อข้อมูลของผู้ที่มีประวัติฉีด vaccine
 * 
 * 
 * columns and relationships of "vaccined_infomation"
 */
export type VaccinedInfomationVaccinedArgs = {
  distinct_on?: Maybe<Array<VaccinedDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedDetailOrderBy>>;
  where?: Maybe<VaccinedDetailBoolExp>;
};


/**
 * รายชื่อข้อมูลของผู้ที่มีประวัติฉีด vaccine
 * 
 * 
 * columns and relationships of "vaccined_infomation"
 */
export type VaccinedInfomationVaccinedAggregateArgs = {
  distinct_on?: Maybe<Array<VaccinedDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaccinedDetailOrderBy>>;
  where?: Maybe<VaccinedDetailBoolExp>;
};

/** aggregated selection of "vaccined_infomation" */
export type VaccinedInfomationAggregate = {
  __typename?: 'vaccined_infomation_aggregate';
  aggregate?: Maybe<VaccinedInfomationAggregateFields>;
  nodes: Array<VaccinedInfomation>;
};

/** aggregate fields of "vaccined_infomation" */
export type VaccinedInfomationAggregateFields = {
  __typename?: 'vaccined_infomation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<VaccinedInfomationMaxFields>;
  min?: Maybe<VaccinedInfomationMinFields>;
};


/** aggregate fields of "vaccined_infomation" */
export type VaccinedInfomationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<VaccinedInfomationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vaccined_infomation". All fields are combined with a logical 'AND'. */
export type VaccinedInfomationBoolExp = {
  _and?: Maybe<Array<VaccinedInfomationBoolExp>>;
  _not?: Maybe<VaccinedInfomationBoolExp>;
  _or?: Maybe<Array<VaccinedInfomationBoolExp>>;
  citizen_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  vaccined?: Maybe<VaccinedDetailBoolExp>;
};

/** unique or primary key constraints on table "vaccined_infomation" */
export enum VaccinedInfomationConstraint {
  /** unique or primary key constraint */
  VaccinedInfomationEmailKey = 'vaccined_infomation_email_key',
  /** unique or primary key constraint */
  VaccinedInfomationPkey = 'vaccined_infomation_pkey'
}

/** input type for inserting data into table "vaccined_infomation" */
export type VaccinedInfomationInsertInput = {
  citizen_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vaccined?: Maybe<VaccinedDetailArrRelInsertInput>;
};

/** aggregate max on columns */
export type VaccinedInfomationMaxFields = {
  __typename?: 'vaccined_infomation_max_fields';
  citizen_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type VaccinedInfomationMinFields = {
  __typename?: 'vaccined_infomation_min_fields';
  citizen_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "vaccined_infomation" */
export type VaccinedInfomationMutationResponse = {
  __typename?: 'vaccined_infomation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VaccinedInfomation>;
};

/** input type for inserting object relation for remote table "vaccined_infomation" */
export type VaccinedInfomationObjRelInsertInput = {
  data: VaccinedInfomationInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<VaccinedInfomationOnConflict>;
};

/** on conflict condition type for table "vaccined_infomation" */
export type VaccinedInfomationOnConflict = {
  constraint: VaccinedInfomationConstraint;
  update_columns: Array<VaccinedInfomationUpdateColumn>;
  where?: Maybe<VaccinedInfomationBoolExp>;
};

/** Ordering options when selecting data from "vaccined_infomation". */
export type VaccinedInfomationOrderBy = {
  citizen_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vaccined_aggregate?: Maybe<VaccinedDetailAggregateOrderBy>;
};

/** primary key columns input for table: vaccined_infomation */
export type VaccinedInfomationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "vaccined_infomation" */
export enum VaccinedInfomationSelectColumn {
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "vaccined_infomation" */
export type VaccinedInfomationSetInput = {
  citizen_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "vaccined_infomation" */
export enum VaccinedInfomationUpdateColumn {
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AtkBrandFragment = (
  { __typename?: 'atk_brand' }
  & Pick<AtkBrand, 'id' | 'name'>
);

export type GetAtkBrandsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<AtkBrandSelectColumn> | AtkBrandSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkBrandOrderBy> | AtkBrandOrderBy>;
  where?: Maybe<AtkBrandBoolExp>;
}>;


export type GetAtkBrandsQuery = (
  { __typename?: 'query_root' }
  & { returning: Array<(
    { __typename?: 'atk_brand' }
    & AtkBrandFragment
  )> }
);

export type AtkResultFragment = (
  { __typename?: 'atk_result' }
  & Pick<AtkResult, 'brand_id' | 'brand_name' | 'created_at' | 'date' | 'id' | 'image' | 'infected' | 'time' | 'user_id'>
);

export type AddAtkResultOneMutationVariables = Exact<{
  object: AtkResultInsertInput;
}>;


export type AddAtkResultOneMutation = (
  { __typename?: 'mutation_root' }
  & { returning?: Maybe<(
    { __typename?: 'atk_result' }
    & AtkResultFragment
  )> }
);

export type DeleteAtkResultByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteAtkResultByPkMutation = (
  { __typename?: 'mutation_root' }
  & { returning?: Maybe<(
    { __typename?: 'atk_result' }
    & AtkResultFragment
  )> }
);

export type GetAtkResultsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<AtkResultSelectColumn> | AtkResultSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AtkResultOrderBy> | AtkResultOrderBy>;
  where?: Maybe<AtkResultBoolExp>;
}>;


export type GetAtkResultsQuery = (
  { __typename?: 'query_root' }
  & { returning: Array<(
    { __typename?: 'atk_result' }
    & AtkResultFragment
  )> }
);

export type BuildingFragment = (
  { __typename?: 'building' }
  & Pick<Building, 'id' | 'name' | 'code'>
);

export type GetBuildingByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBuildingByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'building' }
    & BuildingFragment
  )> }
);

export type UpdateBuildingByPkMutationVariables = Exact<{
  id: Scalars['String'];
  _set?: Maybe<BuildingSetInput>;
  _inc?: Maybe<BuildingIncInput>;
}>;


export type UpdateBuildingByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'building' }
    & BuildingFragment
  )> }
);

export type GetSummaryCheckInOutDailyByBuildingQueryVariables = Exact<{
  buildingId: Scalars['String'];
}>;


export type GetSummaryCheckInOutDailyByBuildingQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'check_in_out_summary_by_building_daily' }
    & Pick<CheckInOutSummaryByBuildingDaily, 'building_id' | 'check_in_count' | 'check_out_count' | 'date'>
    & { building?: Maybe<(
      { __typename?: 'building' }
      & Pick<Building, 'id' | 'name' | 'organization_name' | 'code' | 'qrcode'>
    )> }
  )> }
);

export type GetCheckInOutDailyByBuildingQueryVariables = Exact<{
  buildingId: Scalars['String'];
}>;


export type GetCheckInOutDailyByBuildingQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'check_in_out_daily' }
    & Pick<CheckInOutDaily, 'email' | 'timestamp' | 'type' | 'contact_name' | 'tel' | 'code' | 'building_id' | 'id' | 'date' | 'display_name' | 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'user_email' }
      & { atk_results: Array<(
        { __typename?: 'atk_result' }
        & Pick<AtkResult, 'id' | 'date' | 'time' | 'brand_name' | 'infected'>
      )>, vaccined_information?: Maybe<(
        { __typename?: 'vaccined_infomation' }
        & { vaccined: Array<(
          { __typename?: 'vaccined_detail' }
          & Pick<VaccinedDetail, 'id' | 'vaccined_1_date' | 'vaccined_1_band' | 'vaccined_2_band' | 'vaccined_2_date' | 'vaccined_3_band' | 'vaccined_3_date' | 'vaccined_4_band' | 'vaccined_4_date' | 'vaccined_5_band' | 'vaccined_5_date' | 'vaccined_6_band' | 'vaccined_6_date'>
        )> }
      )> }
    )> }
  )> }
);

export type CheckInBuildingByPkMutationVariables = Exact<{
  buildingId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
}>;


export type CheckInBuildingByPkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_trace_one?: Maybe<(
    { __typename?: 'trace' }
    & Pick<Trace, 'user_id' | 'building_id'>
  )> }
);

export type CheckOutBuildingByPkMutationVariables = Exact<{
  buildingId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
}>;


export type CheckOutBuildingByPkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_trace_one?: Maybe<(
    { __typename?: 'trace' }
    & Pick<Trace, 'user_id' | 'building_id'>
  )> }
);

export type GetConstantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConstantsQuery = (
  { __typename?: 'query_root' }
  & { constants: Array<(
    { __typename?: 'constants' }
    & Pick<Constants, 'key' | 'value'>
  )> }
);

export type GetConstantByPkQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetConstantByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'constants' }
    & Pick<Constants, 'key' | 'value'>
  )> }
);

export type UserFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'id' | 'display_name'>
  & { account?: Maybe<(
    { __typename?: 'auth_accounts' }
    & Pick<AuthAccounts, 'email'>
  )> }
);

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserByIdQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'users' }
    & UserFragment
  )> }
);

export type UpdateUserEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UpdateUserEmailMutation = (
  { __typename?: 'mutation_root' }
  & { returning?: Maybe<(
    { __typename?: 'user_email' }
    & Pick<UserEmail, 'id' | 'email'>
  )> }
);

export type VaccineFragment = (
  { __typename?: 'vaccined_infomation' }
  & Pick<VaccinedInfomation, 'citizen_id' | 'email' | 'id' | 'name'>
  & { vaccined: Array<(
    { __typename?: 'vaccined_detail' }
    & Pick<VaccinedDetail, 'id' | 'vaccined_1_date' | 'vaccined_1_band' | 'vaccined_2_band' | 'vaccined_2_date' | 'vaccined_3_band' | 'vaccined_3_date' | 'vaccined_4_band' | 'vaccined_4_date' | 'vaccined_5_band' | 'vaccined_5_date' | 'vaccined_6_band' | 'vaccined_6_date' | 'updated_at'>
  )> }
);

export type GetVaccineByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetVaccineByEmailQuery = (
  { __typename?: 'query_root' }
  & { returning: Array<(
    { __typename?: 'vaccined_infomation' }
    & VaccineFragment
  )> }
);

export const AtkBrandFragmentDoc = gql`
    fragment AtkBrand on atk_brand {
  id
  name
}
    `;
export const AtkResultFragmentDoc = gql`
    fragment AtkResult on atk_result {
  brand_id
  brand_name
  created_at
  date
  id
  image
  infected
  time
  user_id
}
    `;
export const BuildingFragmentDoc = gql`
    fragment Building on building {
  id
  name
  code
}
    `;
export const UserFragmentDoc = gql`
    fragment User on users {
  id
  display_name
  account {
    email
  }
}
    `;
export const VaccineFragmentDoc = gql`
    fragment Vaccine on vaccined_infomation {
  vaccined {
    id
    vaccined_1_date
    vaccined_1_band
    vaccined_2_band
    vaccined_2_date
    vaccined_3_band
    vaccined_3_date
    vaccined_4_band
    vaccined_4_date
    vaccined_5_band
    vaccined_5_date
    vaccined_6_band
    vaccined_6_date
    updated_at
  }
  citizen_id
  email
  id
  name
}
    `;
export const GetAtkBrandsDocument = gql`
    query getAtkBrands($distinct_on: [atk_brand_select_column!], $limit: Int, $offset: Int, $order_by: [atk_brand_order_by!], $where: atk_brand_bool_exp) {
  returning: atk_brand(
    distinct_on: $distinct_on
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...AtkBrand
  }
}
    ${AtkBrandFragmentDoc}`;
export type GetAtkBrandsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAtkBrandsQuery, GetAtkBrandsQueryVariables>, 'query'>;

    export const GetAtkBrandsComponent = (props: GetAtkBrandsComponentProps) => (
      <ApolloReactComponents.Query<GetAtkBrandsQuery, GetAtkBrandsQueryVariables> query={GetAtkBrandsDocument} {...props} />
    );
    

/**
 * __useGetAtkBrandsQuery__
 *
 * To run a query within a React component, call `useGetAtkBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAtkBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAtkBrandsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAtkBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetAtkBrandsQuery, GetAtkBrandsQueryVariables>) {
        return Apollo.useQuery<GetAtkBrandsQuery, GetAtkBrandsQueryVariables>(GetAtkBrandsDocument, baseOptions);
      }
export function useGetAtkBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAtkBrandsQuery, GetAtkBrandsQueryVariables>) {
          return Apollo.useLazyQuery<GetAtkBrandsQuery, GetAtkBrandsQueryVariables>(GetAtkBrandsDocument, baseOptions);
        }
export type GetAtkBrandsQueryHookResult = ReturnType<typeof useGetAtkBrandsQuery>;
export type GetAtkBrandsLazyQueryHookResult = ReturnType<typeof useGetAtkBrandsLazyQuery>;
export type GetAtkBrandsQueryResult = Apollo.QueryResult<GetAtkBrandsQuery, GetAtkBrandsQueryVariables>;
export const AddAtkResultOneDocument = gql`
    mutation addAtkResultOne($object: atk_result_insert_input!) {
  returning: insert_atk_result_one(object: $object) {
    ...AtkResult
  }
}
    ${AtkResultFragmentDoc}`;
export type AddAtkResultOneMutationFn = Apollo.MutationFunction<AddAtkResultOneMutation, AddAtkResultOneMutationVariables>;
export type AddAtkResultOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAtkResultOneMutation, AddAtkResultOneMutationVariables>, 'mutation'>;

    export const AddAtkResultOneComponent = (props: AddAtkResultOneComponentProps) => (
      <ApolloReactComponents.Mutation<AddAtkResultOneMutation, AddAtkResultOneMutationVariables> mutation={AddAtkResultOneDocument} {...props} />
    );
    

/**
 * __useAddAtkResultOneMutation__
 *
 * To run a mutation, you first call `useAddAtkResultOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAtkResultOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAtkResultOneMutation, { data, loading, error }] = useAddAtkResultOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddAtkResultOneMutation(baseOptions?: Apollo.MutationHookOptions<AddAtkResultOneMutation, AddAtkResultOneMutationVariables>) {
        return Apollo.useMutation<AddAtkResultOneMutation, AddAtkResultOneMutationVariables>(AddAtkResultOneDocument, baseOptions);
      }
export type AddAtkResultOneMutationHookResult = ReturnType<typeof useAddAtkResultOneMutation>;
export type AddAtkResultOneMutationResult = Apollo.MutationResult<AddAtkResultOneMutation>;
export type AddAtkResultOneMutationOptions = Apollo.BaseMutationOptions<AddAtkResultOneMutation, AddAtkResultOneMutationVariables>;
export const DeleteAtkResultByPkDocument = gql`
    mutation deleteAtkResultByPk($id: uuid!) {
  returning: delete_atk_result_by_pk(id: $id) {
    ...AtkResult
  }
}
    ${AtkResultFragmentDoc}`;
export type DeleteAtkResultByPkMutationFn = Apollo.MutationFunction<DeleteAtkResultByPkMutation, DeleteAtkResultByPkMutationVariables>;
export type DeleteAtkResultByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAtkResultByPkMutation, DeleteAtkResultByPkMutationVariables>, 'mutation'>;

    export const DeleteAtkResultByPkComponent = (props: DeleteAtkResultByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAtkResultByPkMutation, DeleteAtkResultByPkMutationVariables> mutation={DeleteAtkResultByPkDocument} {...props} />
    );
    

/**
 * __useDeleteAtkResultByPkMutation__
 *
 * To run a mutation, you first call `useDeleteAtkResultByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAtkResultByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAtkResultByPkMutation, { data, loading, error }] = useDeleteAtkResultByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAtkResultByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAtkResultByPkMutation, DeleteAtkResultByPkMutationVariables>) {
        return Apollo.useMutation<DeleteAtkResultByPkMutation, DeleteAtkResultByPkMutationVariables>(DeleteAtkResultByPkDocument, baseOptions);
      }
export type DeleteAtkResultByPkMutationHookResult = ReturnType<typeof useDeleteAtkResultByPkMutation>;
export type DeleteAtkResultByPkMutationResult = Apollo.MutationResult<DeleteAtkResultByPkMutation>;
export type DeleteAtkResultByPkMutationOptions = Apollo.BaseMutationOptions<DeleteAtkResultByPkMutation, DeleteAtkResultByPkMutationVariables>;
export const GetAtkResultsDocument = gql`
    query getAtkResults($distinct_on: [atk_result_select_column!], $limit: Int, $offset: Int, $order_by: [atk_result_order_by!], $where: atk_result_bool_exp) {
  returning: my_atk_result(
    distinct_on: $distinct_on
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...AtkResult
  }
}
    ${AtkResultFragmentDoc}`;
export type GetAtkResultsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAtkResultsQuery, GetAtkResultsQueryVariables>, 'query'>;

    export const GetAtkResultsComponent = (props: GetAtkResultsComponentProps) => (
      <ApolloReactComponents.Query<GetAtkResultsQuery, GetAtkResultsQueryVariables> query={GetAtkResultsDocument} {...props} />
    );
    

/**
 * __useGetAtkResultsQuery__
 *
 * To run a query within a React component, call `useGetAtkResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAtkResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAtkResultsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAtkResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetAtkResultsQuery, GetAtkResultsQueryVariables>) {
        return Apollo.useQuery<GetAtkResultsQuery, GetAtkResultsQueryVariables>(GetAtkResultsDocument, baseOptions);
      }
export function useGetAtkResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAtkResultsQuery, GetAtkResultsQueryVariables>) {
          return Apollo.useLazyQuery<GetAtkResultsQuery, GetAtkResultsQueryVariables>(GetAtkResultsDocument, baseOptions);
        }
export type GetAtkResultsQueryHookResult = ReturnType<typeof useGetAtkResultsQuery>;
export type GetAtkResultsLazyQueryHookResult = ReturnType<typeof useGetAtkResultsLazyQuery>;
export type GetAtkResultsQueryResult = Apollo.QueryResult<GetAtkResultsQuery, GetAtkResultsQueryVariables>;
export const GetBuildingByPkDocument = gql`
    query GetBuildingByPk($id: String!) {
  row: building_by_pk(id: $id) {
    ...Building
  }
}
    ${BuildingFragmentDoc}`;
export type GetBuildingByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBuildingByPkQuery, GetBuildingByPkQueryVariables>, 'query'> & ({ variables: GetBuildingByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBuildingByPkComponent = (props: GetBuildingByPkComponentProps) => (
      <ApolloReactComponents.Query<GetBuildingByPkQuery, GetBuildingByPkQueryVariables> query={GetBuildingByPkDocument} {...props} />
    );
    

/**
 * __useGetBuildingByPkQuery__
 *
 * To run a query within a React component, call `useGetBuildingByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuildingByPkQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingByPkQuery, GetBuildingByPkQueryVariables>) {
        return Apollo.useQuery<GetBuildingByPkQuery, GetBuildingByPkQueryVariables>(GetBuildingByPkDocument, baseOptions);
      }
export function useGetBuildingByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingByPkQuery, GetBuildingByPkQueryVariables>) {
          return Apollo.useLazyQuery<GetBuildingByPkQuery, GetBuildingByPkQueryVariables>(GetBuildingByPkDocument, baseOptions);
        }
export type GetBuildingByPkQueryHookResult = ReturnType<typeof useGetBuildingByPkQuery>;
export type GetBuildingByPkLazyQueryHookResult = ReturnType<typeof useGetBuildingByPkLazyQuery>;
export type GetBuildingByPkQueryResult = Apollo.QueryResult<GetBuildingByPkQuery, GetBuildingByPkQueryVariables>;
export const UpdateBuildingByPkDocument = gql`
    mutation UpdateBuildingByPk($id: String!, $_set: building_set_input, $_inc: building_inc_input) {
  row: update_building_by_pk(pk_columns: {id: $id}, _set: $_set, _inc: $_inc) {
    ...Building
  }
}
    ${BuildingFragmentDoc}`;
export type UpdateBuildingByPkMutationFn = Apollo.MutationFunction<UpdateBuildingByPkMutation, UpdateBuildingByPkMutationVariables>;
export type UpdateBuildingByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBuildingByPkMutation, UpdateBuildingByPkMutationVariables>, 'mutation'>;

    export const UpdateBuildingByPkComponent = (props: UpdateBuildingByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBuildingByPkMutation, UpdateBuildingByPkMutationVariables> mutation={UpdateBuildingByPkDocument} {...props} />
    );
    

/**
 * __useUpdateBuildingByPkMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingByPkMutation, { data, loading, error }] = useUpdateBuildingByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *      _inc: // value for '_inc'
 *   },
 * });
 */
export function useUpdateBuildingByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBuildingByPkMutation, UpdateBuildingByPkMutationVariables>) {
        return Apollo.useMutation<UpdateBuildingByPkMutation, UpdateBuildingByPkMutationVariables>(UpdateBuildingByPkDocument, baseOptions);
      }
export type UpdateBuildingByPkMutationHookResult = ReturnType<typeof useUpdateBuildingByPkMutation>;
export type UpdateBuildingByPkMutationResult = Apollo.MutationResult<UpdateBuildingByPkMutation>;
export type UpdateBuildingByPkMutationOptions = Apollo.BaseMutationOptions<UpdateBuildingByPkMutation, UpdateBuildingByPkMutationVariables>;
export const GetSummaryCheckInOutDailyByBuildingDocument = gql`
    query GetSummaryCheckInOutDailyByBuilding($buildingId: String!) {
  rows: check_in_out_summary_by_building_daily(
    where: {building_id: {_eq: $buildingId}}
  ) {
    building_id
    check_in_count
    check_out_count
    date
    building {
      id
      name
      organization_name
      code
      qrcode
    }
  }
}
    `;
export type GetSummaryCheckInOutDailyByBuildingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSummaryCheckInOutDailyByBuildingQuery, GetSummaryCheckInOutDailyByBuildingQueryVariables>, 'query'> & ({ variables: GetSummaryCheckInOutDailyByBuildingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSummaryCheckInOutDailyByBuildingComponent = (props: GetSummaryCheckInOutDailyByBuildingComponentProps) => (
      <ApolloReactComponents.Query<GetSummaryCheckInOutDailyByBuildingQuery, GetSummaryCheckInOutDailyByBuildingQueryVariables> query={GetSummaryCheckInOutDailyByBuildingDocument} {...props} />
    );
    

/**
 * __useGetSummaryCheckInOutDailyByBuildingQuery__
 *
 * To run a query within a React component, call `useGetSummaryCheckInOutDailyByBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSummaryCheckInOutDailyByBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSummaryCheckInOutDailyByBuildingQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useGetSummaryCheckInOutDailyByBuildingQuery(baseOptions: Apollo.QueryHookOptions<GetSummaryCheckInOutDailyByBuildingQuery, GetSummaryCheckInOutDailyByBuildingQueryVariables>) {
        return Apollo.useQuery<GetSummaryCheckInOutDailyByBuildingQuery, GetSummaryCheckInOutDailyByBuildingQueryVariables>(GetSummaryCheckInOutDailyByBuildingDocument, baseOptions);
      }
export function useGetSummaryCheckInOutDailyByBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSummaryCheckInOutDailyByBuildingQuery, GetSummaryCheckInOutDailyByBuildingQueryVariables>) {
          return Apollo.useLazyQuery<GetSummaryCheckInOutDailyByBuildingQuery, GetSummaryCheckInOutDailyByBuildingQueryVariables>(GetSummaryCheckInOutDailyByBuildingDocument, baseOptions);
        }
export type GetSummaryCheckInOutDailyByBuildingQueryHookResult = ReturnType<typeof useGetSummaryCheckInOutDailyByBuildingQuery>;
export type GetSummaryCheckInOutDailyByBuildingLazyQueryHookResult = ReturnType<typeof useGetSummaryCheckInOutDailyByBuildingLazyQuery>;
export type GetSummaryCheckInOutDailyByBuildingQueryResult = Apollo.QueryResult<GetSummaryCheckInOutDailyByBuildingQuery, GetSummaryCheckInOutDailyByBuildingQueryVariables>;
export const GetCheckInOutDailyByBuildingDocument = gql`
    query GetCheckInOutDailyByBuilding($buildingId: String!) {
  rows: check_in_out_daily(
    where: {building_id: {_eq: $buildingId}}
    order_by: {timestamp: desc_nulls_last}
    limit: 20
  ) {
    email
    timestamp
    type
    contact_name
    tel
    code
    building_id
    id
    date
    display_name
    user_id
    user {
      atk_results(limit: 1, order_by: {date: desc}) {
        id
        date
        time
        brand_name
        infected
      }
      vaccined_information {
        vaccined {
          id
          vaccined_1_date
          vaccined_1_band
          vaccined_2_band
          vaccined_2_date
          vaccined_3_band
          vaccined_3_date
          vaccined_4_band
          vaccined_4_date
          vaccined_5_band
          vaccined_5_date
          vaccined_6_band
          vaccined_6_date
        }
      }
    }
  }
}
    `;
export type GetCheckInOutDailyByBuildingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCheckInOutDailyByBuildingQuery, GetCheckInOutDailyByBuildingQueryVariables>, 'query'> & ({ variables: GetCheckInOutDailyByBuildingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCheckInOutDailyByBuildingComponent = (props: GetCheckInOutDailyByBuildingComponentProps) => (
      <ApolloReactComponents.Query<GetCheckInOutDailyByBuildingQuery, GetCheckInOutDailyByBuildingQueryVariables> query={GetCheckInOutDailyByBuildingDocument} {...props} />
    );
    

/**
 * __useGetCheckInOutDailyByBuildingQuery__
 *
 * To run a query within a React component, call `useGetCheckInOutDailyByBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckInOutDailyByBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckInOutDailyByBuildingQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useGetCheckInOutDailyByBuildingQuery(baseOptions: Apollo.QueryHookOptions<GetCheckInOutDailyByBuildingQuery, GetCheckInOutDailyByBuildingQueryVariables>) {
        return Apollo.useQuery<GetCheckInOutDailyByBuildingQuery, GetCheckInOutDailyByBuildingQueryVariables>(GetCheckInOutDailyByBuildingDocument, baseOptions);
      }
export function useGetCheckInOutDailyByBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckInOutDailyByBuildingQuery, GetCheckInOutDailyByBuildingQueryVariables>) {
          return Apollo.useLazyQuery<GetCheckInOutDailyByBuildingQuery, GetCheckInOutDailyByBuildingQueryVariables>(GetCheckInOutDailyByBuildingDocument, baseOptions);
        }
export type GetCheckInOutDailyByBuildingQueryHookResult = ReturnType<typeof useGetCheckInOutDailyByBuildingQuery>;
export type GetCheckInOutDailyByBuildingLazyQueryHookResult = ReturnType<typeof useGetCheckInOutDailyByBuildingLazyQuery>;
export type GetCheckInOutDailyByBuildingQueryResult = Apollo.QueryResult<GetCheckInOutDailyByBuildingQuery, GetCheckInOutDailyByBuildingQueryVariables>;
export const CheckInBuildingByPkDocument = gql`
    mutation CheckInBuildingByPk($buildingId: String!, $email: String, $contact_name: String) {
  insert_trace_one(
    object: {building_id: $buildingId, type: in, email: $email, contact_name: $contact_name}
  ) {
    user_id
    building_id
  }
}
    `;
export type CheckInBuildingByPkMutationFn = Apollo.MutationFunction<CheckInBuildingByPkMutation, CheckInBuildingByPkMutationVariables>;
export type CheckInBuildingByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CheckInBuildingByPkMutation, CheckInBuildingByPkMutationVariables>, 'mutation'>;

    export const CheckInBuildingByPkComponent = (props: CheckInBuildingByPkComponentProps) => (
      <ApolloReactComponents.Mutation<CheckInBuildingByPkMutation, CheckInBuildingByPkMutationVariables> mutation={CheckInBuildingByPkDocument} {...props} />
    );
    

/**
 * __useCheckInBuildingByPkMutation__
 *
 * To run a mutation, you first call `useCheckInBuildingByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInBuildingByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInBuildingByPkMutation, { data, loading, error }] = useCheckInBuildingByPkMutation({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      email: // value for 'email'
 *      contact_name: // value for 'contact_name'
 *   },
 * });
 */
export function useCheckInBuildingByPkMutation(baseOptions?: Apollo.MutationHookOptions<CheckInBuildingByPkMutation, CheckInBuildingByPkMutationVariables>) {
        return Apollo.useMutation<CheckInBuildingByPkMutation, CheckInBuildingByPkMutationVariables>(CheckInBuildingByPkDocument, baseOptions);
      }
export type CheckInBuildingByPkMutationHookResult = ReturnType<typeof useCheckInBuildingByPkMutation>;
export type CheckInBuildingByPkMutationResult = Apollo.MutationResult<CheckInBuildingByPkMutation>;
export type CheckInBuildingByPkMutationOptions = Apollo.BaseMutationOptions<CheckInBuildingByPkMutation, CheckInBuildingByPkMutationVariables>;
export const CheckOutBuildingByPkDocument = gql`
    mutation CheckOutBuildingByPk($buildingId: String!, $email: String, $contact_name: String) {
  insert_trace_one(
    object: {building_id: $buildingId, type: out, email: $email, contact_name: $contact_name}
  ) {
    user_id
    building_id
  }
}
    `;
export type CheckOutBuildingByPkMutationFn = Apollo.MutationFunction<CheckOutBuildingByPkMutation, CheckOutBuildingByPkMutationVariables>;
export type CheckOutBuildingByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CheckOutBuildingByPkMutation, CheckOutBuildingByPkMutationVariables>, 'mutation'>;

    export const CheckOutBuildingByPkComponent = (props: CheckOutBuildingByPkComponentProps) => (
      <ApolloReactComponents.Mutation<CheckOutBuildingByPkMutation, CheckOutBuildingByPkMutationVariables> mutation={CheckOutBuildingByPkDocument} {...props} />
    );
    

/**
 * __useCheckOutBuildingByPkMutation__
 *
 * To run a mutation, you first call `useCheckOutBuildingByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutBuildingByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutBuildingByPkMutation, { data, loading, error }] = useCheckOutBuildingByPkMutation({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      email: // value for 'email'
 *      contact_name: // value for 'contact_name'
 *   },
 * });
 */
export function useCheckOutBuildingByPkMutation(baseOptions?: Apollo.MutationHookOptions<CheckOutBuildingByPkMutation, CheckOutBuildingByPkMutationVariables>) {
        return Apollo.useMutation<CheckOutBuildingByPkMutation, CheckOutBuildingByPkMutationVariables>(CheckOutBuildingByPkDocument, baseOptions);
      }
export type CheckOutBuildingByPkMutationHookResult = ReturnType<typeof useCheckOutBuildingByPkMutation>;
export type CheckOutBuildingByPkMutationResult = Apollo.MutationResult<CheckOutBuildingByPkMutation>;
export type CheckOutBuildingByPkMutationOptions = Apollo.BaseMutationOptions<CheckOutBuildingByPkMutation, CheckOutBuildingByPkMutationVariables>;
export const GetConstantsDocument = gql`
    query GetConstants {
  constants {
    key
    value
  }
}
    `;
export type GetConstantsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetConstantsQuery, GetConstantsQueryVariables>, 'query'>;

    export const GetConstantsComponent = (props: GetConstantsComponentProps) => (
      <ApolloReactComponents.Query<GetConstantsQuery, GetConstantsQueryVariables> query={GetConstantsDocument} {...props} />
    );
    

/**
 * __useGetConstantsQuery__
 *
 * To run a query within a React component, call `useGetConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConstantsQuery(baseOptions?: Apollo.QueryHookOptions<GetConstantsQuery, GetConstantsQueryVariables>) {
        return Apollo.useQuery<GetConstantsQuery, GetConstantsQueryVariables>(GetConstantsDocument, baseOptions);
      }
export function useGetConstantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstantsQuery, GetConstantsQueryVariables>) {
          return Apollo.useLazyQuery<GetConstantsQuery, GetConstantsQueryVariables>(GetConstantsDocument, baseOptions);
        }
export type GetConstantsQueryHookResult = ReturnType<typeof useGetConstantsQuery>;
export type GetConstantsLazyQueryHookResult = ReturnType<typeof useGetConstantsLazyQuery>;
export type GetConstantsQueryResult = Apollo.QueryResult<GetConstantsQuery, GetConstantsQueryVariables>;
export const GetConstantByPkDocument = gql`
    query GetConstantByPk($key: String!) {
  row: constants_by_pk(key: $key) {
    key
    value
  }
}
    `;
export type GetConstantByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetConstantByPkQuery, GetConstantByPkQueryVariables>, 'query'> & ({ variables: GetConstantByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetConstantByPkComponent = (props: GetConstantByPkComponentProps) => (
      <ApolloReactComponents.Query<GetConstantByPkQuery, GetConstantByPkQueryVariables> query={GetConstantByPkDocument} {...props} />
    );
    

/**
 * __useGetConstantByPkQuery__
 *
 * To run a query within a React component, call `useGetConstantByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstantByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstantByPkQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetConstantByPkQuery(baseOptions: Apollo.QueryHookOptions<GetConstantByPkQuery, GetConstantByPkQueryVariables>) {
        return Apollo.useQuery<GetConstantByPkQuery, GetConstantByPkQueryVariables>(GetConstantByPkDocument, baseOptions);
      }
export function useGetConstantByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstantByPkQuery, GetConstantByPkQueryVariables>) {
          return Apollo.useLazyQuery<GetConstantByPkQuery, GetConstantByPkQueryVariables>(GetConstantByPkDocument, baseOptions);
        }
export type GetConstantByPkQueryHookResult = ReturnType<typeof useGetConstantByPkQuery>;
export type GetConstantByPkLazyQueryHookResult = ReturnType<typeof useGetConstantByPkLazyQuery>;
export type GetConstantByPkQueryResult = Apollo.QueryResult<GetConstantByPkQuery, GetConstantByPkQueryVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($id: uuid!) {
  entity: users_by_pk(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type GetUserByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserByIdQuery, GetUserByIdQueryVariables>, 'query'> & ({ variables: GetUserByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserByIdComponent = (props: GetUserByIdComponentProps) => (
      <ApolloReactComponents.Query<GetUserByIdQuery, GetUserByIdQueryVariables> query={GetUserByIdDocument} {...props} />
    );
    

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, baseOptions);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, baseOptions);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const UpdateUserEmailDocument = gql`
    mutation UpdateUserEmail($email: String!) {
  returning: insert_user_email_one(
    object: {email: $email}
    on_conflict: {constraint: user_email_pkey, update_columns: []}
  ) {
    id
    email
  }
}
    `;
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;
export type UpdateUserEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>, 'mutation'>;

    export const UpdateUserEmailComponent = (props: UpdateUserEmailComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserEmailMutation, UpdateUserEmailMutationVariables> mutation={UpdateUserEmailDocument} {...props} />
    );
    

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>) {
        return Apollo.useMutation<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>(UpdateUserEmailDocument, baseOptions);
      }
export type UpdateUserEmailMutationHookResult = ReturnType<typeof useUpdateUserEmailMutation>;
export type UpdateUserEmailMutationResult = Apollo.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;
export const GetVaccineByEmailDocument = gql`
    query GetVaccineByEmail($email: String!) {
  returning: vaccined_infomation(where: {email: {_eq: $email}}, limit: 1) {
    ...Vaccine
  }
}
    ${VaccineFragmentDoc}`;
export type GetVaccineByEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetVaccineByEmailQuery, GetVaccineByEmailQueryVariables>, 'query'> & ({ variables: GetVaccineByEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetVaccineByEmailComponent = (props: GetVaccineByEmailComponentProps) => (
      <ApolloReactComponents.Query<GetVaccineByEmailQuery, GetVaccineByEmailQueryVariables> query={GetVaccineByEmailDocument} {...props} />
    );
    

/**
 * __useGetVaccineByEmailQuery__
 *
 * To run a query within a React component, call `useGetVaccineByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVaccineByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVaccineByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetVaccineByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetVaccineByEmailQuery, GetVaccineByEmailQueryVariables>) {
        return Apollo.useQuery<GetVaccineByEmailQuery, GetVaccineByEmailQueryVariables>(GetVaccineByEmailDocument, baseOptions);
      }
export function useGetVaccineByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVaccineByEmailQuery, GetVaccineByEmailQueryVariables>) {
          return Apollo.useLazyQuery<GetVaccineByEmailQuery, GetVaccineByEmailQueryVariables>(GetVaccineByEmailDocument, baseOptions);
        }
export type GetVaccineByEmailQueryHookResult = ReturnType<typeof useGetVaccineByEmailQuery>;
export type GetVaccineByEmailLazyQueryHookResult = ReturnType<typeof useGetVaccineByEmailLazyQuery>;
export type GetVaccineByEmailQueryResult = Apollo.QueryResult<GetVaccineByEmailQuery, GetVaccineByEmailQueryVariables>;