import clsx from "clsx"
import React, { Fragment, useCallback } from "react"
import toast from "react-hot-toast"
import { useSession } from "../../hooks/useSession"

export const UserMenuMobile = ({ open }: { open?: boolean }) => {
  const { user, anonymous, type, logout } = useSession()

  const handleLogout = useCallback(async () => {
    await logout()
    toast.success("ออกจากระบบสำเร็จ")
  }, [logout])

  return (
    <Fragment>
      <div className={clsx(open ? "block" : "hidden", "sm:hidden")}>
        <div className="pb-3 border-t border-gray-200">
          <div className="mt-3 space-y-1 px-4 text-gray-700 font-medium">
            {type === "kmitl" ? user?.display_name : anonymous?.tel}
            <br />
            {type === "kmitl" ? user?.account?.email : anonymous?.contact_name}
          </div>
        </div>
        <div className="pb-3 border-t border-gray-200">
          <div className="mt-3 space-y-1">
            <div
              onClick={handleLogout}
              className={clsx(
                "block",
                "px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 cursor-pointer"
              )}
            >
              ออกจากระบบ
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
